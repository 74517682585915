import React, { FC } from "react";

export interface SaleOffBadgeProps {
  className?: string;
  status?: string;
}

const SaleOffBadge: FC<SaleOffBadgeProps> = ({
  className = "",
  status = "",
}) => {

  var bgColor = "";
  if (status === "Waiting") {
    status = "Waiting approval"
    bgColor = "bg-orange-500"
  } else if (status === "Approved") {
    status = "Approved"
    bgColor = "bg-[#19CFD8]"
  } else if (status === "Rejected") {
    status = "Rejected"
    bgColor = "bg-[#FF0000]"
  }

  return (
    <div
      className={`nc-SaleOffBadge flex items-center justify-center text-xs py-2 px-3 rounded-bl-lg text-white ${className} ${bgColor}`}

      data-nc-id="SaleOffBadge"
    >
      {status}
    </div>
  );
};

export default SaleOffBadge;
