import React, { FC, ReactNode} from "react";
// import { ListingDataType } from "data/types";
// import HeaderFilter from "./HeaderFilter";
 import PropertySkeleton from "./PropertySkeleton";







const SectionGridMainSkeleton = () => {
 


  
  const renderCard = ( index: number) => {
    return <PropertySkeleton key={index}   />;
  }

  return (
    <div className="nc-SectionGridSkeletonProperty relative">
      
      <div
        className={`grid gap-6 md:gap-10 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 sm:mx-[80px] md:mx-[0px] `}
      >
        {/* {featuredListings.map(renderCard)} */}
        {[...Array(6)].map((_, index) => renderCard(index))}
      </div>
      
    </div>
  );
};

export default SectionGridMainSkeleton;
