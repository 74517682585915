import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import LangDropdown from "./LangDropdown";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
// import CurrencyDropdown from "./CurrencyDropdown";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";

export interface MainNav2Props {
  className?: string;
}

const MainNav2: FC<MainNav2Props> = ({ className = "" }) => {
  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 ">
          <div className="hidden items-center lg:flex space-x-1">
            {/* <LangDropdown /> */}
          </div>
          <div className="flex items-center space-x-2 lg:hidden">
           {/* ---------Habilitar la foto despues de login---------------- */}
            <NotifyDropdown />
            <AvatarDropdown /> 
          </div>
          {/* <LangDropdown /> */}
          <MenuBar />
        </div>
      </div>
    </div>
  );
};

export default MainNav2;
