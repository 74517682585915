import React, { FC } from "react";
import LogoLArge from "shared/Logo/LogoLarge";
import MenuBar from "shared/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown2";
import HeroSearchForm2RealEstateMobile from "components/HeroSearchForm2Mobile/HeroSearchForm2RealEstateMobile";
import texture from "images/bg_Texture5.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import LangDropdown from "./LangDropdown";
import { useTranslation } from 'react-i18next';

export interface MainNav3Props {
  className?: string;
}

const MainNav3: FC<MainNav3Props> = ({
  className = "",
}) => {
  const { loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  function gotoLogin() {
  }

  const handleButtonClick = () => {
    navigate(`/blog`);
  };


  const logoutWithRedirect = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  };

  const { user, isAuthenticated } = useAuth0();

  const changeLanguage = (lang:string) => {
    // Logic to change the language in your application
    // console.log(`Changing language to: ${lang}`);
    i18n.changeLanguage(lang);
  };


  return (
    <div className="relative h-[97px]">
      <div className={`absolute left-0 top-0  ${className} opacity-50  w-full  h-full z-0`} style={{
        backgroundImage: `url(${texture})`,
        backgroundSize: 'auto',
        backgroundRepeat: 'repeat',
      }}>
      </div>

      <div className="relative h-full">
        <div className="hidden lg:flex justify-left flex-1 items-center absolute inset-0 z-0 pl-[70px]">
          <div className="flex">
            <LogoLArge />
          </div>
        </div>
        <div className="h-full flex lg:hidden justify-left flex-1 items-center absolute inset-0 z-0 pl-[20px]">
          <div className="flex">
            <LogoLArge className="w-[200px]" />
          </div>
        </div>

        <div className=" h-full flex justify-end items-center lg:hidden z-10 pr-[20px]">
          <HeroSearchForm2RealEstateMobile />
        </div>

        <div className="hidden lg:flex h-full items-center justify-end lg:flex-none text-neutral-700 z-10 pr-[80px] md:pr-[20px] ">
          <div className="flex space-x-4">
            <button className="text-sm font-semibold" style={{ zIndex: 10 }} onClick={handleButtonClick}>{t('mainNav3-header1')}</button>
            <button className="text-sm font-semibold px-4" style={{ zIndex: 10 }}>{t('mainNav3-header2')}</button>
            <div className="hidden items-center lg:flex space-x-1">
            <LangDropdown changeLanguage={changeLanguage} />
          </div>
            {!isAuthenticated && (
              <button className="bg-[#00D3DB] rounded-md h-7 text-sm font-semibold text-white px-4" style={{ zIndex: 10 }} onClick={() => loginWithRedirect()}>{t('mainNav3-header3')}</button>
            )}
            {isAuthenticated && <AvatarDropdown logoutClick={logoutWithRedirect} />}
          </div>
          
          <div style={{ zIndex: 10 }}>
          <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav3;