
import HeroSearchForm2RealEstateMobile from "./HeroSearchForm2RealEstateMobile";



const HeroSearchForm2MobileFactory = () => {
  return <HeroSearchForm2RealEstateMobile />;
};

export default HeroSearchForm2MobileFactory;
