import React, { FC } from "react";
// import pattern from "images/Pattern_B&W.png";
import texture from "images/backgroundMap.svg";


export interface BackgroundSectionProps {
  className?: string;
  children?: React.ReactNode;
}

const BackgroundMap: FC<BackgroundSectionProps> = ({
  className = "bg-neutral-100  ",
  children,
}) => {
  return (
    <div
      className={`overflow-hidden opacity-100   absolute inset-y-0 w-screen left-1/2 transform -translate-x-1/2  z-0 ${className}`}
      style={{
        backgroundImage: `url(${texture})`, // Set the background image using the 'texture' variable
        backgroundSize: 'cover', // The image will display at its original size
        backgroundRepeat: 'repeat', // The image will repeat both horizontally and vertically
      }}
    >
      {/* <img
          className={` `}
          src={texture}
          alt="back"
        /> */}
      {/* {children} */}
    </div>
  );
};

export default BackgroundMap;