



const AccountSavelists2 = () => {


  return (
    <div className="nc-PageWishList relative overflow-hidden">
    
      <div className="container relative  ">
       <div className="relative py-8 ">
         
        </div>
    </div>
  </div>
    
  );
};

export default AccountSavelists2;