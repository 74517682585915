import React, { Fragment, useState, FC } from "react";
import { FactsFeaturesDataType } from "data/types";
import { useTranslation } from 'react-i18next';

export interface StayDatesRangeInputProps {
    className?: string;
    defaultOpen?: boolean;
    facts?: FactsFeaturesDataType;
}

const FactFeatures: FC<StayDatesRangeInputProps> = ({
    className = "flex-1",
    defaultOpen = false,
    facts
}) => {

    const [isOpen, setIsOpen] = useState(defaultOpen);
    const { t, i18n } = useTranslation();

    function showFacts() {
        setIsOpen(!isOpen);
    }

    const renderDetail = () => {
        if (!isOpen) return null;
        return (
            <div className="p-4  rounded-2xl ">
                <div className=" rounded-3xl  ">
                    <div className=" flex flex-row">
                        <div className="  flex-col w-1/2 ">
                            <h2 className="text-base font-bold capitalize text-left ">
                                <span >{t('factsFeatures-interior-details')}</span>
                            </h2>
                            <div className="text-sm font-medium text-left pt-[10px]">
                                <span >{t('factsFeatures-bedrooms-bathrooms')}</span>
                            </div>
                            <div className=" prose sm:prose " >
                                <ul className=" text-xs  text-left">
                                    {facts?.bedrooms?.map((data) => (
                                        <li>{data}</li>
                                    ))}
                                </ul>

                            </div>

                            <div className="text-sm font-medium text-left pt-[10px]">
                                <span >{t('factsFeatures-appliances')}</span>
                            </div>
                            <div className=" prose sm:prose " >
                                <ul className=" text-xs  text-left">
                                    {facts?.appliances?.map((data) => (
                                        <li>{data}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="  flex-col w-1/2 pt-[23px]">
                            <div className="text-sm font-medium text-left pt-[10px]">
                                <span >{t('factsFeatures-interior-features')}</span>
                            </div>
                            <div className=" prose sm:prose " >
                                <ul className=" text-xs  text-left">
                                    {facts?.interior?.map((data) => (
                                        <li>{data}</li>
                                    ))}
                                </ul>

                            </div>
                            <div className="text-sm font-medium text-left pt-[10px]">
                                <span >{t('factsFeatures-exterior-features')}</span>
                            </div>
                            <div className=" prose sm:prose " >
                                <ul className=" text-xs  text-left">
                                    {facts?.otherInterior?.map((data) => (
                                        <li>{data}</li>
                                    ))}
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className=" flex flex-row">
                        <div className="  flex-col w-1/2 pt-[20px]">
                            <h2 className="text-base font-bold capitalize text-left ">
                                <span >{t('factsFeatures-other-details')}</span>
                            </h2>
                            <div className="text-sm font-medium text-left pt-[10px]">
                                <span >{t('factsFeatures-parking')}</span>
                            </div>
                            <div className=" prose sm:prose " >
                                <ul className=" text-xs  text-left">
                                    {facts?.parking?.map((data) => (
                                        <li>{data}</li>
                                    ))}
                                </ul>

                            </div>


                        </div>
                        <div className="  flex-col w-1/2 pt-[43px]">

                            <div className="text-sm font-medium text-left pt-[10px]">
                                <span >{t('factsFeatures-other-exterior')}</span>
                            </div>
                            <div className=" prose sm:prose " >
                                <ul className=" text-xs  text-left">
                                    {facts?.accesability?.map((data) => (
                                        <li>{data}</li>
                                    ))}
                                </ul>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        );
    };

    return (
        <div
            className={`justify-center  relative overflow-hidden  transition-shadow  ${className}`}>
            <div className="flex flex-row w-full " onClick={showFacts} >
                <div className="my-[9px] text-left w-5/6" >
                    <span className="pl-[15px] block text-md font-semibold  flex items-center justify-left h-full">
                        {t('factsFeatures-title')}
                    </span>
                </div>
                <div className={`flex  w-1/6 justify-end text-white ${isOpen ? "" : ""}`}>
                    <span className="flex rounded-tr-lg items-center justify-center w-[30px] bg-[#00D3DB] cursor-pointer rounded-bl-md">
                        {isOpen ? (
                            <i className=" text-xl font-bold las la-minus"></i>
                        ) : (
                            <i className="text-xl font-bold las la-plus"></i>
                        )}
                    </span>
                </div>

            </div>
            {renderDetail()}
        </div>
    );
};

export default FactFeatures;
