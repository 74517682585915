import React, { FC, ReactNode} from "react";
import { ListingDataType } from "data/types";
import HeaderFilter from "./HeaderFilter";
import PropertyCard from "components/PropertyCardH/PropertyCard";


export interface SectionGridFeaturePropertyProps {
  featuredListings?: ListingDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  propertyDetail: (info: ListingDataType) => void;
}

const SectionGridFeatureProperty: FC<SectionGridFeaturePropertyProps> = ({
  featuredListings = [],
  gridClass = "",
  heading = "",
  subHeading = "",
  headingIsCenter,
  tabs = [],
  propertyDetail
  

}) => {

  const handleClick = (info: ListingDataType) => {
    propertyDetail(info);
  };
  
  const renderCard = (stay: ListingDataType, index: number) => {
    return <PropertyCard key={index} className="h-full" data={stay} onButtonClick={handleClick}/>;
  };

  return (
    <div className="nc-SectionGridFeatureProperty relative">
      <HeaderFilter
        tabActive={""}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      />
      <div
        className={`grid gap-6 md:gap-10 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 sm:mx-[80px] md:mx-[0px] ${gridClass}`}
      >
        {featuredListings.map(renderCard)}
      </div>
     
    </div>
  );
};

export default SectionGridFeatureProperty;
