import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { PostData } from "data/types";
import { Link, useNavigate } from "react-router-dom";
import PostCardMeta from "components/PostCardMeta/PostCardMeta2";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import { DEMO_POSTS } from "data/posts";

export interface Card12Props {
  className?: string;
  post?: PostData;
}

const stripHtml = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};




const Card12: FC<Card12Props> = ({
  className = "h-full",
  post = DEMO_POSTS[0],
}) => {


  const { id, title, featuredImage, desc, date } = post;
  const textOnlyDesc = stripHtml(desc || "");
  const navigate = useNavigate();
  const navigateToBlog = () => {
    navigate(`/blog-single?id=${id}`);
  };
  return (
    <div
      className={`nc-Card12 group relative flex flex-col cursor-pointer  ${className}`}
      data-nc-id="Card12"

    >
      <div
         onClick={navigateToBlog}
        className="block flex-shrink-0 flex-grow relative w-full h-0 aspect-w-4 aspect-h-3 rounded-3xl overflow-hidden"
      >
        <NcImage
          containerClassName="absolute inset-0"
          src={featuredImage}
          alt={title}
        />
        <span>
          <PostTypeFeaturedIcon
            className="absolute bottom-2 left-2"
            wrapSize="w-8 h-8"
            iconSize="w-4 h-4"
          />
        </span>
      </div>

      {/* <SocialsShare className="absolute hidden md:grid gap-[5px] right-4 top-4 opacity-0 z-[-1] group-hover:z-10 group-hover:opacity-100 transition-all duration-300" /> */}

      <div
        className="mt-8 px-2 flex flex-col relative"
        onClick={navigateToBlog}
      >
        <h2 className="nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 transition-colors text-lg sm:text-2xl">
          <div className="line-clamp-2" title={title}>
            {title}
          </div>
        </h2>
        <span className="hidden sm:block mt-4 text-neutral-500 dark:text-neutral-400">
          <span className="line-clamp-3">{textOnlyDesc}</span>
        </span>
        <div className="flex justify-between items-center mt-5">
          <PostCardMeta date={date} />
          <div className="text-[#19CFD8]">Read More</div>
        </div>
      </div>

    </div>
  );
};

export default Card12;
