import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NcImage from "shared/NcImage/NcImage";
import axios from 'axios';
import { PostData } from "data/types";
import Badge from "shared/Badge/Badge";
import BackgroundMap from "components/BackgroundSection/BackgroundMap";
import Footer from "shared/Footer/Footer2";
import { Link, useNavigate } from "react-router-dom";



const BlogSingle = () => {
  const [content, setContent] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [featuredImage, setFeaturedImage] = useState<string>('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const postId = queryParams.get('id');
  const [posts2, setPosts2] = useState<PostData[]>([]);

  const navigate = useNavigate();

  const navigateToBlog = (id: string | number) => {
    navigate(`/blog-single?id=${id}`);
  };


  const fetchTagNames = async (tagIds: number[]) => {
    const fetchPromises = tagIds.map(id =>
      fetch(`https://blog.paradiseliving.homes/wp-json/wp/v2/tags/${id}`).then(response => response.json())
    );

    const tags = await Promise.all(fetchPromises);
    return tags;
  };

  useEffect(() => {
    const fetchContent = async () => {
      if (postId) {
        try {
          const response = await fetch(`https://blog.paradiseliving.homes/wp-json/wp/v2/posts/${postId}`);
          const data = await response.json();
          const featuredImageResponse = await axios.get(`https://blog.paradiseliving.homes/wp-json/wp/v2/media/${data.featured_media}`);
          setFeaturedImage(featuredImageResponse.data.guid.rendered);
          setTitle(data.title.rendered);
          setContent(data.content.rendered);
        } catch (error) {
          console.error('Error fetching post content:', error);
        }
      }
    };
    fetchContent();
  }, [postId]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://blog.paradiseliving.homes/wp-json/wp/v2/posts/');
        const postsData: any[] = response.data;
        const mappedPosts: PostData[] = await Promise.all(postsData.map(async (post: any) => {
          const featuredImageResponse = await axios.get(`https://blog.paradiseliving.homes/wp-json/wp/v2/media/${post.featured_media}`);
          const tags = await fetchTagNames(post.tags);
          return {
            id: post.id,
            date: post.date,
            href: post.link,
            title: post.title.rendered,
            featuredImage: featuredImageResponse.data.guid.rendered,
            desc: post.excerpt.rendered,
            tags: tags.map((tag: any) => ({ id: tag.id, name: tag.name })),
          };
        }));

        setPosts2(mappedPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }

    };

    fetchPosts();
  }, []);

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    );
  };

  const renderPostRelated = (post: PostData) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
        onClick={() => navigateToBlog(post.id)}
      >
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <div className="flex flex-wrap gap-2">
            {(post.tags ?? []).map(tag => (
              <Badge key={tag.id} name={tag.name} />
            ))}
          </div>
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">

            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
      </div>
    );
  };



  return (
    <div className="nc-PageSingle pt-2  ">
      <div className="flex flex-col items-center">
        <h2 className="text-3xl md:text-4xl pt-[40px] font-semibold relative">
          {title}
        </h2>
        <NcImage
          className="w-full max-w-[864px] mx-auto rounded-xl"
          containerClassName="container my-10 sm:my-12"
          src={featuredImage}
        />
      </div>

      <div className="nc-SingleContent  space-y-10">
        {renderContent()}
        <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
          <div className="container ">
            <h2 className="text-3xl font-semibold">Related posts</h2>
            <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">

              {posts2.filter((_, i) => i < 4).map(renderPostRelated)}

            </div>
          </div>
        </div>

      </div>
      <div className="relative ">
        <BackgroundMap />
        <Footer />
      </div>
    </div>
  );
};

export default BlogSingle;
