import React, { FC, useEffect, useState } from "react";
import Textarea from "shared/Textarea/Textarea";
import { AuthorType, ListingDataType } from "data/types";
import { useLocation } from 'react-router-dom';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Link, useNavigate } from 'react-router-dom';
import FormItem from "./FormItem";
import Input from "shared/Input/Input";
import { useTranslation } from 'react-i18next';

export interface PageAddListing7Props { }

const PageAddListing7: FC<PageAddListing7Props> = () => {

  const location = useLocation();
  const propertyInfo = location.state;
  const agent = propertyInfo.agent as AuthorType;
  const property = propertyInfo.property as ListingDataType;
  const [appliances, setAppliances] = useState('');
  const [interior, setInterior] = useState('');
  const [exterior, setExterior] = useState('');
  const [otherAmenities, setOtherAmenities] = useState('');
  const [rentalValue, setRentalValue] = useState('');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  type CheckedItems = Record<string, boolean>;
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});

  const [isAppliancesValid, setIsAppliancesValid] = useState(true);
  const [isInteriorValid, setIsInteriorValid] = useState(true);
  const [isExteriorValid, setIsExteriorValid] = useState(true);
  const [isOtherValid, setIsOtherValid] = useState(true);
  const [isRentalValid, setIsRentalValid] = useState(true);
  const [isNearbyValid, setIsNearbyValid] = useState(true);

  useEffect(() => {

    checkValues();
  }, []);

  const checkValues = () => {

    setAppliances(property.factsFeatures?.appliances?.join(', ') || '');
    setInterior(property.factsFeatures?.interior?.join(', ') || '');
    setExterior(property.factsFeatures?.accesability?.join(', ') || '');
    setRentalValue(property.rentalValue || '');


  }

  const handleRentalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRentalValue(event.target.value);
  };

  const handleApplianceChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAppliances(event.target.value);
  };

  const handleInteriorChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInterior(event.target.value);
  };

  const handleExteriorChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setExterior(event.target.value);
  };

  const handleOtherAmenitiesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherAmenities(event.target.value);
  };


  const handleCheckItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const updatedItems = { ...checkedItems };
    if (checked) {
      updatedItems[name] = true;
    } else {
      delete updatedItems[name];
    }
    setCheckedItems(updatedItems);
  };

  const validateFields = () => {
    setIsAppliancesValid(true);
    setIsInteriorValid(true);
    setIsExteriorValid(true);
    setIsOtherValid(true);
    setIsRentalValid(true);
    setIsNearbyValid(true);

    let isValid = true;
    if (!appliances || appliances.length === 0) {
      setIsAppliancesValid(false);
      isValid = false;
    }

    if (!interior || interior.length === 0) {
      setIsInteriorValid(false);
      isValid = false;
    }

    if (!exterior || exterior.length === 0) {
      setIsExteriorValid(false);
      isValid = false;
    }

    if (!otherAmenities || otherAmenities.length === 0) {
      setIsOtherValid(false);
      isValid = false;
    }

    if (!rentalValue || rentalValue.length === 0) {
      setIsRentalValid(false);
      isValid = false;
    }
    const isEmpty = Object.keys(checkedItems).length === 0;

    if (isEmpty) {
      setIsNearbyValid(false);
      isValid = false;
    }

    return isValid;
  };

  const toNextStep = () => {
    const isValid = validateFields();
    if (!isValid) {
      return;
    }


    if (property.factsFeatures) {
      property.factsFeatures.appliances = appliances.split(',').map(item => item.trim());
      property.factsFeatures.interior = interior.split(',').map(item => item.trim());
      property.factsFeatures.accesability = exterior.split(',').map(item => item.trim());
      property.amenities = Object.keys(checkedItems).filter((key) => checkedItems[key]);
    }
    property.author = agent;
    property.rentalValue = rentalValue;


    navigate('/add-listing-10', { state: property });

  }


  const toPrevtStep = () => {
    const propertyInfo = {
      property,
      agent,
    };

    navigate('/add-listing-4', { state: propertyInfo });
  }


  return (
    <div
      className={`nc-PageAddListing2 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing2"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">4</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 5
          </span>
        </div>
        <div>
          <h2 className="text-2xl font-semibold">{t('pageAddListing7-additional-comments')}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          </span>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="listingSection__wrap ">
          <div className="space-y-8">


            <div>
              <span className="text-xl ">
                {t('pageAddListing7-appliances')}
              </span>
            </div>

            <Textarea placeholder="Type here." rows={8}
              value={appliances || ''}
              onChange={handleApplianceChange}
              className={!isAppliancesValid ? 'border-red-500' : ''}
            />

            <div>
              <span className="text-xl ">
                {t('pageAddListing7-interior-features')}
              </span>
            </div>

            <Textarea placeholder="Type here." rows={8}
              value={interior || ''}
              onChange={handleInteriorChange}
              className={!isInteriorValid ? 'border-red-500' : ''}
            />

            <div>
              <span className="text-xl ">
                {t('pageAddListing7-exterior-features')}
              </span>
            </div>

            <Textarea placeholder="Type here." rows={8}
              value={exterior || ''}
              onChange={handleExteriorChange}
              className={!isExteriorValid ? 'border-red-500' : ''}
            />
            <div>
              <span className="text-xl ">
                {t('pageAddListing7-nearby-amenities')}
              </span>
            </div>
            <div className={!isNearbyValid ? 'listingSection__wrap border-red-500' : 'listingSection__wrap'}>
              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Biking"
                    name="Biking"
                    type="checkbox"
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    onChange={handleCheckItems}
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      {t('pageAddListing7-biking')}
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Beach"
                    name="Beach"
                    type="checkbox"
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    onChange={handleCheckItems}
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      {t('pageAddListing7-beach')}
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Golf"
                    name="Golf"
                    type="checkbox"
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    onChange={handleCheckItems}
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      Golf
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Shopping"
                    name="Shopping"
                    type="checkbox"
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    onChange={handleCheckItems}
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      {t('pageAddListing7-shopping')}
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Pool"
                    name="Pool"
                    type="checkbox"
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    onChange={handleCheckItems}
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      {t('pageAddListing7-pool')}
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Park"
                    name="Park"
                    type="checkbox"
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    onChange={handleCheckItems}
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      {t('pageAddListing7-park')}
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Movies"
                    name="Movies"
                    type="checkbox"
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    onChange={handleCheckItems}
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      {t('pageAddListing7-movies')}
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Supermarket"
                    name="Supermarket"
                    type="checkbox"
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    onChange={handleCheckItems}
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      {t('pageAddListing7-supermarket')}
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Restaurants"
                    name="Restaurants"
                    type="checkbox"
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    onChange={handleCheckItems}
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      {t('pageAddListing7-restaurants')}
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Mall"
                    name="Mall"
                    type="checkbox"
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    onChange={handleCheckItems}
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      {t('pageAddListing7-mall')}
                    </span>
                  </label>
                </div>

              </div>
            </div>
            <FormItem label="Rental Value" >
              <Input placeholder="Add Rental Value"
                value={rentalValue}
                onChange={handleRentalChange}
                className={!isRentalValid ? 'border-red-500' : ''}
              />
            </FormItem>
            <div>
              <span className="text-xl ">
                {t('pageAddListing7-other')}
              </span>
            </div>

            <Textarea placeholder="Type here." rows={8}
              onChange={handleOtherAmenitiesChange}
              className={!isOtherValid ? 'border-red-500' : ''}
            />
          </div>
        </div>
        <div className="flex justify-end space-x-5">
          <ButtonSecondary onClick={toPrevtStep}>{t('pageAddListing10-back')}</ButtonSecondary>
          <ButtonPrimary onClick={toNextStep}> {t('pageAddListing4-continue')} </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default PageAddListing7;
