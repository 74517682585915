import React, { FC, useState } from "react";
import adImage from "images/ad.png";


const SectionAd = ({
    className = "",
  }) => {

  
  
  
    return (
      <div className={`nc-SectionAd bg-[#16385D] ${className} lg:h-[150px]`}
         style={{
        backgroundImage: `url(${adImage})`, // Set the background image using the 'texture' variable
       // backgroundSize: 'cover', // This ensures the image covers the entire section
        // backgroundRepeat: 'no-repeat', // Prevents the image from repeating
        // backgroundPosition: 'center', // Centers the image in the section
        backgroundSize: 'cover', // The image will display at its original size
        backgroundRepeat: 'repeat', // The image will repeat both horizontally and vertically
      }}
      >
      </div>
    );
  };
  
  export default SectionAd;