import React, { FC, Fragment, useState, useRef } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import { ListingDataType } from "data/types";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Dialog, Transition } from "@headlessui/react";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { ClockLoader } from 'react-spinners';


export interface PropertyCardHProps {
  className?: string;
  data: ListingDataType;
  modalDetails: (info: any) => void;
  approveRefresh: (id: any) => void;
  rejectRefresh: (id: any, rejectionMessage: string) => void;
  adsRefresh: (id: any, isAds: Boolean) => void;
}

const AdminPropertyCard: FC<PropertyCardHProps> = ({
  className = "",
  data,
  modalDetails,
  approveRefresh,
  rejectRefresh,
  adsRefresh
}) => {
  var {
    galleryImgs = [],
    title,
    author,
    address,
    saleOff,
    type,
    isAds,
    price,
    id,
    published,
    status,
  } = data;

  const [rejectionMessage, setRejectionMessage] = useState('');
  let [isOpenModalReject, setIsOpenModalReject] = useState(false);
  const form = useRef<HTMLFormElement>(null);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const rejectProperty = () => {

    closeModalReject();
    rejectRefresh(id, rejectionMessage);

  };

  function openModalReject() {
    setIsOpenModalReject(true);
  }

  function closeModalReject() {
    setIsOpenModalReject(false);
  }

  const handleClick = () => {
    modalDetails(data);
  };

  function ApproveProperty(id: string | number) {
    approveRefresh(id);
  };

  function switchAds() {
    adsRefresh(id, !isAds);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRejectionMessage(e.target.value);
  };

  const renderModalReject = () => {
    return (
      <Transition appear show={isOpenModalReject} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalReject}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-[420px] w-full max-w-sm">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-md font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      {t('adminPropertyCard-reject-title')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalReject} />
                    </span>
                  </div>
                  <div className=" px-8  text-neutral-700  " >

                    <div>
                      <div className="grid grid-cols-1 gap-6" >


                        <label className="block pt-[20px]">
                          <Label>{t('adminPropertyCard-reject-message')}</Label>

                          <Textarea
                            className="mt-1"
                            rows={6}
                            name="message"
                            value={rejectionMessage}
                            onChange={handleChange}
                          />
                        </label>
                        <div>
                          <button className="p-3 text-white font-medium  rounded-lg w-full bg-[#00D3DB]" onClick={rejectProperty}>{t('adminPropertyCard-send')}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };


  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0  w-full h-[260px] ">
        <GallerySlider
          ratioClass=""
          galleryImgs={galleryImgs}
          className="w-full h-full overflow-hidden will-change-transform"
          uniqueID={`PropertyCardH_${id}`}
        />


        <SaleOffBadge status={`${status}`} className="absolute right-0 top-0 " />


        {isAds && (
          <button
            className={`absolute left-0 top-0 flex items-center justify-center text-xs py-2.5 px-3 rounded-br-lg text-black bg-[#FFEC59]`}
            onClick={() => {
              if (id !== undefined && id !== null) {
                openModalReject();
              }
            }}
          >
            {t('adminIsAds')}
          </button>
        )}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2">
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bed text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.bedrooms} {t('renderTienIch-beds')}
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bath text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.bathrooms} {t('renderTienIch-baths')}
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.sqrft} {t('renderTienIch-sq')}
          </span>
        </div>
      </div>
    );
  };



  const renderContent = () => {
    return (
      <div className="flex-grow  flex flex-col items-start">
        <div className="space-y-4 w-full flex-grow">
          <div className="flex w-full justify-between px-5 pt-6 items-end">
            <span className="flex items-center justify-center leading-none text-xl font-medium text-[#16385D]">
              {`${price}`}
            </span>
            <span className="flex items-center justify-center leading-none text-sm  text-[#16385D]">
              {published ? "Published" : "Not Published"}
            </span>
          </div>
          <div className="ml-[20px]">
            {renderTienIch()}
            <div className="flex flex-col  pt-[5px] h-[80px]">
              <h2 className="flex justify-left text-sm font-medium capitalize">
                <span className="line-clamp-2">{title}</span>
              </h2>
              <div className="flex justify-left text-sm font-light pt-[5px]">
                {address}
              </div>
            </div>
          </div>

          <div className="w-full h-[40px] flex justify-between ">
            <div className="flex-auto bg-[#19CFD8] flex items-center justify-center">

              <button className="text-xs font-medium capitalize bg-[#00D3DB] text-white px-3  rounded-lg" onClick={() => {
                if (id !== undefined && id !== null) {
                  ApproveProperty(id);
                }
              }}>
                {t('adminPropertyCard-reject-approve')}
              </button>

            </div>
            <div className="flex-auto bg-[#FF0000] flex items-center justify-center">
              <button className="text-xs font-medium capitalize  text-white px-3  rounded-lg cursor-pointer" onClick={openModalReject}>
                {t('adminReject')}
              </button>
            </div>
            <div className="flex-auto bg-[#FFEC59] flex items-center justify-center">

              <button className="text-xs font-medium capitalize bg-[#FFEC59] text-black px-3  rounded-lg" onClick={() => {
                switchAds();
              }}>
                {t('adminAds')}
              </button>

            </div>

          </div>
          <div className="w-full h-[40px] flex justify-between ">

            <div className="flex-auto bg-[#16385D] flex items-center justify-center">
              <button className="text-xs font-medium capitalize  text-white px-3 py-1 rounded-lg cursor-pointer" onClick={handleClick}>
                {t('renderTienIch-details')}
              </button>
            </div>

          </div>

        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group h-[520px]  relative bg-white border border-neutral-100  rounded-md overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      <div className="h-full w-full flex flex-col">
        {renderSliderGallery()}

        {loading ? (
          <div className="flex justify-center items-center h-full pt-[20px]">
            <ClockLoader color="#36d7b7" size={20} />
          </div>
        ) : (
          <div>
          </div>
        )}

        {renderContent()}
        {renderModalReject()}
      </div>

    </div>
  );
};

export default AdminPropertyCard;