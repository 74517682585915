import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import StartRating from "components/StartRating/StartRating";
// import { Link } from "react-router-dom";
// import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { StayDataType } from "data/types";





export interface PropertyCardHProps {
  className?: string;
  data?: StayDataType;
  onButtonClick: (info: any) => void;
}


const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const PropertyCardH: FC<PropertyCardHProps> = ({
  className = "",
  data = DEMO_DATA,
  onButtonClick
}) => {
  const {
    galleryImgs,
    title,
    href,
    like,
    saleOff,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
  } = data;

  const handleClick = () => {
    // Call the callback function and pass the information
    onButtonClick("le di");
  };


  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0  w-full h-[265px]  ">
        <GallerySlider
          ratioClass=""
          galleryImgs={galleryImgs}
          className="w-full h-full overflow-hidden will-change-transform"
          uniqueID={`PropertyCardH_${id}`}
          href={href}
        />

        {saleOff && (
          <SaleOffBadge className="absolute right-0 top-0 !bg-orange-500" />
        )}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2">
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bed text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            6 beds
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bath text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            3 baths
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            1200 Sq. Fit
          </span>
        </div>
      </div>
    );
  };

 

  const renderContent = () => {
    return (
      <div className="flex-grow  flex flex-col items-start">
        <div className="space-y-4 w-full">
          {/* <div className="inline-flex space-x-3">
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-share-alt"></i>
                  <span className="ml-1">4 Network</span>
                </div>
              }
            />
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-user-friends"></i>
                  <span className="ml-1">Family</span>
                </div>
              }
              color="yellow"
            />
          </div> */}

          <div className="flex w-full justify-between px-5 pt-6 items-end">
          <span className="flex items-center justify-center leading-none text-xl font-medium text-[#16385D]">
              {`${price},000`}
            </span>
            {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
          </div>
          <div className="ml-[20px]">
          {renderTienIch()}
            <div className="flex items-center space-x-2 pt-[5px] pb-[20px]">
              {/* {isAds && <Badge name="ADS" color="green" />} */}
              <h2 className="text-sm font-medium capitalize">
                <span className="line-clamp-2">{title}</span>
              </h2>
            </div>
          </div>
          <div className="w-full h-[40px] bg-black ">
             <div className="flex h-full ">
                <div className="flex-auto bg-[#19CFD8] flex items-center justify-center">
                <h2 className="text-xs font-medium capitalize text-white">
                <span> Save Now</span>
              </h2>
                </div>  
                <div className="flex-auto  bg-[#16385D]  flex items-center justify-center" >
                  <h2 className="text-xs font-medium capitalize text-white">
                  {/* <span className="cursor-pointer " > View Details</span> */}
                    <span className="cursor-pointer " onClick={handleClick}> View Details</span>
                  </h2>
                </div>  
                {/* {renderModalDetails()} */}
            </div>
          </div> 
          {/* <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div> */}
          {/* <div className="flex w-full justify-between items-end">
            <StartRating reviewCount={reviewCount} point={reviewStart} />
            <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
              {`${price},000`}
            </span>
          </div> */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group h-[520px]  relative bg-white border border-neutral-100  rounded-md overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      {/* <Link to={href} className="absolute inset-0"></Link> */}
      <div className="h-full w-full flex flex-col  ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      {/* <BtnLikeIcon
        colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
        isLiked={like}
        className="absolute right-5 top-5 sm:right-3 sm:top-3 "
      /> */}
    </div>
  );
};

export default PropertyCardH;
