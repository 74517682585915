import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const PropertySkeleton = () =>{
    return (
        <div
        className={`nc-card-skeleton group h-[520px]  relative bg-white border border-neutral-100  rounded-md overflow-hidden hover:shadow-xl transition-shadow `}
        data-nc-id="card-skeleton"
      >
         <div className="property-card">
      <div className="property-image">
        <Skeleton height={250} />
      </div>
      <div className="property-details pt-[30px] px-[20px]">
        <h2>
          <Skeleton width={80} />
        </h2>
        <div className="property-details pt-[30px]">
        <p>
          <Skeleton count={5} style={{ margin: '5px 0' }} />
        </p>
        </div>
      
      </div>
    </div>
        </div>
    );
};
export default PropertySkeleton;