
import { CustomLink } from "data/types";
import React, { Fragment, useState } from "react";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { SocialType } from "shared/SocialsShare/SocialsShare";
import instagram from "images/instagram.png";
import facebook from "images/facebook.png";
import linkedin from "images/linkedin.png";
import { ClockLoader } from 'react-spinners';
import axios from 'axios';
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from 'react-i18next';

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer2: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [info, setInfo] = useState('');
  const [message, setMessage] = useState('');
  const { t, i18n } = useTranslation();
  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isInfoValid, setIsInfoValid] = useState(true);
  const [isMessageValid, setIsMessageValid] = useState(true);

  let [isModal, setIsModal] = useState(false);

  const socialsDemo: SocialType[] = [
    { name: "Facebook", icon: facebook, href: "https://www.facebook.com/people/Paradise-Living/61553196781710/" },
    { name: "Linkedin", icon: linkedin, href: "https://www.linkedin.com/company/paradise-living-homes/?viewAsMember=true" },
    { name: "Instagram", icon: instagram, href: "https://www.instagram.com/paradiselivinghomes/" },
  ];

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const handleInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInfo(event.target.value);
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  function closeModal() {
    setIsModal(false);
}

const renderModal = () => {
    return (
        <Transition appear show={isModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 " />
                    </Transition.Child>

                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block py-8 h-[100px] w-[350px]">
                            <div className="bg-white border-t-4 border-[#00D3DB] rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                                <div className="flex">
                                    <div className="py-1"><svg className="fill-[#00D3DB] h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                                    <div>
                                        <p className="font-bold text-center">Message Sent</p>
                                        <p className="text-sm text-center">your message was successfully sent, please wait to be contacted. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

  const validateFields = () => {
    setIsNameValid(true);
    setIsEmailValid(true);
    setIsPhoneValid(true);
    setIsInfoValid(true);
    // setIsMessageValid(true);

    let isValid = true;
    if (!name) {
      setIsNameValid(false);
      isValid = false;
    }

    if (!email) {
      setIsEmailValid(false);
      isValid = false;
    }

    if (!phone) {
      setIsPhoneValid(false);
      isValid = false;
    }

    if (!info) {
      setIsInfoValid(false);
      isValid = false;
    }

    return isValid;
  };

  const updateAgentData = async () => {
    const isValid = validateFields();
    if (!isValid) {
      return;
    }

    const payload = {
      name: name,
      email: email,
      phone: phone,
      info: info,
      message: message,

    };

    setLoading(true);
    axios.post(process.env.REACT_APP_API + `info/touch`, payload)
      .then((response) => {
        setLoading(false);
        validateFields();
      })
      .catch((error) => {
        setLoading(false);
        setIsModal(true);
        console.error('Error fetching properties:', error);
      });
  };



  return (
    <div className="flex flex-row relative    ">
      <div className=" flex-col min-w-[360px] md:min-w-[460px]  h-[800px] ">


        <div className="ml-[50px] h-[3px]  bg-[#19CFD8] "></div>
        <div className="ml-[50px] h-[700px] rounded-b-md bg-[white]">
          <div className=" px-8  text-neutral-700  ">
            <h2 className="text-3xl font-bold pt-[20px] ">
              <span > {t('footer2-title')}</span>
            </h2>
            <div>
              <div className="grid grid-cols-1 gap-6" >
                <label className="block pt-[20px]">
                  <Label>{t('footer2-name')}</Label>

                  <Input
                    placeholder={t('footer2-name-placeholder')}
                    type="text"
                    onChange={handleNameChange}
                    className={!isNameValid ? 'border-red-500 mt-1 rounded-md' : 'mt-1 rounded-md'}
                  />
                </label>
                <label className="block">
                  <Label>{t('footer2-email')} </Label>

                  <Input
                    type="email"
                    placeholder={t('footer2-email-placeholder')}
                    onChange={handleEmailChange}
                    className={!isEmailValid ? 'border-red-500 mt-1 rounded-md' : 'mt-1 rounded-md'}
                  />
                </label>
                <label className="block">
                  <Label>{t('footer2-phone')} </Label>

                  <Input
                    type="text"
                    placeholder={t('footer2-phone-placeholder')}
                    onChange={handlePhoneChange}
                    className={!isPhoneValid ? 'border-red-500 mt-1 rounded-md' : 'mt-1 rounded-md'}
                  />
                </label>
                <label className="block">
                  <Label>{t('footer2-hear')} </Label>

                  <Input
                    type="text"
                    placeholder="info"
                    onChange={handleInfoChange}
                    className={!isInfoValid ? 'border-red-500 mt-1 rounded-md' : 'mt-1 rounded-md'}
                  />
                </label>
                <label className="block">
                  <Label>{t('footer2-message')}</Label>

                  <Textarea className="mt-1" rows={4} onChange={handleMessageChange} />
                </label>
                <div>
                  {loading ? (
                    <div className="px-[18px] pt-[5px]">
                      <ClockLoader color="#36d7b7" size={20} />
                    </div>
                  ) : (
                    <div>
                      <button className="p-3 text-white font-medium  rounded-lg w-full bg-[#00D3DB]" type="button" onClick={updateAgentData}>Send</button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {renderModal()}
      <div className="hidden md:block  flex-col w-full h-[700px] ">
        <div className={`pl-[20px] flex-grow h-full pt-[628px]`}>
          <div className={`flex space-x-2.5 text-4xl`}>
            {socialsDemo.map((item, i) => (
              <a
                key={i}
                className="block text-white"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                title={item.name}
              >
                <img src={item.icon} className="w-10" alt="" />
              </a>
            ))}
          </div>
          <div className="text-xs flex flex-col text-white ">
            <span >{t('footer2-copyright')}</span>
            <span >{t('footer2-policy')}</span>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Footer2;