import React, { FC, useEffect, useRef, useState } from "react";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import HeroSearchFormSmall, {
  SearchTab,
} from "components/HeroSearchFormSmall/HeroSearchFormSmall";
import { Link, useLocation } from "react-router-dom";
import MenuBar from "shared/MenuBar/MenuBar";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { StaySearchFormFields } from "components/HeroSearchForm/type";
import texture from "images/bg_Texture5.png";
import LogoLArge from "shared/Logo/LogoLarge";
import { useMediaQuery } from "@react-hook/media-query";
import RefinedSearchForm from "../HeroSearchForm/RefinedSearchForm";
import { useTranslation } from 'react-i18next';

interface Header3Props {
  className?: string;
}

let WIN_PREV_POSITION = window.pageYOffset;

const Header3: FC<Header3Props> = ({ className = "" }) => {
  const headerInnerRef = useRef<HTMLDivElement>(null);
  //
  const [showHeroSearch, setShowHeroSearch] =
    useState<StaySearchFormFields | null>();
  //
  const [currentTab, setCurrentTab] = useState<SearchTab>("Stays");
  const isWideScreen = useMediaQuery("(min-width: 1200px)");
  const { t, i18n } = useTranslation();
  useOutsideAlerter(headerInnerRef, () => {
    setShowHeroSearch(null);
    setCurrentTab("Stays");
  });

  let location = useLocation();
  //

  useEffect(() => {
    setShowHeroSearch(null);
  }, [location]);

  // HIDDEN WHEN SCROLL EVENT
  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
    return () => {
      window.removeEventListener("scroll", handleEvent);
    };
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(handleHideSearchForm);
  };

  const handleHideSearchForm = () => {
    if (!document.querySelector("#nc-Header-3-anchor")) {
      return;
    }

    let currentScrollPos = window.pageYOffset;
    if (
      WIN_PREV_POSITION - currentScrollPos > 100 ||
      WIN_PREV_POSITION - currentScrollPos < -100
    ) {
      setShowHeroSearch(null);
    } else {
      return;
    }
    WIN_PREV_POSITION = currentScrollPos;
  };

  //
  const renderHeroSearch = () => {
    return (
      <div
        className={`absolute inset-x-0 top-0 transition-all will-change-[transform,opacity] ${showHeroSearch
          ? "visible"
          : "-translate-x-0 -translate-y-[90px] scale-x-[0.395] scale-y-[0.6] opacity-0 invisible pointer-events-none"
          }`}
      >
        <div className={`w-full `}>
          
          <RefinedSearchForm />
        </div>
      </div>
    );
  };

  const renderButtonOpenHeroSearch = () => {
    return (
      <div
        className={`w-[350px] bg-white relative flex items-center justify-between border border-neutral-200  rounded-full shadow hover:shadow-md transition-all 
        ${showHeroSearch
            ? "invisible"
            : "visible"
          }`}
      >
        <div className="flex items-center font-medium text-sm">
          <span
            onClick={() => setShowHeroSearch("location")}
            className="block pl-5 pr-4 cursor-pointer py-3"
          >
            {t('Header3-title')}
          </span>

        </div>

        <div
          className="flex-shrink-0 ml-auto pr-2 cursor-pointer"
          onClick={() => setShowHeroSearch("location")}
        >
          <span className="w-8 h-8 flex items-center justify-center rounded-full bg-[#19CFD8]  text-white">
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`nc-Header nc-Header-3 fixed z-40 top-0 inset-0 bg-black/30 dark:bg-black/50 transition-opacity will-change-[opacity] ${showHeroSearch ? "visible" : "invisible opacity-0 pointer-events-none"
          }`}
      ></div>
      {showHeroSearch && <div id="nc-Header-3-anchor"></div>}
      <header ref={headerInnerRef} className={`sticky top-0 z-40 ${className}`}>
        <div
          className={`bg-white dark:bg-neutral-900 absolute h-full inset-x-0 top-0 transition-transform will-change-[transform,opacity]
          ${showHeroSearch ? "duration-75" : ""} 
          ${showHeroSearch
              ? currentTab === "Cars" || currentTab === "Flights"
                ? "scale-y-[4.4]"
                : "scale-y-[8.8]"
              : ""
            }`}
        ></div>

        <div className="relative h-[97px]">
          <div className={`absolute left-0 top-0  ${className} opacity-50  w-full  h-full z-0`} style={{
            backgroundImage: `url(${texture})`,
            backgroundSize: 'auto',
            backgroundRepeat: 'repeat',
          }}>
          </div>

          <div className="relative h-full">


            <div className={`justify-start flex-1 items-center absolute inset-0 z-0 ml-[20px] ${isWideScreen ? '' : 'hidden'}`}>
              <div className="flex h-full items-center">
                <LogoLArge />
              </div>
            </div>

            <div className="hidden lg:flex h-full justify-center items-center z-10">
              {renderButtonOpenHeroSearch()}
            </div>
            <div className=" h-full flex justify-center items-center lg:hidden z-10">
              <HeroSearchForm2MobileFactory />
            </div>

            {renderHeroSearch()}
          </div>


        </div>

      </header>
    </>
  );
};

export default Header3;
