import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { Fragment, useState } from "react";
import ButtonSubmit from "./ButtonSubmit";
import { useTimeoutFn } from "react-use";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropertyTypeSelect from "./(real-estate-search-form)/PropertyTypeSelect";
import { ClassOfProperties } from "components/HeroSearchForm/type";
import FormItem from "../../containers/PageAddListing1/FormItem";
import Input from "shared/Input/Input";
import { useSearch } from '../../SearchContext';
import { ClockLoader } from 'react-spinners';

const HeroSearchForm2RealEstateMobile = () => {
  const { listings, setListings } = useSearch();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);
  //
  function closeModal() {
    navigate('/', { state: "info" });
    setShowModal(false);
  }
  const [fieldNameShow, setFieldNameShow] = useState<
    "location" | "propertyType" | "price"
  >("location");
  //
  const [selectedCategory, setSelectedCategory] = useState('Homes');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [typeOfProperty, setTypeOfProperty] = useState<ClassOfProperties[]>([
    {
      name: "Homes",
      description: "description",
      checked: true,
    },
    {
      name: "Apartments",
      description: "description ",
      checked: false,
    },
    {
      name: "Commercial",
      description:
        "description",
      checked: false,
    },
    {
      name: "Rental",
      description:
        "description",
      checked: false,
    },
  ]);



  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLocation(event.target.value);
  };

  const handleSearch = () => {
    setLoading(true);
    axios.get(process.env.REACT_APP_API + `property/search?type=${selectedCategory}&address=${selectedLocation}`)

      .then((response) => {
        setLoading(false);
        // console.log("vamos a search form");
        // console.log(response.data);
        setListings(response.data);
        // navigate('/listing-stay-map', { state: response.data });
        navigate('/listing-stay-map');

      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching properties:', error);
      });
  };


  const renderInputLocation = () => {
    const isActive = fieldNameShow === "location";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          loading ? (
            <div className="px-[18px] pt-[5px]">
              <ClockLoader color="#36d7b7" size={20} />
            </div>
          ) : (
            <button
              className="w-full flex justify-between text-sm font-medium p-4"
              onClick={() => setFieldNameShow("location")}
            >
              <span className="text-neutral-400">Where</span>
              <span>{"Location"}</span>
            </button>
          )
        ) : (
          <FormItem label="">
            <Input
              placeholder="Property Address"
              value={selectedLocation || ''}
              onChange={handleLocationChange}
            />
          </FormItem>
        )}
      </div>
    );
  };

  const renderInputPropertyType = () => {
    const isActive = fieldNameShow === "propertyType";

    let typeOfPropertyText = "";
    if (typeOfProperty && typeOfProperty.length > 0) {
      typeOfPropertyText = typeOfProperty
        .filter((item) => item.checked)
        .map((item) => {
          return item.name;
        })
        .join(", ");
    }

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("propertyType")}
          >
            <span className="text-neutral-400">Property</span>
            <span className="truncate ml-5">
              {typeOfPropertyText || "Add property"}
            </span>
          </button>
        ) : (
          <PropertyTypeSelect
            defaultValue={typeOfProperty}
            onChange={setTypeOfProperty}
          />
        )}
      </div>
    );
  };


  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return (
      <button
        onClick={openModal}
        className="relative flex items-center w-[200px] border bg-white border-neutral-200 dark:border-neutral-6000 px-2 py-2 pr-7 rounded-full shadow-lg"
      >
        <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5" />

        <div className="ml-[20px]  ">
          <span className="block m-1 text-sm">Search Property</span>
        </div>


        {/* <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
          <svg
            viewBox="0 0 16 16"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            className="block w-4 h-4"
            fill="currentColor"
          >
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
        </span> */}
      </button>
    );
  };

  return (
    <div className="HeroSearchForm2RealEstateMobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-max"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  {showDialog && (
                    <Tab.Group manual>
                      <div className="absolute left-4 top-4">
                        <button className="" onClick={closeModal}>
                          <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>
                      <div className="flex-1 pt-[35px] px-1.5 sm:px-4 flex overflow-hidden">
                        <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">

                              <div className="w-full space-y-5">
                                {renderInputLocation()}
                                {renderInputPropertyType()}
                              </div>


                            </div>
                          </Tab.Panel>

                        </Tab.Panels>
                      </div>
                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                        <button
                          type="button"
                          className="underline font-semibold flex-shrink-0"
                          onClick={() => {
                            setShowDialog(false);
                            resetIsShowingDialog();
                          }}
                        >
                          Clear all
                        </button>
                        <ButtonSubmit
                          onClick={() => {
                            handleSearch();
                          }}
                        />
                      </div>
                    </Tab.Group>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default HeroSearchForm2RealEstateMobile;
