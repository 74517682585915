import React, { FC } from "react";
import imagePng from "images/hero-right-1.jpg";
import imagePng4 from "images/hero-right-2.jpg";
import imagePng5 from "images/hero-right-3.jpg";
import imagePng6 from "images/hero-right-4.jpg";
import GallerySlider from "components/GallerySlider/GallerySliderHero";
import HeroRealEstateSearchForm from "components/HeroSearchForm/(real-estate-search-form)/HeroRealEstateSearchForm2";

export interface SectionHero2Props {
  className?: string;
  children?: React.ReactNode;
}

const galleryImgs = [imagePng, imagePng4, imagePng5, imagePng6];




const renderSliderGallery = () => {
  return (

    <div className="absolute inset-0 object-cover w-full h-full">
      <GallerySlider
        ratioClass=""
        galleryImgs={galleryImgs}
        uniqueID={`StayCardH_id1`}
        href={"/"}
      />

    </div>
  );
};

const SectionHero2: FC<SectionHero2Props> = ({ className = "", children }) => {
  return (
    <div
      className={`nc-SectionHero2 relative ${className} h-[450px] sm:h-[750px] md:h-[800px] lg:h-[800px]`}
      data-nc-id="SectionHero2"
    >
      <div className="absolute inset-0 w-full   ">
        {renderSliderGallery()}
      </div>
      <div className="relative pt-[110px] ">
        <div className="hidden lg:block ">
          <HeroRealEstateSearchForm />
        </div>
      </div>
    </div>
  );
};

export default SectionHero2;
