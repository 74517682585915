import Heading from "shared/Heading/Heading";
import NcImage from "shared/NcImage/NcImage";
import NcPlayIcon2 from "shared/NcPlayIcon2/NcPlayIcon2";
import React, { FC, useState } from "react";
import { useTranslation } from 'react-i18next';

export interface VideoType {
  id: string;
  title: string;
  thumbnail: string;
  description: string;
}

export interface SectionVideosProps {
  videos?: VideoType[];
  className?: string;
}

const VIDEOS_DEMO: VideoType[] = [
  {
    id: "Ao7e4iisKMs",
    title: "Main Video",
    thumbnail:
      "https://images.pexels.com/photos/131423/pexels-photo-131423.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    description: "Achive a decisive competitive advantage with the industry's leading cloud-native, unified source-to-pay", 
  },
  {
    id: "a5V6gdu5ih8",
    title: "Where",
    thumbnail:
      "https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    description: "Achive a decisive competitive advantage with the industry's leading cloud-native, unified source-to-pay",  
  },
  {
    id: "MuB7HHeuNbc",
    title: "When",
    thumbnail:
      "https://images.pexels.com/photos/1660995/pexels-photo-1660995.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    description: "Achive a decisive competitive advantage with the industry's leading cloud-native, unified source-to-pay",
  },
  {
    id: "eEaZvEZye84",
    title: "How",
    thumbnail:
      "https://images.pexels.com/photos/4983184/pexels-photo-4983184.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    description: "Achive a decisive competitive advantage with the industry's leading cloud-native, unified source-to-pay",
  },
  {
    id: "EuDJZDaSP0Q",
    title: "Then",
    thumbnail:
      "https://images.pexels.com/photos/2549018/pexels-photo-2549018.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    description: "Achive a decisive competitive advantage with the industry's leading cloud-native, unified source-to-pay",
  },
];

const SectionVideos2: FC<SectionVideosProps> = ({
  videos = VIDEOS_DEMO,
  className = "",
}) => {
  const [isPlay, setIsPlay] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(0);
  const { t, i18n } = useTranslation();

  const renderMainVideo = () => {
    const video: VideoType = videos[currentVideo];
    return (
      <div
        className="group aspect-w-16 aspect-h-16 sm:aspect-h-9 bg-neutral-800 rounded-2xl overflow-hidden border-4 border-white dark:border-neutral-900  will-change-transform"
        title={video.title}
      >
        {isPlay ? (
          <iframe
            src={`https://www.youtube.com/embed/${video.id}?autoplay=1`}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <>
            <div
              onClick={() => setIsPlay(true)}
              className="cursor-pointer absolute inset-0 flex items-center justify-center z-10"
            >
              <NcPlayIcon2 />
            </div>
            <NcImage
              containerClassName="absolute inset-0 "
              className="object-cover w-full h-full transform transition-transform group-hover:scale-105 duration-300 nc-will-change-transform"
              src={video.thumbnail}
              title={video.title}
              alt={video.title}
            />
          </>
        )}
      </div>
    );
  };

  const renderSubVideo = (video: VideoType, index: number) => {
    if (index === currentVideo) return null;
    return (
     <div className="flex flex-col ">
      <div
        className="group relative aspect-h-16 aspect-w-16 rounded-lg cursor-pointer overflow-hidden sm:aspect-h-12 sm:rounded-lg lg:aspect-h-9 will-change-transform"
        onClick={() => {
          setCurrentVideo(index);
          !isPlay && setIsPlay(true);
        }}
        title={video.title}
        key={String(index)}
      >
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <NcPlayIcon2 />
        </div>
        <NcImage
          containerClassName="absolute inset-0 w-full h-full"
          className="object-cover w-full h-full transform transition-transform group-hover:scale-110 duration-300 nc-will-change-transform"
          src={video.thumbnail}
          title={video.title}
          alt={video.title}
        />
      </div>
        <div className="hidden md:block">
            <div className="pt-[15px] font-semibold text-[#16385D]">
                {video.title}
            </div>
            <div className="pt-[5px] text-xs text-[#393939] font-light">
                {video.description}
            </div>
        </div>
      </div>
    
    );
  };

  return (
    <div className={`nc-SectionVideos ${className} py-[80px]`}>
     

      <div className="flex flex-col relative sm:pr-4 sm:py-4 md:pr-6 md:py-6 xl:pr-14 xl:py-14 lg:flex-row">
        <div className="flex-grow relative pb-2 sm:pb-4 lg:pb-0 lg:pr-5 xl:pr-6">
          {renderMainVideo()}
        </div>
        <Heading
        desc= {t('sectionVideos2-text')}
      >
        <div className="block  ">
        {t('sectionVideos2-title')}
        </div>       
        {t('sectionVideos2-sub-title')}
      </Heading>
        
      </div>

      {/* <div className="flex flex-col relative">
      <div className="flex-shrink-0 grid gap-7 grid-cols-4 ">
          {videos.map(renderSubVideo)}
        </div>

      </div> */}

    </div>
  );
};

export default SectionVideos2;