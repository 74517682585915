"use client";

import React, { FC, useState } from "react";
import RealEstateSearchForm from "./RealEstateSearchForm2";


export interface HeroRealEstateSearchFormProps {
  className?: string;
}

const HeroRealEstateSearchForm2: FC<HeroRealEstateSearchFormProps> = ({
  className = "",
}) => {
  
  return (
    <div className="flex "> 
    <div
      className={`nc-HeroRealEstateSearchForm w-full lg:max-w-xl py-5 lg:py-0 ml-[80px] ${className}`}
    >
      <RealEstateSearchForm />
    </div>
    </div>
  );
};

export default HeroRealEstateSearchForm2;