import React from "react";
import MyRouter from "routers/index";
import { SkeletonTheme } from "react-loading-skeleton";
import { SearchProvider } from "./SearchContext";
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n';

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
     <I18nextProvider i18n={i18n}>
     <SkeletonTheme>
     <SearchProvider>
          <MyRouter />
        </SearchProvider>
      </SkeletonTheme>
      </I18nextProvider>
    </div>
  );
}

export default App;
