import React, { Fragment, useState, FC } from "react";
import piggi from "images/piggi.png";
import { useTranslation } from 'react-i18next';

export interface StayDatesRangeInputProps {
  className?: string;
  defaultOpen?: boolean;
  value?: String;
}

const RentalValue: FC<StayDatesRangeInputProps> = ({
  className = "flex-1",
  defaultOpen = false,
  value
}) => {

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const { t, i18n } = useTranslation();

  function showFacts() {
    setIsOpen(!isOpen);

  }

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="flex flex-row items-center">
        <div className="flex flex-col ml-[20px] pt-[20px] justify-left">
          <span className="flex mt-[10px] text-xl font-bold">
            ${value}/mo
          </span>
          <span className="mb-[30px] flex text-neutral-900 dark:text-neutral-100">
            {t('rentalValue-estimate')}
          </span>
        </div>

        <img src={piggi} alt="Description of your image" className="mx-auto" />

      </div>
    );
  };

  return (
    <div
      className={`justify-center  relative overflow-hidden  transition-shadow  ${className}`}>
      <div className="flex flex-row w-full " onClick={showFacts} >
        <div className="my-[9px] text-left w-5/6" >
          <span className="pl-[15px] block text-md font-semibold  flex items-center justify-left h-full">
            {t('rentalValue-value')}
          </span>
        </div>
        <div className={`flex  w-1/6 justify-end text-white ${isOpen ? "" : ""}`}>
          <span className="flex rounded-tr-lg items-center justify-center w-[30px] bg-[#00D3DB] cursor-pointer rounded-bl-md">
            {isOpen ? (
              <i className=" text-xl font-bold las la-minus"></i>
            ) : (
              <i className="text-xl font-bold las la-plus"></i>
            )}
          </span>
        </div>

      </div>

      {renderDetail()}
    </div>
  );
};

export default RentalValue;
