// import React, { FC } from "react";
import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { PostDataType } from "data/types";
import { PostData } from "data/types";
import Card12 from "./Card12";
import hAzul from "images/hojaAzul.png";
import SectionGridMainSkeleton from "../PageHome/SectionGridMainSkeleton";
import { useTranslation } from 'react-i18next';

export interface SectionMagazine5Props {
  posts: PostDataType[];

}

const SectionMagazine5: FC<SectionMagazine5Props> = ({ posts }) => {
  const [posts2, setPosts2] = useState<PostData[]>([]);
  const [isLoadingBlog, setIsLoadingBlog] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://blog.paradiseliving.homes/wp-json/wp/v2/posts/');
        const postsData: any[] = response.data;
        const mappedPosts: PostData[] = await Promise.all(postsData.map(async (post: any) => {
          const featuredImageResponse = await axios.get(`https://blog.paradiseliving.homes/wp-json/wp/v2/media/${post.featured_media}`);
          return {
            id: post.id,
            date: post.date,
            href: post.link,
            title: post.title.rendered,
            featuredImage: featuredImageResponse.data.guid.rendered,
            desc: post.excerpt.rendered,
          };
        }));
        setIsLoadingBlog(false);
        setPosts2(mappedPosts);
      } catch (error) {
        setIsLoadingBlog(false);
        console.error('Error fetching posts:', error);
      }

    };

    fetchPosts();
  }, []);


  return (
    <div className="nc-SectionMagazine5">


      <div className="flex flex-col items-center justify-center ">
        <div className="py-[5px]  ">
          <img
            className="mx-auto h-25 w-20"
            src={hAzul}
            alt=""
          />
        </div>
        <h2 className="text-3xl md:text-4xl font-semibold">
          {t('sectionMagazine5-title')}
          <div className="bg-[#19CFD8] w-full h-[1px]" > </div>
        </h2>
        <div className="pt-4 pb-[40px] font-normal text-base text-neutral-500 text-center px-[10px] md:px-[200px]">
          {t('sectionMagazine5-text')}
        </div>
      </div>



      {isLoadingBlog ? (
        <SectionGridMainSkeleton />
      ) : (
        <div className="grid lg:grid-cols-3 gap-6 md:gap-8">
          {posts2.map((post) => (
            <Card12 key={post.id} post={post} />
          ))}
        </div>
      )}

    </div>
  );


};

export default SectionMagazine5;
