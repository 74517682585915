import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header3 from "components/Header/Header3";
import Header from "components/Header/Header";
import { Link, useLocation } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { PathName } from "routers/types";
import imageHouse from "images/house.png";
import arrow from "images/arrowDown.png";
import arrowUp from "images/arrowUp.png";
import { useTranslation } from 'react-i18next';

export type SiteHeaders = "Header 1" | "Header 2" | "Header 3";

interface HomePageItem {
  name: string;
  slug: PathName;
}

let OPTIONS = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};
let OBSERVER: IntersectionObserver | null = null;
const PAGES_HIDE_HEADER_BORDER: PathName[] = [
  "/home-3",
  "/listing-car-detail",
  "/listing-experiences-detail",
  "/listing-stay-detail",
];

const SiteHeader = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  
  const [headerSelected, setHeaderSelected] =
    React.useState<SiteHeaders>("Header 1");
  const [isArrowRotated, setIsArrowRotated] = useState(false);
  const [isTopOfPage, setIsTopOfPage] = React.useState(window.pageYOffset < 5);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      setIsTopOfPage(entry.isIntersecting);
    });
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setHeaderSelected("Header 1");
    }
    if (location.pathname === "/listing-stay-map") {
      setHeaderSelected("Header 3");
    }
    if (location.pathname === "/agent") {
      setHeaderSelected("Header 1");
    }
    

    if (!PAGES_HIDE_HEADER_BORDER.includes(location.pathname as PathName)) {
      OBSERVER && OBSERVER.disconnect();
      OBSERVER = null;
      return;
    }
    if (!OBSERVER) {
      OBSERVER = new IntersectionObserver(intersectionCallback, OPTIONS);
      anchorRef.current && OBSERVER.observe(anchorRef.current);
    }
  }, [location.pathname]);



  const renderControlSelections = () => {
    const handleButtonClick = () => {
      setIsArrowRotated(!isArrowRotated);
    };

    return (
      <div className="relative z-40 hidden lg:block">
        <div className="fixed right-0 top-1/4 z-40 flex items-center ">
          <Popover className="relative">
            <>
              <div className="relative pl-[100px] ">
                <Popover.Button
                  onClick={handleButtonClick}
                  className={` p-[2px] bg-[#19CFD8] w-[210px] h-[45px] focus:outline-none`}
                >
                  <div className="flex flex-row  items-center">
                    <div className="relative  w-[60px] h-[60px] ml-[-35px] mt-[-8px] z-max">
                      <img
                        className="absolute inset-0 object-cover rounded-full"
                        src={imageHouse}
                        alt="hero"
                      />
                    </div>
                    <span className="text-m text-white font-bold pb-[6px] pl-[18px]"> {t('siteHeader')}</span>
                    <img
                        className={`pl-[8px] pb-[7px] }`}
                        src={isArrowRotated ? arrowUp : arrow}
                        alt=""
                      />
                  </div>
                </Popover.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1 "
              >
                <Popover.Panel className=" w-[210px] h-[130px] bg-[#19CFD8] ml-[100px] mt-[-25px] rounded-bl-lg z-[30] ">

                  <div className="rounded-2xl  overflow-hidden">

                    <div className="flex flex-col  pl-11 py-[5px]">
                      <h1 className="text-sm font-medium text-black pt-[5px]">
                        <span className="line-clamp-2">{t('siteHeader-join')}</span>
                      </h1>
                      <h1 className="text-sm font-medium text-black ">
                        <span className="line-clamp-2">{t('siteHeader-carrer')}</span>
                      </h1>
                      <h1 className="text-sm font-medium text-black ">
                        <span className="line-clamp-2">{t('siteHeader-next-level')}</span>
                      </h1>

                    </div>
                    <div className="px-[45px] py-[5px]">
                     
                      <button className="bg-white rounded-md h-7 text-md font-semibold text-[#00D3DB] px-[19px]">
                        <a href="https://paradiseliving.homes/">{t('siteHeader-signup')}</a>

                      </button>
                    </div>
                   
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          </Popover>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    let headerClassName = "shadow-sm ";
    if (PAGES_HIDE_HEADER_BORDER.includes(location.pathname as PathName)) {
      headerClassName = isTopOfPage
        ? ""
        : "shadow-sm ";
    }
    switch (headerSelected) {
      case "Header 1":
        return <Header className={headerClassName} navType="MainNav3" />;
      case "Header 2":
        return <Header className={headerClassName} navType="MainNav2" />;
      case "Header 3":
        return <Header3 className={headerClassName} />;

      default:
        return <Header className={headerClassName} />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Paradise Living</title>
      </Helmet>
      {location.pathname === "/" && (
        renderControlSelections()
      )}

      {renderHeader()}
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  );
};

export default SiteHeader;
