import React from "react";
import { Link } from "react-router-dom";
// import logoImg from "images/Paradise_logo.png";
// import logoLightImg from "images/logo-light.png";
import texture from "images/logoLarge.svg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  // img = logoImg,
  // imgLight = logoLightImg,
  className = " w-[270px]",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
     
      <img
          className=""
          src={texture}
          alt="Logo-Large"
        />
    </Link>
  );
};

export default Logo;
