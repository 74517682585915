import React, { FC } from "react";
import LocationInput from "../LocationInput";


export interface RealEstateSearchFormProps {}

const RealEstateSearchForm2: FC<RealEstateSearchFormProps> = () => {
  const renderForm = () => {
    return (
      <div className="w-full  mt-8  h-[130px] rounded-3xl  shadow-xl  bg-white">
        <LocationInput className="" />
      </div>
    );
  };

  return renderForm();
};

export default RealEstateSearchForm2;