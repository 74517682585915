import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import React, { FC, useEffect, useMemo } from "react";
// import NcImage from "shared/NcImage/NcImage";
// import NextPrev from "shared/NextPrev/NextPrev";
// import { Link } from "react-router-dom";

export interface GallerySliderProps {
  className?: string;
  galleryImgs: string[];
  ratioClass?: string;
  uniqueID: string;
  href?: string;
}

const GallerySliderHero: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-4 aspect-h-3",
  uniqueID = "uniqueID",
  href = "/listing-stay-detail",
}) => {
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}` + useNcId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 1,
      gap: 0,
      keyboard: false,
      autoplay: 6800,
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 10);
  }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

  const renderDots = () => {
    return (
      <div
        className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-4"
        data-glide-el="controls[nav]"
      >
        {galleryImgs.map((_, i) => (
          <button
            className="glide__bullet w-2.5 h-2.5 rounded-full bg-white"
            key={i}
            data-glide-dir={`=${i}`}
          />
        ))}
      </div>
    );
  };

  const renderSliderGallery = () => {
    return (
    //   <div className={`${UNIQUE_CLASS} relative group overflow-hidden`}style={{ height: '750px' }}>
    // <div className={`${UNIQUE_CLASS} relative group overflow-hidden lg:h-[700px]  md:h-[500px] h-[280px]`}>
    <div className={`${UNIQUE_CLASS} relative group w-full h-full `}> 

        <div className="glide__track h-full" data-glide-el="track">
          <ul className="glide__slides ">
            {galleryImgs.map((item, index) => (
              <li key={index} className="glide__slide ">
                {/* <Link to={href} className={`block ${ratioClass}`}> */}
                {/* <Link to={href} > */}

                  {/* <NcImage src={item} className="max-h-[630px]"/> */}
                  {/* <NcImage src={item} className="  object-cover"/> */}
                  {/* <NcImage src={item} /> */}
                  {/* <div className="relative aspect-w-3 aspect-h-4  md:aspect-w-11 md:aspect-h-9  lg:aspect-w-16 lg:aspect-h-10 xl:aspect-h-8 "> */}

                  {/* <div className="relative aspect-w-3 aspect-h-4  lg:aspect-w-16 lg:aspect-h-13 xl:aspect-h-12 "> */}
                  <div className="relative w-full h-[450px] sm:h-[750px] md:h-[800px] lg:h-[800px] ">

                    <img
                      className="w-full h-full object-cover"
                      src={item}
                      alt="hero"
                    />
                  </div>

                {/* </Link> */}
              </li>
            ))}
          </ul>
        </div>

        {/* DOTS */}
        {/* <div className="absolute -bottom-0 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div> */}
        {renderDots()}

        {/* NAV */}
        {/* <div className="absolute w-full h-[10px] opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
          <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
        </div> */}
      </div>
    );
  };

  return (
    <div className={`nc-GallerySlider w-full h-full ${className}`} data-nc-id="GallerySlider">
      {renderSliderGallery()}
    </div>
  );
};

export default GallerySliderHero;