import React, { FC } from "react";


export interface PostCardMetaProps {
  className?: string;
  date: string;
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

const PostCardMeta2: FC<PostCardMetaProps> = ({
  className = "leading-none",
  date,
}) => {
  return (
    <div
      className={`nc-PostCardMeta inline-flex items-center fledx-wrap text-neutral-800 dark:text-neutral-200 text-sm  ${className}`}
      data-nc-id="PostCardMeta">
      <>
        <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
        {formatDate(date)}
        </span>
      </>
    </div>
  );
};

export default PostCardMeta2;
