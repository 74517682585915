import { CheckIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import backgroundImage from "images/hojaBlanca.png";

export interface PageSubcriptionProps {
  className?: string;
}

export interface PricingItem {
  isPopular: boolean;
  name: string;
  pricing: string;
  desc: string;
  per: string;
  features: string[];
}

const pricings: PricingItem[] = [
  {
    isPopular: false,
    name: "Silver",
    pricing: "US30.00",
    per: "/mo",
    features: ["30 day post.", "Upload to up to 10 images.", "Full exposure to potential international buyers.", "Full access to instructional content.", "Full access to webinars."],
    desc: `Description for starter plan.`,
  },
  {
    isPopular: true,
    name: "Gold",
    pricing: "US60.00",
    per: "/mo",
    features: ["90 day post.", "Upload to up to 10 images.", "15 days front page placement (40% more visibility)", "Full exposure to potential international buyers.", "Full access to instructional content.", "Full access to webinars."],
    desc: `Description for basic plan.`,
  },
  {
    isPopular: false,
    name: "Platinum",
    pricing: "US90.00",
    per: "/mo",
    features: ["90 day post.", "Upload to up to 10 images.", "Property will show on first page upon search.", "30 days front page placement (40% more visibility)", "Full exposure to potential international buyers.", "Full access to instructional content.", "Full access to webinars."],
    desc: `Description for plus plan.`,
  },
];


// className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden border-primary-500
//           ${pricing.isPopular ? "border-primary-500" : "border-neutral-100 dark:border-neutral-700" }
//         `}

const PageSubcription: FC<PageSubcriptionProps> = ({ className = "" }) => {
  const renderPricingItem = (pricing: PricingItem, index: number) => {
    return (
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden border-primary-500
        `}
      >
        {/* {pricing.isPopular && (
          <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
            POPULAR
          </span>
        )} */}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
            {pricing.name}
          </h3>
          <h2 className="text-xl font-bold leading-none flex items-center text-neutral-900 ">
            <span>{pricing.pricing}</span>
            {/* <span className="text-lg ml-1 font-normal text-neutral-500">
              {pricing.per}
            </span> */}
          </h2>
        </div>
        <nav className=" mb-8 ">
          {pricing.features.map((item, index) => (

            // <li className="flex items-center list-disc" key={index}>

            //   <span className="text-neutral-700 ">
            //     {item}
            //   </span>
            // </li>
            <div className="flex-col prose sm:prose ">
              <ul className="text-sm text-left">
                <li>{item}</li>
              </ul>
            </div>

          ))}
        </nav>
        <div className="flex flex-col mt-auto ">
          <ButtonPrimary className="text-xl "> Join us</ButtonPrimary>
          {/* {pricing.isPopular ? (
            <ButtonPrimary>Submit</ButtonPrimary>
          ) : (
            <ButtonSecondary>
              <span className="font-medium">Submit</span>
            </ButtonSecondary>
          )} */}
          {/* <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3">
            {pricing.desc}
          </p> */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
      data-nc-id="PageSubcription">
      <div className="relative flex items-center justify-center">
        <img
          className="w-[200px] h-[200px] object-cover mx-auto absolute z-0"
          src={backgroundImage}
          alt=""
        />


        <header className="text-center max-w-4xl mx-auto my-20 relative z-10">
        
          <h2 className="text-4xl font-semibold">
            What’s your plan
          </h2>
          <span className="block text-sm mt-4 sm:text-base">
            Discover Your Perfect Plan and Unlock Endless Opportunities for Your Properties
          </span>
          <span className="block mt-6 sm:text-base">
            Welcome to Paradise Living – the ultimate solution for connecting sellers and buyers like never before.
          </span>
          <span className="block sm:text-base">
            Our relentless marketing program ensures your properties receive unparalleled exposure in a vast marketplace.
          </span>
          <span className="block sm:text-base">
            The choice is yours. Choose Paradise Living today and embark on your path to success!
          </span>
        </header>
      </div>

      <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
        <div className="grid lg:grid-cols-3 gap-5 xl:gap-8">
          {pricings.map(renderPricingItem)}
        </div>
      </section>
    </div>
  );
};

export default PageSubcription;
