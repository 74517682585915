import React, { FC } from "react";
import MainNav1 from "./MainNav1";
import MainNav2 from "./MainNav2";
import MainNav3 from "./MainNav3";
// import texture from "../../images/bg_Texture.svg";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";


export interface HeaderProps {
  navType?: "MainNav1" | "MainNav2"| "MainNav3";
  className?: string;
}

const Header: FC<HeaderProps> = ({ 
  navType , 
  className  
}) => {
  const renderNav = () => {
    switch (navType) {
      case "MainNav3":
        return <MainNav3 />;
      case "MainNav2":
        return <MainNav2 />;
      default:
        return <MainNav1 />;
    }
  };

  return (
    // <div className={`nc-Header bg-white sticky top-0 w-full left-0 right-0 z-40   ${className}`} 
    // <div className={`nc-Header bg-white top-0 w-full left-0 right-0 z-40   ${className}`} 
    // style={{
    //   backgroundImage: `url(${texture})`, // Set the background image using the 'texture' variable
    //   backgroundSize: 'cover', // The image will display at its original size
    //   backgroundRepeat: 'repeat', // The image will repeat both horizontally and vertically
    // }}>
    <div className={`nc-Header bg-white top-0 w-full left-0 right-0  h-[100px] ${className}`} >

     {/* <BackgroundSection className = "bg-neutral-100  w-full "/> */}
     <div className="flex flex-col  ">
       <div className="bg-[#19CFD8] w-full h-[3px]" > </div>
       {renderNav()}
     </div>
      
    </div>

    
  );
};

export default Header;
