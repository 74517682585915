import React, { FC, ReactNode} from "react";
import { ListingDataType } from "data/types";
import PropertyCard from "components/PropertyCardH/PropertyCardBuyer";


export interface SectionGridBuyerPropertyProps {
  featuredListings?: ListingDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  propertyDetail: (info: ListingDataType) => void;
  propertyRemoved: (propertyId: String) => void;
}





const SectionGridBuyerProperty: FC<SectionGridBuyerPropertyProps> = ({
  featuredListings = [],
  gridClass = "",
  heading = "",
  subHeading = "",
  headingIsCenter,
  tabs = [],
  propertyDetail,
  propertyRemoved

}) => {

  const handleClick = (info: ListingDataType) => {
    propertyDetail(info);
  };

  const handleRemoved= (propertyId: String) => {
    propertyRemoved(propertyId);
  };
  
  const renderCard = (stay: ListingDataType, index: number) => {
    return <PropertyCard key={index} className="h-full" data={stay} onButtonClick={handleClick} onDelete={handleRemoved}/>;
  };

  return (
    <div className="nc-SectionGridFeatureProperty relative">
      
      <div
        className={`grid gap-6 md:gap-10 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 sm:mx-[80px] md:mx-[0px] ${gridClass}`}
      >
        {featuredListings.map(renderCard)}
      </div>
    
    </div>
  );
};

export default SectionGridBuyerProperty;