import LocationMarker from "components/AnyReactComponent/LocationMarker";
import GoogleMapReact from "google-map-react";
import React, { FC, useState, useEffect } from "react";
import Input from "shared/Input/Input";
import FormItem from "./FormItem";
import "react-datepicker/dist/react-datepicker.css";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { AuthorType, ListingDataType } from "data/types";
import { useLocation } from 'react-router-dom';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface PageAddListing2Props { }

const PageAddListing2: FC<PageAddListing2Props> = () => {
  const location = useLocation();
  const propertyInfo = location.state;
  const agent = propertyInfo.agent as AuthorType;
  const property = propertyInfo.property as ListingDataType;
  const navigate = useNavigate();
  const [sqFootage, setSqFootage] = useState(0);
  const [plotSize, setPlotSize] = useState('');
  const [bedroomsCount, setBedroomsCount] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);
  const [hbathrooms, setHbathrooms] = useState(0);
  const [livingAreas, setLivingAreas] = useState(0);
  const [parkingSpots, setParkingSpots] = useState(0);
  const [balcony, setBalcony] = useState(0);
  const [airUnits, setAirUnits] = useState(0);
  type CheckedItems = Record<string, boolean>;
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});
  const { t, i18n } = useTranslation();
  const [isSqFootageValid, setIsSqFootageValid] = useState(true);
  const [isPlotSizeValid, setIsPlotSizeValid] = useState(true);
  const [coordinates, setCoordinates] = useState<Coordinates>({ lat: 18.582010, lng: -68.405500 });

  type Coordinates = {
    lat: number;
    lng: number;
  };

  const handleSqFootageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSqFootage(parseInt(event.target.value));
  };

  const handlePlotSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlotSize(event.target.value);
  };

  const handleBedroomsChange = (value: number) => {
    setBedroomsCount(value)
  };

  const handleBathroomsChange = (value: number) => {
    setBathrooms(value)
  };

  const handleHbathroomsChange = (value: number) => {
    setHbathrooms(value)
  };

  const handleLivingAreasChange = (value: number) => {
    setLivingAreas(value)
  };

  const handleParkingSpotsChange = (value: number) => {
    setParkingSpots(value)
  };

  const handleBalconyChange = (value: number) => {
    setBalcony(value)
  };

  const handleAirUnitsChange = (value: number) => {
    setAirUnits(value)
  };

  const validateFields = () => {
    setIsSqFootageValid(true);
    setIsPlotSizeValid(true);

    let isValid = true;
    if (!sqFootage) {
      setIsSqFootageValid(false);
      isValid = false;
    }

    if (!plotSize) {
      setIsPlotSizeValid(false);
      isValid = false;
    }

    return isValid;
  };

  const handleCheckItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const updatedItems = { ...checkedItems };
    if (checked) {
      updatedItems[name] = true;
    } else {
      delete updatedItems[name];
    }
    setCheckedItems(updatedItems);
  };


  useEffect(() => {

    checkValues();
  }, []);

  const checkValues = () => {

    setSqFootage(property.sqrft || 0);
    setBedroomsCount(property.bedrooms || 0);
    setBathrooms(property.bathrooms || 0);
    setHbathrooms(property.bathrooms || 0);


  }

  const toNextStep = () => {
    const isValid = validateFields();
    if (!isValid) {
      return;
    }


    if (property.overview) {
      property.overview.map.lat = coordinates.lat;
      property.overview.map.lng = coordinates.lng;
    }
    property.bedrooms = bedroomsCount;
    property.bathrooms = bathrooms;
    var bedrooms: string[] = [];
    var parking: string[] = [];
    var other: string[] = [];
    bedrooms = bedrooms.concat("Bedrooms: " + bedroomsCount, "Bathrooms: " + bathrooms, "1/2 bathrooms: " + hbathrooms);
    property.sqrft = sqFootage;
    parking = parking.concat("Total spaces: " + parkingSpots);
    const checkedItemsArray: string[] = Object.keys(checkedItems);
    other = other.concat("Living Areas: " + livingAreas, "Balcony: " + balcony, "Individual air units: " + airUnits, checkedItemsArray);


    if (property.factsFeatures) {
      property.factsFeatures.bedrooms = bedrooms;
      property.factsFeatures.parking = parking;
      property.factsFeatures.otherInterior = other;
    }


    const propertyInfo = {
      property,
      agent,
    };

    navigate('/add-listing-4', { state: propertyInfo });
    // console.log(propertyInfo);
  }

  const handleMapClick = ({ lat, lng }: Coordinates) => {
    setCoordinates({ lat, lng });
  };

  const toPrevtStep = () => {
    const propertyInfo = {
      property,
      agent,
    };
    navigate('/add-listing-1', { state: propertyInfo });
  }

  return (
    <div
      className={`nc-PageAddListing2 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing2"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">2</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 5
          </span>
        </div>
        <h2 className="text-2xl font-semibold">Location Map</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="listingSection__wrap ">

          <div className="space-y-8">


            <div className="mt-4">
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                <div className="rounded-xl overflow-hidden">
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY",
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultZoom={15}
                    defaultCenter={{
                      lat: 18.582010,
                      lng: -68.405500,
                    }}
                    onClick={handleMapClick}
                  >
                    <LocationMarker lat={coordinates.lat} lng={coordinates.lng} />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
            <h2 className="text-2xl font-semibold">{t('pageAddListing2-facts')}</h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
              <FormItem label="Property square footage" >
                <Input placeholder="square footage"
                  onChange={handleSqFootageChange}
                  className={!isSqFootageValid ? 'border-red-500' : ''}
                />
              </FormItem>
              <FormItem label="Plot size*">
                <Input placeholder="size"
                  onChange={handlePlotSizeChange}
                  className={!isPlotSizeValid ? 'border-red-500' : ''}
                />
              </FormItem>
            </div>
            <div className="space-y-4">
              <NcInputNumber
                label="Bedrooms"
                desc=""
                defaultValue={bedroomsCount}
                onChange={handleBedroomsChange}
              />
              <NcInputNumber
                label="Bathrooms"
                desc=""
                defaultValue={0}
                onChange={handleBathroomsChange}
              />

              <NcInputNumber
                label="1/2 bathrooms"
                desc=""
                defaultValue={0}
                onChange={handleHbathroomsChange}
              />

              <NcInputNumber
                label="Living Areas"
                desc=""
                defaultValue={0}
                onChange={handleLivingAreasChange}
              />

              <NcInputNumber
                label="Parking spots"
                desc=""
                defaultValue={0}
                onChange={handleParkingSpotsChange}
              />

              <NcInputNumber
                label="Balcony"
                desc=""
                defaultValue={0}
                onChange={handleBalconyChange}
              />

              <NcInputNumber
                label="Individual air units"
                desc=""
                defaultValue={0}
                onChange={handleAirUnitsChange}
              />

            </div>
            <div className="mt-6 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-5">
              <div className={`flex text-sm sm:text-base`}>
                <input
                  id="Gym"
                  name="Gym"
                  type="checkbox"
                  className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  onChange={handleCheckItems}
                />
                <label
                  className="ml-3.5 flex flex-col flex-1 justify-center"
                >
                  <span className=" text-neutral-900 dark:text-neutral-100">
                    Gym
                  </span>
                </label>
              </div>
              <div className={`flex text-sm sm:text-base`}>
                <input
                  id="Jacuzzi"
                  name="Jacuzzi"
                  type="checkbox"
                  className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  onChange={handleCheckItems}
                />
                <label
                  className="ml-3.5 flex flex-col flex-1 justify-center"
                >
                  <span className=" text-neutral-900 dark:text-neutral-100">
                    Jacuzzi
                  </span>
                </label>
              </div>
              <div className={`flex text-sm sm:text-base`}>
                <input
                  id="Central air"
                  name="Central air"
                  type="checkbox"
                  className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  onChange={handleCheckItems}
                />
                <label
                  className="ml-3.5 flex flex-col flex-1 justify-center"
                >
                  <span className=" text-neutral-900 dark:text-neutral-100">
                    {t('pageAddListing2-central-air')}
                  </span>
                </label>
              </div>
              <div className={`flex text-sm sm:text-base`}>
                <input
                  id="Pool"
                  name="Pool"
                  type="checkbox"
                  className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  onChange={handleCheckItems}
                />
                <label
                  className="ml-3.5 flex flex-col flex-1 justify-center"
                >
                  <span className=" text-neutral-900 dark:text-neutral-100">
                    Pool
                  </span>
                </label>
              </div>
              <div className={`flex text-sm sm:text-base`}>
                <input
                  id="Maid room"
                  name="Maid room"
                  type="checkbox"
                  className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  onChange={handleCheckItems}
                />
                <label
                  className="ml-3.5 flex flex-col flex-1 justify-center"
                >
                  <span className=" text-neutral-900 dark:text-neutral-100">
                    {t('pageAddListing2-maid-room')}
                  </span>
                </label>
              </div>
              <div className={`flex text-sm sm:text-base`}>
                <input
                  id="Elevator"
                  name="Elevator"
                  type="checkbox"
                  className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  onChange={handleCheckItems}
                />
                <label
                  className="ml-3.5 flex flex-col flex-1 justify-center"
                >
                  <span className=" text-neutral-900 dark:text-neutral-100">
                    {t('pageAddListing2-elevator')}
                  </span>
                </label>
              </div>
              <div className={`flex text-sm sm:text-base`}>
                <input
                  id="Patio square footage"
                  name="Patio square footage"
                  type="checkbox"
                  className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  onChange={handleCheckItems}
                />
                <label
                  className="ml-3.5 flex flex-col flex-1 justify-center"
                >
                  <span className=" text-neutral-900 dark:text-neutral-100">
                    {t('pageAddListing2-patio-square-footage')}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-5">
          <ButtonSecondary onClick={toPrevtStep}>{t('pageAddListing10-back')}</ButtonSecondary>
          <ButtonPrimary onClick={toNextStep}> {"Continue"} </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default PageAddListing2;
