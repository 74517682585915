import React, { Fragment, useState, FC } from "react";
import Shopping from "images/Shopping.png";
import Supermarket from "images/Supermarket.png";
import Biking from "images/Biking.png";
import Park from "images/Park.png";
import Beach from "images/Beach.png";
import Golf from "images/Golf.png";
import Pool from "images/Pools.png";
import Restaurant from "images/Restaurants.png";
import Movies from "images/Movie.png";
import Mall from "images/Mall.png";
import { useTranslation } from 'react-i18next';

export interface StayDatesRangeInputProps {
    className?: string;
    defaultOpen?: boolean;
    neighborhoods?: string[];
}

const Neighborhood: FC<StayDatesRangeInputProps> = ({
    className = "flex-1",
    defaultOpen = false,
    neighborhoods
}) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const { t, i18n } = useTranslation();
    const neighborhoodImages = {
        Park: Park,
        Shopping: Shopping,
        Supermarket: Supermarket,
        Biking: Biking,
        Beach: Beach,
        Golf: Golf,
        Pool: Pool,
        Restaurants: Restaurant,
        Movies: Movies,
        Mall: Mall,
    } as Record<string, string>;

    function showFacts() {
        setIsOpen(!isOpen);

    }

    const renderDetail = () => {
        if (!isOpen) return null;
        return (
            <div className="grid grid-cols-4 gap-2 ">
                {neighborhoods?.map((item, index) => (
                    <div key={index} className="m-[15px] flex items-center flex-col">
                        <div>
                            <img
                                src={neighborhoodImages[item]}
                                alt={item}
                                className="w-[60px] h-[60px] object-cover rounded-md"
                            />
                        </div>
                        <div >

                            <p className="p-[10px] text-center text-xs">
                                {item}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div
            className={`justify-center  relative overflow-hidden  transition-shadow  ${className}`}>
            <div className="flex flex-row w-full " onClick={showFacts} >
                <div className="my-[9px] text-left w-5/6" >
                    <span className="pl-[15px] block text-md font-semibold  flex items-center justify-left h-full">
                        {t('neighborhood')}
                    </span>
                </div>
                <div className={`flex  w-1/6 justify-end text-white ${isOpen ? "" : ""}`}>
                    <span className="flex rounded-tr-lg items-center justify-center w-[30px] bg-[#00D3DB] cursor-pointer rounded-bl-md">
                        {isOpen ? (
                            <i className=" text-xl font-bold las la-minus"></i>
                        ) : (
                            <i className="text-xl font-bold las la-plus"></i>
                        )}
                    </span>
                </div>

            </div>
            {renderDetail()}
        </div>
    );
};

export default Neighborhood;
