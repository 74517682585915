import React, { FC, ReactNode} from "react";
// import { DEMO_STAY_LISTINGS } from "data/listings";
import { ListingDataType } from "data/types";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import PropertyCard from "components/PropertyCardH/PropertyCard";

// OTHER DEMO WILL PASS PROPS
// const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 6);
//
export interface SectionGridSearchPropertyProps {
  featuredListings?: ListingDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  propertyDetail: (info: ListingDataType) => void;
}





const SectionGridSearchProperty: FC<SectionGridSearchPropertyProps> = ({
  featuredListings = [],
  gridClass = "",
  heading = "",
  subHeading = "",
  headingIsCenter,
  tabs = [],
  propertyDetail
  

}) => {

  const handleClick = (info: ListingDataType) => {
    // Call the callback function and pass the information
    propertyDetail(info);
  };
  
  const renderCard = (stay: ListingDataType, index: number) => {
    return <PropertyCard key={index} className="h-full" data={stay} onButtonClick={handleClick}/>;
  };

  return (
    <div className="nc-SectionGridFeatureProperty relative">
      
      <div
        className={`grid gap-6 md:gap-10 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 sm:mx-[80px] md:mx-[0px] ${gridClass}`}
      >
        {featuredListings.map(renderCard)}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Show me more</ButtonPrimary>
      </div> */}
    </div>
  );
};

export default SectionGridSearchProperty;