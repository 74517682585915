import React, { ButtonHTMLAttributes } from "react";
// import twFocusClass from "utils/twFocusClass";

export interface ButtonSquareProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: string;
}

const ButtonSquareFull: React.FC<ButtonSquareProps> = ({
  className = " ",
  size = "",
  ...args
}) => {
  return (
    <button
      className={
        ` flex items-center justify-center rounded-md  ${className} ${size} ` 
       
      }
      {...args}
    />
  );
};

export default ButtonSquareFull;