import React, { FC, useState } from "react";
import ButtonSquareFull from "shared/Button/ButtonSqareFull";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSearch } from '../../SearchContext';
import { ClockLoader } from 'react-spinners';

interface QueryParams {
  minYearBuilt?: number;
  maxYearBuilt?: number;
  type?: string;
  minBedrooms?: string;
  maxBedrooms?: string;
  minBathrooms?: string;
  maxBathrooms?: string;
  minPrice?: string;
  maxPrice?: string;
}

const RefinedSearchForm = () => {
  const { listings, setListings } = useSearch();
  const [yearNewChecked, setYearNewChecked] = useState(true);
  const [yearOldChecked, setYearOldChecked] = useState(false);
  const [newListingChecked, setNewListingChecked] = useState(false);
  const [priceCChecked, setPriceCChecked] = useState(false);
  const [openHChecked, setOpenHChecked] = useState(false);
  const [allChecked, setAllChecked] = useState(true);
  const [homesChecked, setHomesChecked] = useState(true);
  const [apartmentsChecked, setApartmentsChecked] = useState(false);
  const [villaChecked, setVillaChecked] = useState(false);
  const [commercialChecked, setCommercialChecked] = useState(false);
  const [rentalChecked, setRentalChecked] = useState(false);
  const [beachFrontChecked, setBeachFrontChecked] = useState(false);
  const [poolChecked, setPoolChecked] = useState(false);
  const [longTermChecked, setLongTermChecked] = useState(false);
  const [airbnbChecked, setAirbnbChecked] = useState(false);
  const navigate = useNavigate();
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [beds, setBeds] = useState('');
  const [baths, setBaths] = useState('');
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { id } = event.target;
    if (id === 'yearNew') {
      setYearNewChecked(true);
      setYearOldChecked(false);
    } else if (id === 'yearOld') {
      setYearNewChecked(false);
      setYearOldChecked(true);
    }
  };

  const handleShowOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    if (id === 'newListing') {
      setNewListingChecked(checked);
      setPriceCChecked(false);
      setAllChecked(false);
      setOpenHChecked(false);
    } else if (id === 'priceC') {
      setPriceCChecked(checked);
      setAllChecked(false);
      setOpenHChecked(false);
      setNewListingChecked(false);
    } else if (id === 'openH') {
      setOpenHChecked(checked);
      setAllChecked(false);
      setPriceCChecked(false);
      setNewListingChecked(false);
    } else if (id === 'all') {
      setAllChecked(checked);
      setOpenHChecked(false);
      setPriceCChecked(false);
      setNewListingChecked(false);
    }
  };

  const handlePropertyTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    if (id === 'Homes') {
      setHomesChecked(checked);
    } else if (id === 'Apartments') {
      setApartmentsChecked(checked);
    } else if (id === 'Villa') {
      setVillaChecked(checked);
    } else if (id === 'Commercial') {
      setCommercialChecked(checked);
    } else if (id === 'Rental') {
      setRentalChecked(checked);
    }
  };


  const handleFeaturesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    if (id === 'Beach') {
      setBeachFrontChecked(checked);
    } else if (id === 'Pool') {
      setPoolChecked(checked);
    }
  };

  const handleInvestmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    if (id === 'Rental') {
      setLongTermChecked(checked);
    } else if (id === 'Airbnb') {
      setAirbnbChecked(checked);
    }
  };

  const handleMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMinPrice(event.target.value);
  };

  const handleMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaxPrice(event.target.value);
  };

  const handleBedsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBeds(event.target.value);
  };

  const handleBathsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBaths(event.target.value);
  };

  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  const handleUpdateButtonClick = () => {
    setLoading(true);
    let queryParams: QueryParams = {};

    if (yearNewChecked) {
      queryParams.minYearBuilt = 2000;
      queryParams.maxYearBuilt = 2030;
    } else if (yearOldChecked) {
      queryParams.minYearBuilt = 1990;
      queryParams.maxYearBuilt = 1999;
    }

    if (homesChecked) {
      queryParams.type = 'Homes';
    } else if (apartmentsChecked) {
      queryParams.type = 'Apartments';
    } else if (villaChecked) {
      queryParams.type = 'Villa';
    } else if (commercialChecked) {
      queryParams.type = 'Commercial';
    } else if (rentalChecked) {
      queryParams.type = 'Rental';
    }

    if (beds !== '') {
      queryParams.minBedrooms = beds;
      queryParams.maxBedrooms = beds;
    }
    if (baths !== '') {
      queryParams.minBathrooms = baths;
      queryParams.maxBathrooms = baths;
    }

    if (minPrice !== '') {
      queryParams.minPrice = minPrice;
    }
    if (maxPrice !== '') {
      queryParams.maxPrice = maxPrice;
    }
    let apiUrl = `${process.env.REACT_APP_API}property/refined/search`;

    axios.get(apiUrl, { params: queryParams })
      .then((response) => {
        setLoading(false);
        // console.log("vamos a refined form");
        // console.log(response.data.content);
        // navigate('/listing-stay-map', { state: response.data.content });
        setListings(response.data.content);
        navigate('/listing-stay-map');
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  const renderForm = () => {
    return (

      <div className="flex flex-row bg-[#EFF7FE] w-full  h-[480px] ">
        <div className="flex-col w-1/3 " >
          <div className="flex-row " >
            <div className="pl-[100px] " >
              <span className=" pt-[30px] block text-4xl font-semibold w-full flex items-center justify-left h-full">Refined Search</span>
              <div className="mt-[30px] mb-[10px]">
                <label className="text-lg font-semibold " htmlFor="">
                  Years built
                </label>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="yearNew"
                    name="yearNew"
                    type="checkbox"
                    checked={yearNewChecked}
                    onChange={handleYearChange}
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className="text-neutral-900 dark:text-neutral-100">
                      2000s - New
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="yearOld"
                    name="yearOld"
                    type="checkbox"
                    checked={yearOldChecked}
                    onChange={handleYearChange}
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className="text-neutral-900 dark:text-neutral-100">
                      1990s - 1999s
                    </span>
                  </label>
                </div>
              </div>

              <div className="mt-[30px] mb-[10px]">
                <label className="text-lg font-semibold " htmlFor="">
                  Features
                </label>
              </div>
              <div className=" grid grid-cols-2 gap-5">

                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Beach"
                    name="Beach"
                    type="checkbox"
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    onChange={handleFeaturesChange}
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      Beach front
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Pool"
                    name="Pool"
                    type="checkbox"
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                    onChange={handleFeaturesChange}
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className=" text-neutral-900 dark:text-neutral-100">
                      Pool
                    </span>
                  </label>
                </div>

              </div>


              <div className="pt-[140px]" >
                {loading ? (
                  <div className="px-[18px] pt-[5px]">
                    <ClockLoader color="#36d7b7" size={20} />
                  </div>
                ) : (

                  <ButtonSquareFull className="bg-[#00D3DB] text-md font-medium text-[white] px-[18px]"
                    size="h-9"
                    onClick={handleUpdateButtonClick} >
                    Update
                  </ButtonSquareFull>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col w-2/3  " >
          <div className="flex flex-row  " >
            <div className="w-[400px] mt-[103px]">
              <div className="mt-[30px] mb-[10px]">
                <label className="text-sm  " htmlFor="">
                  Show only
                </label>
              </div>

              <div className="grid grid-cols-2 gap-5">
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="newListing"
                    name="newListing"
                    type="checkbox"
                    checked={newListingChecked}
                    onChange={handleShowOnlyChange}
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className="text-neutral-900 dark:text-neutral-100">
                      New listing
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="priceC"
                    name="priceC"
                    type="checkbox"
                    checked={priceCChecked}
                    onChange={handleShowOnlyChange}
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className="text-neutral-900 dark:text-neutral-100">
                      Price charge
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="openH"
                    name="openH"
                    type="checkbox"
                    checked={openHChecked}
                    onChange={handleShowOnlyChange}
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className="text-neutral-900 dark:text-neutral-100">
                      Open houses
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="all"
                    name="all"
                    type="checkbox"
                    checked={allChecked}
                    onChange={handleShowOnlyChange}
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className="text-neutral-900 dark:text-neutral-100">
                      All listings
                    </span>
                  </label>
                </div>
              </div>
            </div>


            <div className="ml-[20px] w-[520px] mt-[80px]">
              <div className="mt-[30px] mb-[25px]">
                <label className="text-lg font-semibold " htmlFor="">
                  Property type
                </label>
              </div>

              <div className="grid grid-cols-3 gap-5">
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Homes"
                    name="Homes"
                    type="checkbox"
                    checked={homesChecked}
                    onChange={handlePropertyTypeChange}
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className="text-neutral-900 dark:text-neutral-100">
                      Homes
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Apartments"
                    name="Apartments"
                    type="checkbox"
                    checked={apartmentsChecked}
                    onChange={handlePropertyTypeChange}
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className="text-neutral-900 dark:text-neutral-100">
                      Apartments
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Villa"
                    name="Villa"
                    type="checkbox"
                    checked={villaChecked}
                    onChange={handlePropertyTypeChange}
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className="text-neutral-900 dark:text-neutral-100">
                      Villa
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Rental"
                    name="Rental"
                    type="checkbox"
                    checked={rentalChecked}
                    onChange={handlePropertyTypeChange}
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label
                    className="ml-3.5 flex flex-col flex-1 justify-center"
                  >
                    <span className="text-neutral-900 dark:text-neutral-100">
                      Residential rents
                    </span>
                  </label>
                </div>
                <div className={`flex text-sm sm:text-base`}>
                  <input
                    id="Commercial"
                    name="Commercial"
                    type="checkbox"
                    checked={commercialChecked}
                    onChange={handlePropertyTypeChange}
                    className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700 dark:checked:bg-primary-500 focus:ring-primary-500"
                  />
                  <label className="ml-3.5 flex flex-col items-center">
                    <span className="text-neutral-900 dark:text-neutral-100">
                      Commercial
                    </span>
                  </label>
                </div>

              </div>

            </div>
          </div>

          <div className="mt-[15px] mb-[5px]">
            <label className="text-lg font-semibold" htmlFor="">
              Additional
            </label>
          </div>
          <div className="flex flex-row">
            <div className="pr-[15px]">
              <input
                className="block w-[135px] bg-white border-none rounded-md pl-[15px] py-[10px] text-xs focus:outline-none focus:placeholder-neutral-300 placeholder-neutral-800 truncate"
                placeholder="Minimum price"
                value={minPrice}
                onChange={handleMinPriceChange}
              />
            </div>
            <div className="pr-[15px]">
              <input
                className="block w-[135px] bg-white border-none rounded-md pl-[15px] py-[10px] text-xs focus:outline-none focus:placeholder-neutral-300 placeholder-neutral-800 truncate"
                placeholder="Maximum price"
                value={maxPrice}
                onChange={handleMaxPriceChange}
              />
            </div>
            <div className="pr-[15px]">
              <input
                className="block w-[75px] bg-white border-none rounded-md pl-[15px] py-[10px] text-xs focus:outline-none focus:placeholder-neutral-300 placeholder-neutral-800 truncate"
                placeholder="beds"
                value={beds}
                onChange={handleBedsChange}
              />
            </div>
            <div className="pr-[15px]">
              <input
                className="block w-[75px] bg-white border-none rounded-md pl-[15px] py-[10px] text-xs focus:outline-none focus:placeholder-neutral-300 placeholder-neutral-800 truncate"
                placeholder="baths"
                value={baths}
                onChange={handleBathsChange}
              />
            </div>
            <div className="pr-[15px]">
              <input
                className="block w-[145px] bg-white border-none rounded-md pl-[15px] py-[10px] text-xs focus:outline-none focus:placeholder-neutral-300 placeholder-neutral-800 truncate"
                placeholder="keyword"
                value={keyword}
                onChange={handleKeywordChange}
              />
            </div>
          </div>


          <div className="mt-[25px] mb-[10px]">
            <label className="text-lg font-semibold " htmlFor="">
              Investment property
            </label>
          </div>
          <div className="w-[450px] grid grid-cols-2 gap-5">

            <div className={`flex text-sm sm:text-base`}>
              <input
                id="Rental"
                name="Rental"
                type="checkbox"
                className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                onChange={handleInvestmentChange}
              />
              <label
                className="ml-3.5 flex flex-col flex-1 justify-center"
              >
                <span className=" text-neutral-900 dark:text-neutral-100">
                  Long term rental
                </span>
              </label>
            </div>
            <div className={`flex text-sm sm:text-base`}>
              <input
                id="Airbnb"
                name="Airbnb"
                type="checkbox"
                className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                onChange={handleInvestmentChange}
              />
              <label
                className="ml-3.5 flex flex-col flex-1 justify-center"
              >
                <span className=" text-neutral-900 dark:text-neutral-100">
                  Airbnb
                </span>
              </label>
            </div>

          </div>
        </div>


      </div>
    );
  };

  return (
    <div className="">
      {renderForm()}
    </div>
  );
};

export default RefinedSearchForm;
