import React, { FC, Fragment, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import { ListingDataType } from "data/types";
import { useAuth0 } from "@auth0/auth0-react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from 'react-i18next';

export interface PropertyCardBuyerProps {
  className?: string;
  data: ListingDataType;
  onButtonClick: (info: any) => void;
  onDelete: (info: any) => void;
}



const PropertyCardBuyer: FC<PropertyCardBuyerProps> = ({
  className = "",
  data,
  onButtonClick,
  onDelete
}) => {
  const {
    galleryImgs = [],
    title,
    author,
    address,
    saleOff,
    type,
    isLike,
    price,
    id,
  } = data;
  const { user, isAuthenticated } = useAuth0();
  let [isSaveModal, setIsSaveModal] = useState(false);
  let [isRemoveModal, setIsRemoveModal] = useState(false);
  const { t, i18n } = useTranslation();
  const handleClick = () => {
    onButtonClick(data);
  };

  function removeProperty(id: string | number) {
    onDelete(id);
    setIsRemoveModal(true);

  };

  function closeModalSave() {
    setIsSaveModal(false);
  }
  function closeModalRemove() {
    setIsRemoveModal(false);
  }

  const renderModalSave = () => {
    return (
      <Transition appear show={isSaveModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalSave}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 " />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-[100px] w-[400px]">
                {isAuthenticated ? (
                  <div className="bg-white border-t-4 border-[#19CFD8] rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                    <div className="flex">
                      <div className="py-1"><svg className="fill-[#19CFD8] h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                      <div>
                        <p className="font-bold text-left">{t('propertyCardBuyer-saved')}</p>
                        <p className="text-sm">{t('propertyCardBuyer-saved-message')} </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="bg-white border-t-4 border-orange-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                    <div className="flex">
                      <div className="py-1"><svg className="fill-orange-500 h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                      <div>
                        <p className="font-bold text-left">{t('propertyCardBuyer-login')}</p>
                        <p className="text-sm">{t('propertyCardBuyer-login-message')}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const HandleLikeClick = (info: boolean) => {
    if (id !== undefined && id !== null) {
      removeProperty(id);
    }
  };

  const renderModalRemove = () => {
    return (
      <Transition appear show={isRemoveModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalRemove}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 " />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-[100px] w-[400px]">
                <div className="bg-white border-t-4 border-[#19CFD8] rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                  <div className="flex">
                    <div className="py-1"><svg className="fill-[#19CFD8] h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                    <div>
                      <p className="font-bold text-left">{t('propertyCardBuyer-removed')}</p>
                      <p className="text-sm">{t('propertyCardBuyer-removed-message')}</p>
                    </div>
                  </div>
                </div>

              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0  w-full h-[260px] ">
        <GallerySlider
          ratioClass=""
          galleryImgs={galleryImgs}
          className="w-full h-full overflow-hidden will-change-transform"
          uniqueID={`PropertyCardH_${id}`}
        />

        {saleOff && (
          <SaleOffBadge className="absolute right-0 top-0 !bg-orange-500" />
        )}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2">
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bed text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.bedrooms}  {t('renderTienIch-beds')}
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bath text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.bathrooms}  {t('renderTienIch-baths')}
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.sqrft}  {t('renderTienIch-sq')}
          </span>
        </div>
      </div>
    );
  };



  const renderContent = () => {
    return (
      <div className="flex-grow  flex flex-col items-start">
        <div className="space-y-4 w-full flex-grow">
          <div className="flex w-full justify-between px-5 pt-6 items-end">
            <span className="flex items-center justify-center leading-none text-xl font-medium text-[#16385D]">
              {`${price}`}
            </span>
          </div>
          <div className="ml-[20px]">
            {renderTienIch()}
            <div className="flex flex-col  pt-[5px] h-[80px]">
              <h2 className="flex justify-left text-sm font-medium capitalize">
                <span className="line-clamp-2">{title}</span>
              </h2>
              <div className="flex justify-left text-sm font-light pt-[5px]">
                {address}
              </div>
            </div>
          </div>


          <div className="w-full h-[40px] flex justify-between">
            <div className="flex-auto bg-[#19CFD8] flex items-center justify-center">
              <button className="text-xs font-medium capitalize bg-[#00D3DB] text-white px-3 py-1 rounded-lg" onClick={() => {
                if (id !== undefined && id !== null) {
                  removeProperty(id);
                }
              }}>
                Remove
              </button>

            </div>
            <div className="flex-auto bg-[#16385D] flex items-center justify-center">
              <button className="text-xs font-medium capitalize  text-white px-3 py-1 rounded-lg cursor-pointer" onClick={handleClick}>
                View Details
              </button>
            </div>
            {renderModalSave()}
            {renderModalRemove()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group h-[520px]  relative bg-white border border-neutral-100  rounded-md overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      <div className="h-full w-full flex flex-col  ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      <BtnLikeIcon
        colorClass={` bg-clear   text-white `}
        isLiked={isLike}
        className="absolute left-5 top-5 sm:left-3 sm:top-3 "
        onLikeClick={HandleLikeClick}
      />
    </div>
  );
};

export default PropertyCardBuyer;
