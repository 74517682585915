import React, { FC, Fragment, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { ListingDataType } from "data/types";
import GallerySlider from "components/GallerySlider/GallerySlider";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Dialog, Transition } from "@headlessui/react";
import Avatar from "shared/Avatar/Avatar";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import ButtonSquareFull from "shared/Button/ButtonSqareFull";
import Overview from "containers/ListingDetailPage/listing-stay-detail/Overview";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import FactsFeatures from "containers/ListingDetailPage/listing-stay-detail/FactsFeatures";
import { useTranslation } from 'react-i18next';



const MobileDetailPage: React.FC = () => {
  let [isOpenModalTour, setIsOpenModalTour] = useState(false);
  let [isOpenModalContact, setIsOpenModalContact] = useState(false);
  let [isTypePerson, setIsTypePerson] = useState(true);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const location = useLocation();
  const listing = location.state as ListingDataType;
  const { t, i18n } = useTranslation();

  function openModalTour() {
    setIsOpenModalTour(true);
  }

  function closeModalTour() {
    setIsOpenModalTour(false);
  }

  function openModalContact() {
    setIsOpenModalContact(true);
  }

  function closeModalContact() {
    setIsOpenModalContact(false);
  }
  function changeTypePerson() {
    setIsTypePerson(true);
  }

  function changeTypeVideo() {
    setIsTypePerson(false);
  }

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0  w-full  ">
        <GallerySlider
          ratioClass=""
          galleryImgs={listing.galleryImgs || []}
          className="w-full h-full overflow-hidden will-change-transform"
          uniqueID={`PropertyCardH_${listing.id}`}
        />


      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2 py-[10px]">
        <div className="flex items-center space-x-2">
          <span className="sm:inline-block">
            <i className="las la-bed text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {listing?.bedrooms} {t('renderTienIch-beds')}
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className=" sm:inline-block">
            <i className="las la-bath text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {listing?.bathrooms} {t('renderTienIch-baths')}
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className=" sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {listing?.sqrft} {t('renderTienIch-sq')}
          </span>
        </div>
      </div>
    );
  };

  const renderMainFeatures = (features: string[] | undefined) => {
    if (!features) {
      return null;
    }
    return (
      <div className="flex flex-col">
        {features.map((data, index) => (
          <div key={index} className="flex flex-row">
            {index % 2 === 0 && (
              <>
                <div className="flex-col prose sm:prose w-1/2">
                  <ul className="text-xs text-left">
                    <li>{data}</li>
                  </ul>
                </div>
                {features[index + 1] && (
                  <div className="flex-col prose sm:prose w-1/2">
                    <ul className="text-xs text-left">
                      <li>{features[index + 1]}</li>
                    </ul>
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="py-[20px] pl-[15px] pr-[15px]">

        <div className="flex justify-between pb-[10px] ">
          <span className="text-2xl ">
            {listing?.price}
          </span>
        </div>
        <div className="flex flex-col ">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            {renderTienIch()}
          </div>
          <div className="flex flex-col  pt-[5px] pb-[20px]">
            <h2 className="flex justify-left text-sm font-medium capitalize">
              <span className="line-clamp-2">{listing?.title}</span>
            </h2>
            <div className="flex justify-left text-sm font-light pt-[5px]">
              {listing?.address}
            </div>
          </div>
          <div className="flex items-center space-x-4 pt-[20px] pl-[35px]">
            <Avatar
              hasChecked
              hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
              sizeClass="h-20 w-20"
              radius="rounded-full"
              imgUrl={listing?.author?.avatar}
            />
            <div >

              <Link className=" text-md font-medium flex justify-left" to="/agent" state={listing.author}>
                {listing?.author?.firstName} {listing?.author?.lastName}
              </Link>

              <div className=" flex items-center text-sm text-neutral-500 dark:text-neutral-400">
                <FiveStartIconForRate iconClass="w-4 h-4 " className="space-x-0.5" defaultPoint={listing?.author?.reviewStart} />
                <span className="mx-2">·</span>
              </div>
            </div>

          </div>
          <div className="flex flex-row justify-left  pt-[30px]">
            <div className="flex-col pr-[20px]">
              <ButtonSquareFull onClick={openModalTour} className="bg-[#16385D] text-sm font-medium text-[white] px-[22px]"
                size="h-11" >
                {t('renderSidebar-tour')}
              </ButtonSquareFull>
            </div>
            <div className="flex-col">
              <ButtonSquareFull onClick={openModalContact} className="bg-[white] border border-[#16385D] text-sm font-medium text-[#16385D] px-[22px]"
                size="h-11" >
                {t('renderSidebar-contact')}
              </ButtonSquareFull>
            </div>
            {renderModalTour()}
            {renderModalContact()}
          </div>
          <div className="pt-[30px] ">
            {renderMainFeatures(listing?.mainFeatures)}
          </div>



          <div className="grid grid-cols-1 gap-3 mt-[35px] pb-[20px] ">
            <div className="   rounded-lg bg-[#E3F3FF] ">
              <Overview className="flex flex-col " overview={listing?.overview} author={listing?.author} />
            </div>
            <div className="  rounded-lg bg-[#E3F3FF]">
              <FactsFeatures className="flex flex-col" facts={listing?.factsFeatures} />
            </div>
            <div className=" rounded-lg bg-[#E3F3FF]">

            </div>

          </div>
        </div>

      </div>
    );
  };


  const renderModalTour = () => {
    return (
      <Transition appear show={isOpenModalTour} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalTour}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-[600px] w-full max-w-sm">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-md font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      {t('renderModalTour')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalTour} />
                    </span>
                  </div>
                  {/* <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200"> */}
                  <div className=" px-8  text-neutral-700  ">
                    <label className="block py-[15px]">
                      <Label>{t('sendTourEmail-type')}</Label>
                      <div className="flex justify-center w-full">
                        <div className="inline-flex  rounded-lg py-[3px]" role="group">
                          <button onClick={changeTypePerson} type="button" className={`w-[160px] py-3 text-xs font-medium  rounded-l-xl  ${isTypePerson ? "text-white bg-[#1A4676] " : "text-[#1A4676] bg-white border border-[#1A4676]"}`}>
                            {t('sendTourEmail-person')}
                          </button>
                          <button onClick={changeTypeVideo} type="button" className={`w-[160px] py-3 text-xs font-medium  rounded-r-xl ${isTypePerson ? "text-[#1A4676] bg-white border border-[#1A4676] " : "text-white bg-[#1A4676]"}`}>
                            Video chat
                          </button>
                        </div>
                      </div>
                    </label>
                    <div className="w-full">
                      <label className="block">
                        <Label>{t('sendTourEmail-date')}</Label>
                        <DatePicker className="rounded-md w-[320px]"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          minDate={new Date()}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={30}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                          placeholderText="Select date and time"
                        />
                      </label>
                    </div>
                    <div>
                      <form className="grid grid-cols-1 gap-6" >
                        <label className="block pt-[20px]">
                          <Label>{t('sendTourEmail-name')}</Label>

                          <Input
                            placeholder="Type your full name"
                            type="text"
                            className="mt-1 rounded-md"
                          />
                        </label>
                        <label className="block">
                          <Label>{t('sendTourEmail-email')}s</Label>

                          <Input
                            type="email"
                            placeholder="example@example.com"
                            className="mt-1 rounded-md"
                          />
                        </label>

                        <div>
                          <button className="p-3 text-white font-medium  rounded-lg w-full bg-[#00D3DB]" type="submit">{t('sendEmail-request')}</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderModalContact = () => {
    return (
      <Transition appear show={isOpenModalContact} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalContact}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-[600px] w-full max-w-sm">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-md font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      {t('sendEmail-contact-buyer')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalContact} />
                    </span>
                  </div>
                  <div className=" px-8  text-neutral-700  ">
                    <div>
                      <form className="grid grid-cols-1 gap-6" >
                        <label className="block pt-[20px]">
                          <Label>{t('sendTourEmail-name')}</Label>

                          <Input
                            placeholder="Type your full name"
                            type="text"
                            className="mt-1 rounded-md"
                          />
                        </label>
                        <label className="block">
                          <Label>{t('sendTourEmail-name')}</Label>

                          <Input
                            type="email"
                            placeholder="example@example.com"
                            className="mt-1 rounded-md"
                          />
                        </label>
                        <label className="block">
                          <Label>{t('sendTourEmail-message')}</Label>

                          <Textarea className="mt-1" rows={6} />
                        </label>
                        <div>
                          <button className="p-3 text-white font-medium  rounded-lg w-full bg-[#00D3DB]" type="submit">Send</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className={`nc-MobileDetail `} >
      <div className="h-full w-full flex flex-col  ">
        {renderSliderGallery()}
        {renderSidebar()}
      </div>
    </div>
  );
};

export default MobileDetailPage;