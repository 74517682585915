import React, { useState, FC } from "react";
import { OverviewDataType, AuthorType } from "data/types";
import { useTranslation } from 'react-i18next';

export interface overviewInputProps {
  className?: string;
  defaultOpen?: boolean;
  overview?: OverviewDataType | undefined;
  author?: AuthorType | undefined;
}

const Overview: FC<overviewInputProps> = ({
  className = "flex-1",
  defaultOpen = false,
  overview,
  author,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const { t, i18n } = useTranslation();

  function showOverview() {
    setIsOpen(!isOpen);

  }

  const renderDetail = () => {
    if (!isOpen) return null;
    const mapURL = `https://www.google.com/maps/embed/v1/view?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&center=${overview?.map.lat},${overview?.map.lng}&zoom=18&maptype=satellite`;

    return (
      <div className="p-4  rounded-2xl ">
        <div className=" rounded-3xl  ">
          <div className="flex-row text-xs text-left">
            <span >{overview?.description}</span>
          </div>

          <div className="text-xs font-medium text-left pt-[15px]">
            <span >{t('overview-listed-by')}</span>
          </div>
          <div className="text-xs font-medium text-left pt-[2px]">
            <span >{author?.firstName} {author?.lastName}</span>
          </div>
          <div className="text-xs font-medium text-left pt-[2px]">
            <span >{author?.company}</span>
          </div>

          <div className="text-xs font-medium text-left pt-[15px]">
            <span >{t('overview-updated')} {overview?.lastUpdate}</span>
          </div>
          <div className="  rounded-xl z-0 mt-[15px]">
            <div className="rounded-xl overflow-hidden z-0">
              <iframe
                title="x"
                width="100%"
                height="200px"
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                // src="https://www.google.com/maps/embed/v1/view?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&center=-33.8569,151.2152&zoom=18&maptype=satellite"
                src={mapURL}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`justify-center  relative overflow-hidden  transition-shadow  ${className}`}>
      <div className="flex flex-row w-full " onClick={showOverview} >
        <div className="my-[9px] text-left w-5/6" >
          <span className="pl-[15px] block text-md font-semibold  flex items-center justify-left h-full">
            {t('overview')}
          </span>
        </div>
        <div className={`flex  w-1/6 justify-end text-white ${isOpen ? "" : ""}`}>
          <span className="flex rounded-tr-lg items-center justify-center w-[30px] bg-[#00D3DB] cursor-pointer rounded-bl-md">
            {isOpen ? (
              <i className=" text-xl font-bold las la-minus"></i>
            ) : (
              <i className="text-xl font-bold las la-plus"></i>
            )}
          </span>
        </div>

      </div>
      {renderDetail()}
    </div>
  );
};

export default Overview;
