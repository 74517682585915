import React, { useEffect, FC, Fragment, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import { Helmet } from "react-helmet";
import { useAuth0 } from "@auth0/auth0-react";
import Input from "shared/Input/Input";
import axios from 'axios';
import { Dialog, Transition } from "@headlessui/react";
import ButtonSquareFull from "shared/Button/ButtonSqareFull";
import Overview from "containers/ListingDetailPage/listing-stay-detail/Overview";
import FactsFeatures from "containers/ListingDetailPage/listing-stay-detail/FactsFeatures";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ListingDataType, AuthorType } from "data/types";
import ListingPropertyCard from "components/PropertyCardH/ListingPropertyCard";
import { Link, useNavigate } from 'react-router-dom';
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Modal from "../../components/ListingImageGallery/components/Modal";
import type { ListingGalleryImage } from "../../components/ListingImageGallery/utils/types";
import ReactS3Client from 'react-aws-s3-typescript';
import { s3Config } from '../../utils/s3Config';
import RentalValue from "containers/ListingDetailPage/listing-stay-detail/RentalValue";
import Neighborhood from "containers/ListingDetailPage/listing-stay-detail/Neighborhood";
import { ClockLoader } from 'react-spinners';
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import { useTranslation } from 'react-i18next';

export interface AuthorPageProps {
    className?: string;
}


const ListingProfile: FC<AuthorPageProps> = ({ className = "" }) => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    let [isOpenModalDetails, setIsOpenModalDetails] = useState(false);
    let [isOpenModalTour, setIsOpenModalTour] = useState(false);
    let [isOpenModalContact, setIsOpenModalContact] = useState(false);
    let [isTypePerson, setIsTypePerson] = useState(true);
    let [isOpenGallery, setIsOpenGallery] = useState(false);
    let [propertyImage, setPropertyImage] = useState<ListingGalleryImage[]>();
    let [isErrorModal, setIsErrorModal] = useState(false);

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [modalPropertyData, setModalPropertyData] = useState<ListingDataType>();
    const [properties, setProperties] = useState<ListingDataType[]>([]);
    const [author, setAuthor] = useState<AuthorType>();
    const [isNameValid, setIsNameValid] = useState(true);
    const [isLastNameValid, setIsLastNameValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isFacebookValid, setIsFacebookValid] = useState(true);
    const [isYoutubeValid, setIsYoutubeValid] = useState(true);
    const [isInstagramValid, setIsInstagramValid] = useState(true);
    const [isCompanyValid, setIsCompanyValid] = useState(true);
    const [isLanguageValid, setIsLanguageValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [agent, setAgent] = useState<AuthorType>();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    function openModalDetails() {
        setIsOpenModalDetails(true);
    }

    function closeModalDetails() {
        setIsOpenModalDetails(false);
    }

    const validateFields = () => {
        setIsNameValid(true);
        setIsLastNameValid(true);
        setIsPhoneValid(true);
        setIsFacebookValid(true);
        setIsInstagramValid(true);
        setIsCompanyValid(true);
        setIsLanguageValid(true);

        let isValid = true;
        if (!agent?.firstName || agent.firstName === "") {
            setIsNameValid(false);
            isValid = false;
        }

        if (!agent?.lastName || agent.lastName === "") {
            setIsLastNameValid(false);
            isValid = false;
        }

        if (!agent?.phone || agent.phone === "") {
            setIsPhoneValid(false);
            isValid = false;
        }

        if (!agent?.facebook || agent.facebook === "") {
            setIsFacebookValid(false);
            isValid = false;
        }

        if (!agent?.youtube || agent.youtube === "") {
            setIsYoutubeValid(false);
            isValid = false;
        }


        if (!agent?.instagram || agent.instagram === "") {
            setIsInstagramValid(false);
            isValid = false;
        }

        if (!agent?.company || agent.company === "") {
            setIsCompanyValid(false);
            isValid = false;
        }

        if (!agent?.language || agent.language === "") {
            setIsLanguageValid(false);
            isValid = false;
        }

        return isValid;
    };


    const handleAddPropertyClick = () => {
        const isValid = validateFields();
        if (!isValid) {
            setIsErrorModal(true);
            return;
        }

        const property = {
            "address": "",
            "price": 0,
            "type": "",
            "mainFeatures": [],
            "sqrft": 0,
            "isRental": false,
            "overview": {
                "description": "",
                "openDate": "",
                "openHour": "",
                "sourceMls": "",
                "mls": "",
                "lastUpdate": "",
                "map": {
                    "lat": 0,
                    "lng": 0
                }
            }
        } as ListingDataType


        const propertyInfo = {
            property,
            agent,
        };

        navigate('/add-listing-1', { state: propertyInfo });
    };

    const renderCard = (stay: ListingDataType, index: number) => {
        return <ListingPropertyCard key={index} className="h-full" data={stay} onButtonClick={handleClick} onRefresh={handleRefresh} onPublish={handlePublish} onUnPublish={handleUnPublish} />;
    };

    const handleClick = (info: ListingDataType) => {
        setModalPropertyData(info);
        setAuthor(info.author);
        const smallScreenBreakpoint = 768;
        if (window.innerWidth <= smallScreenBreakpoint) {
            navigate('/mobile-detail', { state: info });
        } else {
            openModalDetails();
        }
    };

    const handleRefresh = (info: ListingDataType) => {
        getProperties();
    };

    const handlePublish = (info: String | number) => {
        axios.put(process.env.REACT_APP_API + `property/${info}/publish?publish=true`)
            .then((response) => {
                getProperties();
            })
            .catch((error) => {
                console.error('Error fetching properties:', error);
            });

    };

    const handleUnPublish = (info: String | number) => {
        axios.put(process.env.REACT_APP_API + `property/${info}/publish?publish=false`)
            .then((response) => {
                getProperties();
            })
            .catch((error) => {
                console.error('Error fetching properties:', error);
            });
    };

    function closeModalTour() {
        setIsOpenModalTour(false);
    }

    function changeTypePerson() {
        setIsTypePerson(true);
    }

    function changeTypeVideo() {
        setIsTypePerson(false);
    }

    function openModalTour() {
        setIsOpenModalTour(true);
    }

    function openModalContact() {
        setIsOpenModalContact(true);
    }

    function closeModalContact() {
        setIsOpenModalContact(false);
    }

    const handleOpenModalImageGallery = () => {
        const PHOTOS: string[] = [
            ...(modalPropertyData?.galleryImgs || []),
            ...(modalPropertyData?.galleryImgs2 || []),
            ...(modalPropertyData?.galleryImgs3 || [])
        ];
        const featuredImage = modalPropertyData?.featuredImage;
        const featuredImage2 = modalPropertyData?.featuredImage2;
        const featuredImage3 = modalPropertyData?.featuredImage3;

        const newPhotos = [
            featuredImage,
            featuredImage2,
            featuredImage3,
            ...PHOTOS
        ];
        const filteredPhotos = newPhotos.filter((item) => typeof item === 'string');

        setPropertyImage(
            filteredPhotos.map((item, index): ListingGalleryImage => {
                return {
                    id: index,
                    url: typeof item === 'string' ? item : '',
                };
            })
        );
        setIsOpenGallery(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isLoading) {
                await getProperties();
                await getAgentInfo();
            }
        };

        fetchData();
    }, [isLoading]);

    const getProperties = async () => {
        const userId = user && user.sub;
        if (userId) {
            const cleanUserId = userId.replace('auth0|', '');

            axios.get(process.env.REACT_APP_API + `agent-property/${cleanUserId}`)
                .then((response) => {
                    setProperties(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching properties:', error);
                });
        }
    };

    const getAgentInfo = async () => {
        const userId = user && user.sub;
        if (userId) {
            const cleanUserId = userId.replace('auth0|', '');

            axios.get(process.env.REACT_APP_API + `agent/${cleanUserId}`)
                .then((response) => {
                    setAgent(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching properties:', error);
                });
        }
    };

    const updateAgentData = async () => {

        const userId = user && user.sub;
        if (userId) {
            const cleanUserId = userId.replace('auth0|', '');
            setLoading(true);
            axios.put(process.env.REACT_APP_API + `agent/${cleanUserId}`, agent)
                .then((response) => {
                    setLoading(false);
                    validateFields();
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error fetching properties:', error);
                });
        }
    };

    const updateAgentAvatar = async (avatarUrl: string) => {
        // console.log("le di al avatar");
        const userId = user && user.sub;
        if (userId) {
            const cleanUserId = userId.replace('auth0|', '');
            const payload = {
                id: agent?.id || '',
                avatar: avatarUrl || '',
                firstName: agent?.firstName || '',
                lastName: agent?.lastName || '',
                phoneNumber: agent?.phone || '',
                facebook: agent?.facebook || '',
                youtube: agent?.youtube || '',
                instagram: agent?.instagram || '',
                company: agent?.company || '',
                language: agent?.language || ''
            };
            axios.put(process.env.REACT_APP_API + `agent/${cleanUserId}`, payload)
                .then((response) => {
                    setAgent(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching properties:', error);
                });
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log("le di a handleFileChange");
        const file = event.target.files && event.target.files[0];
        const s3 = new ReactS3Client({
            ...s3Config,
            dirName: 'profiles'
        });


        if (file) {
            try {
                const res = await s3.uploadFile(file);
                if (agent) {

                    updateAgentAvatar(res.location);
                }

            } catch (exception) {
                console.log(exception);
            }
        }
    };

    const renderGallery = () => {
        return (
            <div className="flex flex-col ">
                <div className="relative flex-col w-full h-[400px] rounded-sm overflow-hidden cursor-pointer bg-[white] mb-[2px]">
                    <img
                        className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                        src={modalPropertyData?.featuredImage}
                        alt=""
                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                        onClick={handleOpenModalImageGallery}
                    />
                </div>
                <div className="grid grid-cols-2 gap-[2px] scroll-top-0 mb-[2px]">

                    {modalPropertyData?.galleryImgs?.map((item, index) => (
                        <div
                            key={index}
                            onClick={handleOpenModalImageGallery}
                            className={`relative rounded-sm overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                                }`}
                        >
                            <div className="aspect-w-5 aspect-h-3 sm:aspect-w-6 sm:aspect-h-3">
                                <img
                                    className="absolute inset-0 object-cover rounded-sm  w-full h-full"
                                    src={item || ""}
                                    alt=""

                                />
                            </div>
                            <div
                                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"

                            />
                        </div>
                    ))}

                </div>
                <div className="relative flex-col w-full h-[400px] rounded-sm overflow-hidden cursor-pointer bg-[white] mb-[2px]">
                    <img
                        className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                        src={modalPropertyData?.featuredImage2}
                        alt=""
                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                        onClick={handleOpenModalImageGallery}
                    />
                </div>
                <div className="grid grid-cols-2 gap-[2px] scroll-top-0 mb-[2px]">

                    {modalPropertyData?.galleryImgs2?.map((item, index) => (
                        <div
                            key={index}
                            onClick={handleOpenModalImageGallery}
                            className={`relative rounded-sm overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                                }`}
                        >
                            <div className="aspect-w-5 aspect-h-3 sm:aspect-w-6 sm:aspect-h-3">
                                <img
                                    className="absolute inset-0 object-cover rounded-sm  w-full h-full"
                                    src={item || ""}
                                    alt=""

                                />
                            </div>
                            <div
                                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"

                            />
                        </div>
                    ))}

                </div>
                <div className="relative flex-col w-full h-[400px] rounded-sm overflow-hidden cursor-pointer bg-[white] mb-[2px]">
                    <img
                        className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                        src={modalPropertyData?.featuredImage3}
                        alt=""
                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                        onClick={handleOpenModalImageGallery}
                    />
                </div>
                <div className="grid grid-cols-2 gap-[2px] scroll-top-0 mb-[2px]">

                    {modalPropertyData?.galleryImgs3?.map((item, index) => (
                        <div
                            key={index}
                            onClick={handleOpenModalImageGallery}
                            className={`relative rounded-sm overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                                }`}
                        >
                            <div className="aspect-w-5 aspect-h-3 sm:aspect-w-6 sm:aspect-h-3">
                                <img
                                    className="absolute inset-0 object-cover rounded-sm  w-full h-full"
                                    src={item || ""}
                                    alt=""

                                />
                            </div>
                            <div
                                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"

                            />
                        </div>
                    ))}

                </div>
            </div>
        );
    };

    function closeModalError() {
        setIsErrorModal(false);
    }

    const renderModalError = () => {
        return (
            <Transition appear show={isErrorModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={closeModalError}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 " />
                        </Transition.Child>

                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block py-8 h-[100px] w-[350px]">
                                <div className="bg-white border-t-4 border-[#FF0000] rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                                    <div className="flex">
                                        <div className="py-1"><svg className="fill-[#FF0000] h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                                        <div>
                                            <p className="font-bold text-center">Update Details</p>
                                            <p className="text-sm text-center">in order to upload properties please update you profile first. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    };

    const renderTienIch = () => {
        return (
            <div className="inline-grid grid-cols-3 gap-2 py-[10px]">
                <div className="flex items-center space-x-2">
                    <span className="hidden sm:inline-block">
                        <i className="las la-bed text-lg"></i>
                    </span>
                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                        {modalPropertyData?.bedrooms} {t('renderTienIch-beds')}
                    </span>
                </div>
                <div className="flex items-center space-x-2">
                    <span className="hidden sm:inline-block">
                        <i className="las la-bath text-lg"></i>
                    </span>
                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                        {modalPropertyData?.bathrooms} {t('renderTienIch-baths')}
                    </span>
                </div>
                <div className="flex items-center space-x-2">
                    <span className="hidden sm:inline-block">
                        <i className="las la-expand-arrows-alt text-lg"></i>
                    </span>
                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                        {modalPropertyData?.sqrft} {t('renderTienIch-sq')}
                    </span>
                </div>
            </div>
        );
    };

    const renderMainFeatures = (features: string[] | undefined) => {
        if (!features) {
            return null;
        }
        return (
            <div className="flex flex-col">
                {features.map((data, index) => (
                    <div key={index} className="flex flex-row">
                        {index % 2 === 0 && (
                            <>
                                <div className="flex-col prose sm:prose w-1/2">
                                    <ul className="text-xs text-left">
                                        <li>{data}</li>
                                    </ul>
                                </div>
                                {features[index + 1] && (
                                    <div className="flex-col prose sm:prose w-1/2">
                                        <ul className="text-xs text-left">
                                            <li>{features[index + 1]}</li>
                                        </ul>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    const renderSidebar = () => {
        return (
            <div className="py-[20px] ">

                <div className="flex justify-between pb-[10px] ">
                    <span className="text-3xl ">

                        {modalPropertyData?.price}
                    </span>
                </div>
                <div className="flex flex-col ">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        {renderTienIch()}
                    </div>
                    <div className="flex flex-col  pt-[5px] pb-[20px]">
                        <h2 className="flex justify-left text-sm font-medium capitalize">
                            <span className="line-clamp-2">{modalPropertyData?.title}</span>
                        </h2>
                        <div className="flex justify-left text-sm font-light pt-[5px]">
                            {modalPropertyData?.address}
                        </div>
                    </div>
                    <div className="flex items-center space-x-4 pt-[20px] pl-[35px]">
                        <Avatar
                            hasChecked
                            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
                            sizeClass="h-20 w-20"
                            radius="rounded-full"
                            imgUrl={modalPropertyData?.author?.avatar}
                        />
                        <div >
                            <button className="text-md font-medium flex justify-left bg-transparent border-none cursor-pointer focus:outline-none" onClick={() => navigate('/agent', { state: author })}>
                                {modalPropertyData?.author?.firstName} {modalPropertyData?.author?.lastName}
                            </button>

                            <div className=" flex items-center text-sm text-neutral-500 dark:text-neutral-400">
                                <FiveStartIconForRate iconClass="w-4 h-4 " className="space-x-0.5" defaultPoint={modalPropertyData?.author?.reviewStart} />
                                <span className="mx-2">·</span>
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-row justify-left  pt-[30px]">
                        <div className="flex-col pr-[20px]">
                            <ButtonSquareFull onClick={openModalTour} className="bg-[#16385D] text-sm font-medium text-[white] px-[22px]"
                                size="h-11" >
                                {t('renderSidebar-tour')}
                            </ButtonSquareFull>
                        </div>
                        <div className="flex-col">
                            <ButtonSquareFull onClick={openModalContact} className="bg-[white] border border-[#16385D] text-sm font-medium text-[#16385D] px-[22px]"
                                size="h-11" >
                                {t('renderSidebar-contact')}
                            </ButtonSquareFull>
                        </div>
                        {renderModalTour()}
                        {renderModalContact()}
                    </div>
                    <div className="pt-[30px] ">
                        {renderMainFeatures(modalPropertyData?.mainFeatures)}
                    </div>



                    <div className="grid grid-cols-1 gap-3 mt-[35px] pb-[20px] ">
                        <div className="   rounded-lg bg-[#E3F3FF] ">
                            <Overview className="flex flex-col " overview={modalPropertyData?.overview} author={modalPropertyData?.author} />
                        </div>
                        <div className="  rounded-lg bg-[#E3F3FF]">
                            <FactsFeatures className="flex flex-col" facts={modalPropertyData?.factsFeatures} />
                        </div>
                        <div className=" rounded-lg bg-[#E3F3FF]">
                            <RentalValue className="flex flex-col " value={modalPropertyData?.rentalValue} />
                        </div>
                        <div className=" rounded-lg bg-[#E3F3FF]">
                            <Neighborhood className="flex flex-col " neighborhoods={modalPropertyData?.amenities} />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderModalTour = () => {
        return (
            <Transition appear show={isOpenModalTour} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={closeModalTour}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
                        </Transition.Child>

                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block py-8 h-[600px] w-full max-w-sm">
                                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white shadow-xl h-full">
                                    <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                                        <h3
                                            className="text-md font-medium leading-6 text-gray-900"
                                            id="headlessui-dialog-title-70"
                                        >
                                            {t('renderModalTour')}
                                        </h3>
                                        <span className="absolute left-3 top-3">
                                            <ButtonClose onClick={closeModalTour} />
                                        </span>
                                    </div>
                                    <div className=" px-8  text-neutral-700  ">
                                        <label className="block py-[15px]">
                                            <Label>{t('sendTourEmail-type')}</Label>
                                            <div className="flex justify-center w-full">
                                                <div className="inline-flex  rounded-lg py-[3px]" role="group">
                                                    <button onClick={changeTypePerson} type="button" className={`w-[160px] py-3 text-xs font-medium  rounded-l-xl  ${isTypePerson ? "text-white bg-[#1A4676] " : "text-[#1A4676] bg-white border border-[#1A4676]"}`}>
                                                        {t('sendTourEmail-person')}
                                                    </button>
                                                    <button onClick={changeTypeVideo} type="button" className={`w-[160px] py-3 text-xs font-medium  rounded-r-xl ${isTypePerson ? "text-[#1A4676] bg-white border border-[#1A4676] " : "text-white bg-[#1A4676]"}`}>
                                                        Video chat
                                                    </button>
                                                </div>
                                            </div>
                                        </label>
                                        <div className="w-full">
                                            <label className="block">
                                                <Label>{t('sendTourEmail-date')}</Label>
                                                <DatePicker className="rounded-md w-[320px]"
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    minDate={new Date()}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={30}
                                                    timeCaption="time"
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    placeholderText="Select date and time"
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <form className="grid grid-cols-1 gap-6" >
                                                <label className="block pt-[20px]">
                                                    <Label>{t('sendTourEmail-name')}</Label>

                                                    <Input
                                                        placeholder="Type your full name"
                                                        type="text"
                                                        className="mt-1 rounded-md"
                                                    />
                                                </label>
                                                <label className="block">
                                                    <Label>{t('sendTourEmail-email')}</Label>

                                                    <Input
                                                        type="email"
                                                        placeholder="example@example.com"
                                                        className="mt-1 rounded-md"
                                                    />
                                                </label>

                                                <div>
                                                    <button className="p-3 text-white font-medium  rounded-lg w-full bg-[#00D3DB]" type="submit">{t('sendEmail-request')}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    };

    const renderModalContact = () => {
        return (
            <Transition appear show={isOpenModalContact} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={closeModalContact}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
                        </Transition.Child>

                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block py-8 h-[600px] w-full max-w-sm">
                                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white shadow-xl h-full">
                                    <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                                        <h3
                                            className="text-md font-medium leading-6 text-gray-900"
                                            id="headlessui-dialog-title-70"
                                        >
                                            {t('sendEmail-contact-buyer')}
                                        </h3>
                                        <span className="absolute left-3 top-3">
                                            <ButtonClose onClick={closeModalContact} />
                                        </span>
                                    </div>
                                    <div className=" px-8  text-neutral-700  ">
                                        <div>
                                            <form className="grid grid-cols-1 gap-6" >
                                                <label className="block pt-[20px]">
                                                    <Label>{t('sendTourEmail-name')}</Label>

                                                    <Input
                                                        placeholder="Type your full name"
                                                        type="text"
                                                        className="mt-1 rounded-md"
                                                    />
                                                </label>
                                                <label className="block">
                                                    <Label>{t('sendTourEmail-email')}</Label>

                                                    <Input
                                                        type="email"
                                                        placeholder="example@example.com"
                                                        className="mt-1 rounded-md"
                                                    />
                                                </label>
                                                <label className="block">
                                                    <Label>{t('sendTourEmail-message')}</Label>

                                                    <Textarea className="mt-1" rows={6} />
                                                </label>
                                                <div>
                                                    <button className="p-3 text-white font-medium  rounded-lg w-full bg-[#00D3DB]" type="submit">{t('adminPropertyCard-send')}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    };

    const renderModalDetails = () => {
        return (
            <Transition appear show={isOpenModalDetails} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={closeModalDetails}
                >
                    <div className=" text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-xl backdrop-contrast-125 " />
                        </Transition.Child>

                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block  w-full max-w-6xl h-screen">
                                <div className="  flex flex-col lg:flex-row  h-full w-full overflow-hidden">
                                    <div className="relative flex-col lg:w-3/5 w-full  space-y-8 lg:space-y-10 overflow-y-auto scroll-top-0 bg-[white]">
                                        {renderGallery()}

                                    </div>

                                    <div className="flex-col bg-[white] lg:w-2/5 w-full ">
                                        <div className=" h-[30px] bg-[#D9EEFF57]">

                                        </div>
                                        <div className=" mx-[20px] h-full overflow-y-auto ">
                                            {renderSidebar()}

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    };

    const renderSidebar2 = () => {
        return (
            <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
                <label htmlFor="fileInput" className="relative rounded-full overflow-hidden flex">
                    <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                                stroke="currentColor"
                                strokeWidth={1.5}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span className="mt-1 text-xs">{t('buyerProfile-image')}</span>
                    </div>
                    <Avatar sizeClass="w-32 h-32" imgUrl={agent?.avatar} />
                    <input
                        id="fileInput"
                        type="file"
                        className="absolute inset-0 opacity-0 cursor-pointer"
                        onChange={(event) => handleFileChange(event)}
                    />
                </label>

                {isAuthenticated && <div className="space-y-2 text-center flex flex-col items-center">
                    <h2 className="text-sm font-semibold">{user?.email} </h2>
                    <span className="text-neutral-6000 text-xs">
                        {t('listingProfile')}
                    </span>
                </div>
                }

                <div className="space-y-4 w-full">

                    <form className="grid grid-cols-1 gap-6" >

                        <label className="block ">

                            <Input
                                placeholder={agent?.firstName ? agent.firstName : "First Name"}
                                value={agent?.firstName}
                                onChange={(e) => {
                                    if (agent) {
                                        setAgent({ ...agent, id: agent.id || '', firstName: e.target.value });
                                    }

                                }}
                                type="text"
                                className={!isNameValid ? 'mt-1 rounded-md border-red-500' : 'mt-1 rounded-md'}
                            />
                        </label>
                        <label className="block ">

                            <Input
                                placeholder={agent?.lastName ? agent.lastName : "Last Name"}
                                value={agent?.lastName}
                                onChange={(e) => {
                                    if (agent) {
                                        setAgent({ ...agent, id: agent.id || '', lastName: e.target.value });
                                    }

                                }}
                                type="text"
                                className={!isLastNameValid ? 'mt-1 rounded-md border-red-500' : 'mt-1 rounded-md'}
                            />
                        </label>
                        <label className="block ">

                            <Input
                                placeholder={agent?.phone ? agent.phone : "Phone Number"}
                                value={agent?.phone}
                                onChange={(e) => {
                                    if (agent) {
                                        setAgent({ ...agent, id: agent.id || '', phone: e.target.value });
                                    }

                                }}
                                type="text"
                                className={!isPhoneValid ? 'mt-1 rounded-md border-red-500' : 'mt-1 rounded-md'}
                            />
                        </label>

                        <label className="block ">

                            <Input
                                placeholder={agent?.facebook ? agent.facebook : "Facebook Url"}
                                value={agent?.facebook}
                                onChange={(e) => {
                                    if (agent) {
                                        setAgent({ ...agent, id: agent.id || '', facebook: e.target.value });
                                    }

                                }}
                                type="text"
                                className={!isFacebookValid ? 'mt-1 rounded-md border-red-500' : 'mt-1 rounded-md'}
                            />
                        </label>
                        <label className="block ">

                            <Input
                                placeholder={agent?.youtube ? agent.youtube : "Youtube Url"}
                                value={agent?.youtube}
                                onChange={(e) => {
                                    if (agent) {
                                        setAgent({ ...agent, id: agent.id || '', youtube: e.target.value });
                                    }

                                }}
                                type="text"
                                className={!isYoutubeValid ? 'mt-1 rounded-md border-red-500' : 'mt-1 rounded-md'}
                            />
                        </label>
                        <label className="block ">

                            <Input
                                placeholder={agent?.instagram ? agent.instagram : "Instagram Url"}
                                value={agent?.instagram}
                                onChange={(e) => {
                                    if (agent) {
                                        setAgent({ ...agent, id: agent.id || '', instagram: e.target.value });
                                    }

                                }}
                                type="text"
                                className={!isInstagramValid ? 'mt-1 rounded-md border-red-500' : 'mt-1 rounded-md'}
                            />
                        </label>
                        <label className="block ">

                            <Input
                                placeholder={agent?.company ? agent.company : "Company"}
                                value={agent?.company}
                                onChange={(e) => {
                                    if (agent) {
                                        setAgent({ ...agent, id: agent.id || '', company: e.target.value });
                                    }

                                }}
                                type="text"
                                className={!isCompanyValid ? 'mt-1 rounded-md border-red-500' : 'mt-1 rounded-md'}
                            />
                        </label>
                        <label className="block ">

                            <Input
                                placeholder={agent?.language ? agent.language : "Languages"}
                                value={agent?.language}
                                onChange={(e) => {
                                    if (agent) {
                                        setAgent({ ...agent, id: agent.id || '', language: e.target.value });
                                    }

                                }}
                                type="text"
                                className={!isLanguageValid ? 'mt-1 rounded-md border-red-500' : 'mt-1 rounded-md'}
                            />
                        </label>
                        <label className="block">
                            <Label>{t('sendTourEmail-message')}</Label>

                            <Textarea className="mt-1"
                                rows={6}
                                value={agent?.message}
                                onChange={(e) => {
                                    if (agent) {
                                        setAgent({ ...agent, id: agent.id || '', message: e.target.value });
                                    }

                                }}
                            />
                        </label>

                        {loading ? (
                            <div className="px-[18px] pt-[5px]">
                                <ClockLoader color="#36d7b7" size={20} />
                            </div>
                        ) : (
                            <div>
                                <button className="p-3 text-white font-medium  rounded-lg w-full bg-[#00D3DB]" type="button" onClick={updateAgentData}>Update</button>
                            </div>
                        )}

                    </form>

                </div>
            </div>
        );
    };

    const renderSection1 = () => {
        return (
            <div className="listingSection__wrap">
                <div className="flex justify-between ">
                    <div>
                        <h2 className="text-2xl font-semibold">{t('listingProfile-properties')}</h2>

                    </div>
                    <div>
                        <ButtonSquareFull
                            className="bg-[#16385D] text-sm font-medium text-[white] px-[22px]"
                            size="h-11"
                            onClick={handleAddPropertyClick}
                        >
                            {t('listingProfile-add')}
                        </ButtonSquareFull>

                    </div>

                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                <div>

                    <div
                        className={`grid gap-6  md:gap-10 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 sm:mx-[80px] md:mx-[0px] `}
                    >
                        {properties.map(renderCard)}
                    </div>
                    {renderModalDetails()}
                    <div className="flex mt-11 justify-center items-center">
                    </div>
                </div>
            </div>
        );
    };



    return (
        <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">

            <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
                <div className="block flex-grow mb-24 lg:mb-0">
                    <div className="lg:sticky lg:top-24">{renderSidebar2()}</div>
                </div>
                <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
                    {renderSection1()}
                    {renderModalError()}
                    {isOpenGallery &&
                        <Modal
                            images={propertyImage || []}
                            onClose={() => {
                                setIsOpenGallery(false);
                            }}
                        />
                    }

                </div>
            </main>
        </div>
    );
};

export default ListingProfile;