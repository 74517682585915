import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import { ListingDataType } from "data/types";
import axios from 'axios';
import { useTranslation } from 'react-i18next';


export interface PropertyCardHProps {
  className?: string;
  data: ListingDataType;
  onButtonClick: (info: any) => void;
  onRefresh: (info: any) => void;
  onPublish: (info: string | number) => void;
  onUnPublish: (info: string | number) => void;
}

const ListingPropertyCard: FC<PropertyCardHProps> = ({
  className = "",
  data,
  onButtonClick,
  onRefresh,
  onPublish,
  onUnPublish
}) => {
  const {
    galleryImgs = [],
    title,
    author,
    address,
    saleOff,
    type,
    isLike,
    price,
    id,
    published,
    status,
  } = data;

  const handleClick = () => {
    onButtonClick(data);
  };

  function publishProperty(id: string | number) {
    onPublish(id);
  };

  function unpublishProperty(id: string | number) {
    onUnPublish(id);
  };

  const { t, i18n } = useTranslation();
  const deleteProperty = async (id: string | number) => {


    const payload = {
      deleted: true
    };
    axios.put(process.env.REACT_APP_API + `property/${id}`, payload)
      .then((response) => {
        onRefresh(data);
      })
      .catch((error) => {
        console.error('Error fetching properties:', error);
      });

  };


  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0  w-full h-[260px] ">
        <GallerySlider
          ratioClass=""
          galleryImgs={galleryImgs}
          className="w-full h-full overflow-hidden will-change-transform"
          uniqueID={`PropertyCardH_${id}`}
        />

        {/* {status == "waiting" && (
          <SaleOffBadge className="absolute right-0 top-0 !bg-orange-500" />
        )} */}
        <SaleOffBadge status={`${status}`} className="absolute right-0 top-0 " />
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2">
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bed text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.bedrooms} {t('renderTienIch-beds')}
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bath text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.bathrooms} {t('renderTienIch-baths')}
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {data?.sqrft} {t('renderTienIch-sq')}
          </span>
        </div>
      </div>
    );
  };



  const renderContent = () => {
    return (
      <div className="flex-grow  flex flex-col items-start">
        <div className="space-y-4 w-full flex-grow">
          <div className="flex w-full justify-between px-5 pt-6 items-end">
            <span className="flex items-center justify-center leading-none text-xl font-medium text-[#16385D]">
              {`${price}`}
            </span>
          </div>
          <div className="ml-[20px]">
            {renderTienIch()}
            <div className="flex flex-col  pt-[5px] h-[80px]">
              <h2 className="flex justify-left text-sm font-medium capitalize">
                <span className="line-clamp-2">{title}</span>
              </h2>
              <div className="flex justify-left text-sm font-light pt-[5px]">
                {address}
              </div>
            </div>
          </div>


          <div className="w-full h-[40px] flex justify-between">
            <div className="flex-auto bg-[#19CFD8] flex items-center justify-center">
              {published ? (
                <button className="text-xs font-medium capitalize bg-[#00D3DB] text-white px-3 py-1 rounded-lg" onClick={() => {
                  if (id !== undefined && id !== null) {
                    unpublishProperty(id);
                  }
                }}>
                  {t('listingPropertyCard-unpublish')}
                </button>
              ) : (
                <button className="text-xs font-medium capitalize bg-[#00D3DB] text-white px-3 py-1 rounded-lg" onClick={() => {
                  if (id !== undefined && id !== null) {
                    publishProperty(id);
                  }
                }}>
                  {t('listingPropertyCard-publish')}
                </button>
              )}
            </div>
            <div className="flex-auto bg-[#16385D] flex items-center justify-center">
              <button className="text-xs font-medium capitalize  text-white px-3 py-1 rounded-lg cursor-pointer" onClick={handleClick}>
                {t('renderTienIch-details')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group h-[520px]  relative bg-white border border-neutral-100  rounded-md overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      <div className="h-full w-full flex flex-col  ">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      <button className="absolute left-3 top-3 sm:left-3 sm:top-3 w-8 h-8 flex items-center justify-center rounded-full cursor-pointer" onClick={() => {
        if (id !== undefined && id !== null) {
          deleteProperty(id);
        }
      }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="11" fill="red" />
          <path fill="white" d="M8 12h8c.55 0 1 .45 1 1s-.45 1-1 1H8c-.55 0-1-.45-1-1s.45-1 1-1z" />
        </svg>
      </button>
    </div>
  );
};

export default ListingPropertyCard;