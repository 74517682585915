import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { ListingDataType } from "data/types";
// import StartRating from "components/StartRating/StartRating";
// import { Link } from "react-router-dom";
// import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
// import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
// import Badge from "shared/Badge/Badge";

export interface StayCardProps {
  className?: string;
  data?: ListingDataType;
  onButtonClick: (info: any) => void;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCard2: FC<StayCardProps> = ({
  className = "",
  data = DEMO_DATA,
  onButtonClick
}) => {
  const {
    galleryImgs=[],
    // listingCategory,
    address,
    title,
    price,
    // reviewStart,
    // reviewCount,
    id,
  } = data;

  const handleClick = () => {
    // Call the callback function and pass the information
    onButtonClick("onButtonClick");
    //console.log("le di a la tarjeta");
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={galleryImgs}
        />
     
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="p-3 space-y-2">
        <div className="space-y-2">
         
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize text-base `}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
        
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {price}
           
          </span>
          {/* {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )} */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white  border border-neutral-100  rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      onClick={handleClick}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {/* <Link to={href}>{renderContent()}</Link> */}
      {renderContent() }

    </div>
  );
};

export default StayCard2;