import React, { createContext, useState, useContext, ReactNode } from 'react';
import { ListingDataType } from "data/types";


// Define the type for the context data
interface SearchContextData {
  listings: ListingDataType[]; // Array of ListingDataType
  setListings: (listings: ListingDataType[]) => void;
}

// Create the initial context data
const initialSearchContextData: SearchContextData = {
  listings: [],
  setListings: () => {} // Placeholder function
};

// Create the context
const SearchContext = createContext<SearchContextData>(initialSearchContextData);

// Create a custom hook to use the search context
export const useSearch = () => useContext(SearchContext);

// Create the provider component
export const SearchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Define your state and context functions
  const [listings, setListings] = useState<ListingDataType[]>([]);

  // Wrap children with the provider and provide the context value
  return (
    <SearchContext.Provider value={{ listings, setListings }}>
      {children}
    </SearchContext.Provider>
  );
};