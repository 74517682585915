import React from "react";
import { Link } from "react-router-dom";
import texture from "images/logoLarge.svg";


export interface LogoProps {
  className?: string;
}

const LogoLarge: React.FC<LogoProps> = ({
  className = " w-[400px]",

}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block  ${className}`}
    >

        <img
          className=""
          src={texture}
          alt="Logo-Large"
        />
      
    </Link>
  );
};

export default LogoLarge;
