import React, { FC, ReactNode, useState } from "react";
import imagePng from "images/hero-testimonials.png";
import avatar1 from "images/Testimonial_1.png";
import avatar2 from "images/Testimonial_2.png";
import avatar3 from "images/Testimonial_3.png";
import avatar4 from "images/Testimonial_4.png";
import StarRatings from "react-star-ratings";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";
import Avatar from "shared/Avatar/Avatar";
import { useTranslation } from 'react-i18next';
// import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
// import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRateDecimals";




export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Stays" | "Experiences" | "Cars" | "Flights";
  currentTab: SearchTab;
  rightImage?: string;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage = imagePng,
}) => {
  const [showMore, setShowMore] = useState(false);
  const { t, i18n } = useTranslation();
  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative  ${className}`}
      data-nc-id="SectionHeroArchivePage"
    >
      <div className="flex flex-col lg:flex-row lg:pt-[10px]">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-2 pb-10 lg:pb-64 xl:pb-80 xl:pr-14  xl:mr-0">
          <h2 className="text-3xl md:text-4xl font-semibold ">
            {t('sectionHeroArchivePage-title')}
          </h2>
          <div className=" w-[365px] font-normal text-base  text-neutral-500">
            {t('sectionHeroArchivePage-sub-title')}
          </div>

        </div>
        <div className="flex flex-row justify-center items-center pb-[35px]">
          <div className="flex-col ">
            {showMore ? (
              <>
                <div className="m-[15px]  p-[15px] w-[220px] h-[290px] bg-white rounded-xl">
                  <div className="flex items-center space-x-4 ">
                    <Avatar
                      hasChecked
                      hasCheckedClass="w-0 h-0 "
                      sizeClass="h-[70px] w-[70px]"
                      radius="rounded-full"
                      imgUrl={avatar1}
                    />
                    <div >
                      <a className="block text-md font-semibold">
                        David
                      </a>
                      <a className="block text-md font-semibold mt-[-3px]">
                        Silverman
                      </a>

                    </div>
                  </div>
                  <div className=" py-[10px] ">
                    <StarRatings
                      rating={5.0}
                      starRatedColor="#facc15"
                      numberOfStars={5}
                      starDimension="20px"
                      starSpacing="3px"
                    />

                  </div>
                  <div className="py-[2px] text-[10px] leading-normal font-light">
                  {t('sectionHeroArchivePage-david')}
                  </div>
                </div>
                <div className="m-[15px] p-[15px] w-[220px] h-[290px] bg-white rounded-xl">
                  <div className="flex items-center space-x-4 ">
                    <Avatar
                      hasChecked
                      hasCheckedClass="w-0 h-0 "
                      sizeClass="h-[70px] w-[70px]"
                      radius="rounded-full"
                      imgUrl={avatar2}
                    />
                    <div >
                      <a className="block text-md font-semibold">
                        Manuel
                      </a>
                      <a className="block text-md font-semibold mt-[-3px]">
                        Vazquez
                      </a>

                    </div>
                  </div>
                  <div className=" py-[10px] ">
                    <StarRatings
                      rating={4.5}
                      starRatedColor="#facc15"
                      numberOfStars={5}
                      starDimension="20px"
                      starSpacing="3px"
                    />
                  </div>
                  <div className="py-[2px] text-[10px] leading-normal font-light">
                    {t('sectionHeroArchivePage-manuel')}
                  </div>
                </div>
                <div className="m-[15px]  p-[15px] w-[220px] h-[290px] bg-white rounded-xl">
                  <div className="flex items-center space-x-4 ">
                    <Avatar
                      hasChecked
                      hasCheckedClass="w-0 h-0 "
                      sizeClass="h-[70px] w-[70px]"
                      radius="rounded-full"
                      imgUrl={avatar3}
                    />
                    <div >
                      <a className="block text-md font-semibold">
                        Ronda
                      </a>
                      <a className="block text-md font-semibold mt-[-3px]">
                        Johnson
                      </a>

                    </div>
                  </div>
                  <div className=" py-[10px] ">
                    <StarRatings
                      rating={4.5}
                      starRatedColor="#facc15"
                      numberOfStars={5}
                      starDimension="20px"
                      starSpacing="3px"
                    />
                  </div>
                  <div className="py-[2px] text-[10px] leading-normal font-light">
                  {t('sectionHeroArchivePage-ronda')}
                  </div>
                </div>
                <div className="m-[15px] p-[15px] w-[220px] h-[290px] bg-white rounded-xl">
                  <div className="flex items-center space-x-4 ">
                    <Avatar
                      hasChecked
                      hasCheckedClass="w-0 h-0 "
                      sizeClass="h-[70px] w-[70px]"
                      radius="rounded-full"
                      imgUrl={avatar4}
                    />
                    <div >
                      <a className="block text-md font-semibold">
                        Crystal
                      </a>
                      <a className="block text-md font-semibold mt-[-3px]">
                        Garner
                      </a>

                    </div>
                  </div>
                  <div className=" py-[10px] ">
                    <StarRatings
                      rating={5.0}
                      starRatedColor="#facc15"
                      numberOfStars={5}
                      starDimension="20px"
                      starSpacing="3px"
                    />
                  </div>
                  <div className="py-[2px] text-[10px] leading-normal font-light">
                  {t('sectionHeroArchivePage-crystal')}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="m-[15px]  p-[15px] w-[220px] h-[290px] bg-white rounded-xl">
                  <div className="flex items-center space-x-4 ">
                    <Avatar
                      hasChecked
                      hasCheckedClass="w-0 h-0 "
                      sizeClass="h-[70px] w-[70px]"
                      radius="rounded-full"
                      imgUrl={avatar1}
                    />
                    <div >
                      <a className="block text-md font-semibold">
                        David
                      </a>
                      <a className="block text-md font-semibold mt-[-3px]">
                        Silverman
                      </a>

                    </div>
                  </div>
                  <div className=" py-[10px] ">
                    <StarRatings
                      rating={5.0}
                      starRatedColor="#facc15"
                      numberOfStars={5}
                      starDimension="20px"
                      starSpacing="3px"
                    />
                  </div>
                  <div className="py-[2px] text-[10px] leading-normal font-light">
                  {t('sectionHeroArchivePage-david')}
                  </div>
                </div>
                <div className="m-[15px] p-[15px] w-[220px] h-[290px] bg-white rounded-xl">
                  <div className="flex items-center space-x-4 ">
                    <Avatar
                      hasChecked
                      hasCheckedClass="w-0 h-0 "
                      sizeClass="h-[70px] w-[70px]"
                      radius="rounded-full"
                      imgUrl={avatar2}
                    />
                    <div >
                      <a className="block text-md font-semibold">
                        Manuel
                      </a>
                      <a className="block text-md font-semibold mt-[-3px]">
                        Vazquez
                      </a>

                    </div>
                  </div>
                  <div className=" py-[10px] ">
                    <StarRatings
                      rating={4.5}
                      starRatedColor="#facc15"
                      numberOfStars={5}
                      starDimension="20px"
                      starSpacing="3px"
                    />
                  </div>
                  <div className="py-[2px] text-[10px] leading-normal font-light">
                  {t('sectionHeroArchivePage-manuel')}
                  </div>
                </div>
              </>
            )}
            <div className="block md:hidden  pt-[15px] px-[15px] text-sm relative z-10">
              <button
                className="text-[#00D3DB] hover:underline"
                onClick={handleReadMore}
              >
                {showMore ? 'Read Less' : 'Read More'}
              </button>
            </div>
          </div>

          <div className="hidden md:block flex-col  pt-[80px]">
            <div className="m-[15px] p-[15px] w-[220px] h-[290px] bg-white rounded-xl">
              <div className="flex items-center space-x-4 ">
                <Avatar
                  hasChecked
                  hasCheckedClass="w-0 h-0 "
                  sizeClass="h-[70px] w-[70px]"
                  radius="rounded-full"
                  imgUrl={avatar3}
                />
                <div >
                  <a className="block text-md font-semibold">
                    Ronda
                  </a>
                  <a className="block text-md font-semibold mt-[-3px]">
                    Johnson
                  </a>

                </div>
              </div>
              <div className=" py-[10px] ">
                <StarRatings
                  rating={4.5}
                  starRatedColor="#facc15"
                  numberOfStars={5}
                  starDimension="20px"
                  starSpacing="3px"
                />
              </div>
              <div className="py-[2px] text-[10px] leading-normal font-light">
              {t('sectionHeroArchivePage-ronda')}
              </div>
            </div>
            <div className="m-[15px] p-[15px] w-[220px] h-[290px] bg-white rounded-xl">
              <div className="flex items-center space-x-4 ">
                <Avatar
                  hasChecked
                  hasCheckedClass="w-0 h-0 "
                  sizeClass="h-[70px] w-[70px]"
                  radius="rounded-full"
                  imgUrl={avatar4}
                />
                <div >
                  <a className="block text-md font-semibold">
                    Crystal
                  </a>
                  <a className="block text-md font-semibold mt-[-3px]">
                    Garner
                  </a>

                </div>
              </div>
              <div className=" py-[10px] ">
                <StarRatings
                  rating={5.0}
                  starRatedColor="#facc15"
                  numberOfStars={5}
                  starDimension="20px"
                  starSpacing="3px"
                />
              </div>
              <div className="py-[2px] text-[10px] leading-normal font-light">
              {t('sectionHeroArchivePage-crystal')}
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default SectionHeroArchivePage;
