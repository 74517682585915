import React, { FC } from "react";
// import pattern from "images/Pattern_B&W.png";
// import texture from "images/bg_Texture.svg";
// import texture from "images/bg_Texture.svg";
import texture from "images/bg_Texture5.png";



export interface BackgroundSectionProps {
  className?: string;
  children?: React.ReactNode;
}

const BackgroundSection: FC<BackgroundSectionProps> = ({
  className,
  children,
}) => {
  return (
    <div
      // className={`overflow-hidden absolute inset-y-0 left-1/2 transform -translate-x-1/2  z-0 ${className}`}
      className={`overflow-hidden absolute  z-0 ${className}`}

      style={{
        backgroundImage: `url(${texture})`, // Set the background image using the 'texture' variable
        backgroundSize: 'auto', // The image will display at its original size
        backgroundRepeat: 'repeat', // The image will repeat both horizontally and vertically
      }}
    >
      {/* <img
          className={` `}
          src={texture}
          alt="back"
        /> */}
      {/* {children} */}
    </div>
  );
};

export default BackgroundSection;
