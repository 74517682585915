import React, { FC, useEffect, useState } from "react";


// import { AuthorType, ListingDataType, FactsFeaturesDataType } from "data/types";
// import { useLocation } from 'react-router-dom';
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import ButtonSecondary from "shared/Button/ButtonSecondary";
// import { Link, useNavigate } from 'react-router-dom';
// import ReactS3Client from 'react-aws-s3-typescript';
// import { s3Config } from '../../utils/s3Config';
// import { FadeLoader } from 'react-spinners';
export interface PageAddListing4Props { }





const PageAddListing12: FC<PageAddListing4Props> = () => {

  // const location = useLocation();
  // const propertyInfo = location.state;
  // const [agent, setAgent] = useState<AuthorType>();
  // const [loading, setLoading] = useState(false);
  // const [galleryImgs, setGalleryImgs] = useState<string[]>();
  // const [property, setProperty] = useState<ListingDataType>(
  //   {
  //     "id": "id1",
  //     "author": {
  //       "id": 1,
  //       "firstName": "Tony",
  //       "lastName": "Stark",
  //       "displayName": "-",
  //       "email": "tonystark0@gmail.com",
  //       "avatar": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmdU20JbK_Fv68F2pkt3ODsftZrI3VnqJwvnG_R06PFfPo19r-HNuSGRWZrFwFYw3is3Q&usqp=CAU",
  //       "bgImage": "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
  //       "count": 40,
  //       "reviewStart": 4.8,
  //       "reviewCount": 28,
  //       "language": "English, Spanish",
  //       "href": "/author",
  //       "desc": "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       "jobName": "Author Job",
  //       "company": "Test Company",
  //       "joindate": "10/10/2023",
  //       "facebook": "url",
  //       "twitter": "url",
  //       "youtube": "url",
  //       "instagram": "url"
  //     },
  //     "date": "May 20, 2021",
  //     "title": "LUXURIOUS AND MODERN HOUSE FOR SALE",
  //     "featuredImage": "",
  //     "galleryImgs": [
  //       "https://images.pexels.com/photos/1268871/pexels-photo-1268871.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/1179156/pexels-photo-1179156.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
     
  //       "https://images.pexels.com/photos/2506988/pexels-photo-2506988.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
  //     ],
  //     "viewCount": 602,
  //     "isLike": false,
  //     "address": "La Altagracia Province, Punta Cana, Punta Cana, 32923",
  //     "price": "$5,072,588",
  //     "type": "House",
  //     "bedrooms": 3,
  //     "bathrooms": 3,
  //     "mainFeatures": ["condominium", "Built in 1968", "Forced air, radiator, oil", "2 Garage Spaces", "$2,042 Monthly", "$261 price/sqft"],
  //     "sqrft": 1345,
  //     "saleOff": "",
  //     "isAds": false,
  //     "isRental": false,
  //     "overview": {
  //       "description": "Welcome to 779 prospect Ave PH, the largest penthouse in the Hartford area! This Luxurious 3 beedroom/2.5 bathroom condo has everything you could want or need to entertain! Enjoy over 3,400 sq ft of indoor living space",
  //       "openDate": "02/02/2023",
  //       "openHour": "12:00 PM - 2:00 PM",
  //       "sourceMls": "Smart MLS",
  //       "mls": "170546053",
  //       "lastUpdate": "02/02/2023",
  //       "map": { "lat": -33.8569, "lng": 151.2152 }
  //     },
  //     "factsFeatures": {
  //       "bedrooms": ["Bedrooms: 5", "Bathrooms: 4", "Full bathrooms: 3", "1/2 bathrooms: 2"],
  //       "basements": ["Basement: Heated,Garage Access,Storage Space"],
  //       "heating": ["Heating features: Forced Air, Radiator, Oil"],
  //       "cooling": ["Cooling features: Central Air"],
  //       "appliances": ["Appliances included: Electric Range, Wall Oven, Microwave, Range Hood, Refrigerator, Freezer, Dishwasher, Disposal, Washer, Dryer, Wine Cooler", "Laundry features: Private laundry room in unit, Main Level, Laundry Room"],
  //       "interior": ["Interior features: Wired for Sound, Elevator, Open Floorplan, Exercise Room, Foyer, Laundry Room"],
  //       "otherInterior": ["Common walls with other units/homes: End Unit", "Total interior livable area: 3,441 sqft", "Finished area above ground: 3,441", "Total number of fireplaces: 1", "Virtual tour: View virtual"],
  //       "parking": ["Total spaces: 2", "Parking features: Unit Garage, Assigned, Secured", "Garage spaces: 2"],
  //       "accesability": ["Accessibility features: Accessible Doors, Accessible Hallway(s)"],
  //       "property": ["Levels: 1", "Stories: 1", "Exterior features: Awning(s), Garden, Barbecue, Lighting"],
  //       "lot": ["Lot features: Historic District, Level, Lightly Wooded, Fruit Trees"],
  //     }
  //   });
  // // const property = propertyInfo.property as ListingDataType;
  // const [coverImage, setCoverImage] = useState('');
  // // const factsF = propertyInfo.property as ListingDataType;
  // const navigate = useNavigate();

  // useEffect(() => {
  //   checkValues();
  // }, []);

  // useEffect(() => {
  // }, [coverImage, galleryImgs]);

  // const checkValues = () => {
  //   setCoverImage(property.featuredImage || '');
  //   setGalleryImgs(property.galleryImgs || []);
  // }

  // const toNextStep = () => {
  //   property.featuredImage = coverImage;
  //   property.galleryImgs = [
  //     "https://vr-thumbs.vacationrenter.com/960/agoda/700245933/6b44f6.jpg",
  //     "https://vr-thumbs.vacationrenter.com/960/agoda/700245933/cc6422.jpg",
  //     "https://vr-thumbs.vacationrenter.com/960/agoda/700245933/cc329b.jpg",
  //     "https://vr-thumbs.vacationrenter.com/960/agoda/700245933/566fa3.jpg",
  //     "https://vr-thumbs.vacationrenter.com/960/agoda/700245933/e837a8.jpg",
  //     "https://vr-thumbs.vacationrenter.com/960/agoda/700245933/40290e.jpg"
  //   ];

  //   const propertyInfo = {
  //     property,
  //     agent,
  //   };
  //   navigate('/add-listing-7', { state: propertyInfo });

  // }

  // const handleCoverImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files && event.target.files[0];
  //   const s3 = new ReactS3Client({
  //     ...s3Config,
  //     dirName: 'profiles'
  //   });


  //   if (file) {
  //     try {
  //       setLoading(true);
  //       const res = await s3.uploadFile(file);
  //       setCoverImage(res.location);
  //     } catch (exception) {
  //       console.log(exception);
  //     } finally {
  //       setLoading(false);
  //     }

  //   }
  // };

  // const toPrevtStep = () => {
  //   const propertyInfo = {
  //     property,
  //     agent,
  //   };

  //   navigate('/add-listing-2', { state: propertyInfo });
  // };


  // const renderGallery = () => {
  //   return (

  //     <div className="border-dashed relative flex-col w-full h-[400px] rounded-md overflow-hidden cursor-pointer bg-[white] ">
  //       {coverImage && (
  //         <img
  //           className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
  //           src={coverImage}
  //           alt=""
  //           sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
  //         />
  //       )}
  //       {loading ? (
  //         <div className="flex items-center justify-center h-[400px]">
  //           <FadeLoader color="#36d7b7" />
  //         </div>
  //       ) : (

  //         <div className="relative z-10 flex-col w-full h-[400px] rounded-sm overflow-hidden cursor-pointer bg-transparent ">
  //           <div className="flex items-center justify-center h-[400px] px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
  //             <div className="space-y-1 text-center">
  //               <svg
  //                 className="mx-auto h-12 w-12 text-neutral-400"
  //                 stroke="currentColor"
  //                 fill="none"
  //                 viewBox="0 0 48 48"
  //                 aria-hidden="true"
  //               >
  //                 <path
  //                   d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
  //                   strokeWidth="2"
  //                   strokeLinecap="round"
  //                   strokeLinejoin="round"
  //                 ></path>
  //               </svg>
  //               <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
  //                 <label
  //                   htmlFor="file-upload"
  //                   className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
  //                 >
  //                   <span>Click to upload a cover Image</span>
  //                   <input
  //                     id="file-upload"
  //                     name="file-upload"
  //                     type="file"
  //                     className="sr-only"
  //                     onChange={handleCoverImageChange}
  //                   />
  //                 </label>
  //                 <p className="pl-1"></p>
  //               </div>
  //               <p className="text-xs text-neutral-500 dark:text-neutral-400">
  //                 PNG, JPG, GIF up to 10MB
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //     </div>

  //   );
  // };


  return (

    <div
      className={`nc-PageAddListing2 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing2"
    >
      {/* <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">3</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 5
          </span>
        </div>
        <div>
          <h2 className="text-2xl font-semibold">Property Images</h2>

        </div>
        <div className="listingSection__wrap ">
          <div className="space-y-8">
            <span className="text-lg font-semibold">Cover image</span>
            {renderGallery()}
            <ButtonPrimary onClick={toNextStep}> {"Add Property Images"} </ButtonPrimary>

            <div className="grid grid-cols-2 gap-[2px] scroll-top-0  ">

              {(
                property.galleryImgs?.map((item, index) => (
                  <div
                    key={index}
                    className={`border-dashed border-2 border-neutral-300 relative rounded-sm overflow-hidden `}
                  >
                    <div className="border-dashed relative flex-col rounded-md overflow-hidden cursor-pointer bg-[white] aspect-w-6 aspect-h-3 sm:aspect-w-6 sm:aspect-h-3">
                      <img
                        className="absolute inset-0 object-cover w-full h-full"
                        src={item || ""}
                        alt=""
                      />
                    </div>
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    />

                  </div>
                ))
              )}


            </div>
            
          </div>

        </div>
        <div className="flex justify-end space-x-5">
          <ButtonSecondary onClick={toPrevtStep}>Go back</ButtonSecondary>
          <ButtonPrimary onClick={toNextStep}> {"Continue"} </ButtonPrimary>
        </div>
      </div> */}
    </div>
  );
};

export default PageAddListing12;
