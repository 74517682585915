import React from "react";
import ReactDOM from "react-dom/client";
import "rc-slider/assets/index.css";
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import { Auth0Provider } from '@auth0/auth0-react';
import App from "./App";
import reportWebVitals from "./reportWebVitals";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Auth0Provider
    domain="paradise-living.us.auth0.com"
    clientId="xgnllnhYBTxtIYQmAjTCEOj3ZL3aFycA"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
     <App />
  </Auth0Provider>
  // </React.StrictMode>
);


reportWebVitals();
