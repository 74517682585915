import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { FC } from "react";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import Input from "shared/Input/Input";
import { useTranslation } from 'react-i18next';

export interface PageAddListing3Props { }

const PageAddListing3: FC<PageAddListing3Props> = () => {
  const { t, i18n } = useTranslation();
  return (
    <CommonLayout
      index="03"
      backtHref="/add-listing-2"
      nextHref="/add-listing-4"
    >
      <>
        <h2 className="text-2xl font-semibold">{t('pageAddListing3-property-size')}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="space-y-8">

          <FormItem label="Square feet (sqft)">
            <Input />
          </FormItem>
          <NcInputNumber label="Guests" defaultValue={1} />
          <NcInputNumber label="Bedroom" defaultValue={1} />
          <NcInputNumber label="Beds" defaultValue={1} />
          <NcInputNumber label="Bathroom" defaultValue={1} />
          <NcInputNumber label="Kitchen" defaultValue={1} />
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing3;
