import React, { useEffect, Fragment, useState, useRef, FormEvent } from "react";
import axios from 'axios';
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BackgroundMap from "components/BackgroundSection/BackgroundMap";
import SectionHero2 from "components/SectionHero2/SectionHero2";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import SectionGridFeatureProperty from "./SectionGridFeatureProperty";
import SectionGridMainSkeleton from "./SectionGridMainSkeleton";
import SectionVideos from "./SectionVideos2";
import SectionAd from "./SectionAd";
import SectionTestimonials from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import Footer from "shared/Footer/Footer2";
import { Dialog, Transition } from "@headlessui/react";
import Avatar from "shared/Avatar/Avatar";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import ButtonSquareFull from "shared/Button/ButtonSqareFull";
import Overview from "containers/ListingDetailPage/listing-stay-detail/Overview";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import FactsFeatures from "containers/ListingDetailPage/listing-stay-detail/FactsFeatures";
import RentalValue from "containers/ListingDetailPage/listing-stay-detail/RentalValue";
import Neighborhood from "containers/ListingDetailPage/listing-stay-detail/Neighborhood";
import { AuthorType, ListingDataType } from "data/types";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/ListingImageGallery/components/Modal";
import type { ListingGalleryImage } from "../../components/ListingImageGallery/utils/types";
import hAzul from "images/hojaAzul.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';


function PageHome2() {

  let [isOpenModalDetails, setIsOpenModalDetails] = useState(false);
  let [isOpenModalTour, setIsOpenModalTour] = useState(false);
  let [isOpenModalContact, setIsOpenModalContact] = useState(false);
  let [isTypePerson, setIsTypePerson] = useState(true);
  let [isOpenGallery, setIsOpenGallery] = useState(false);
  let [propertyImage, setPropertyImage] = useState<ListingGalleryImage[]>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [modalPropertyData, setModalPropertyData] = useState<ListingDataType>();
  const [author, setAuthor] = useState<AuthorType>();
  const navigate = useNavigate();
  const [properties, setProperties] = useState<ListingDataType[]>([]);
  const [showMore, setShowMore] = useState(false);
  const form = useRef<HTMLFormElement>(null);
  const [isLoadingProperty, setIsLoadingProperty] = useState(true);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { t, i18n } = useTranslation();

  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  const containerHeight = showMore ? 'h-[500px]' : 'h-[300px]';

  useEffect(() => {
    const $body = document.querySelector("body");
    if (!$body) return;
    $body.classList.add("theme-cyan-blueGrey");
    return () => {
      $body.classList.remove("theme-cyan-blueGrey");
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading) {
        await getProperties();
      }
    };

    fetchData();
  }, [isLoading]);

  const getProperties = async () => {
    let apiUrl = `${process.env.REACT_APP_API}property/features`;
    if (isAuthenticated) {
      const userId = user && user.sub;
      if (userId) {
        const cleanUserId = userId.replace('auth0|', '');
        apiUrl += `?userId=${cleanUserId}`;
      }
    }
    // console.log(apiUrl);
    axios.get(apiUrl)
      .then((response) => {
        setProperties(response.data);
        setIsLoadingProperty(false);
      })
      .catch((error) => {
        console.error('Error fetching properties:', error);
        setIsLoadingProperty(false);
      });


  };

  function openModalDetails() {
    setIsOpenModalDetails(true);
  }

  function closeModalDetails() {
    setIsOpenModalDetails(false);
  }

  function closeModalTour() {
    setIsOpenModalTour(false);
  }

  function changeTypePerson() {
    setIsTypePerson(true);
  }

  function changeTypeVideo() {
    setIsTypePerson(false);
  }

  function openModalTour() {
    setIsOpenModalTour(true);
  }

  function openModalContact() {
    setIsOpenModalContact(true);
  }

  function closeModalContact() {
    setIsOpenModalContact(false);
  }

  const HandleButtonClick = (info: ListingDataType) => {
    setModalPropertyData(info);
    setAuthor(info.author);
    const smallScreenBreakpoint = 768;
    if (window.innerWidth <= smallScreenBreakpoint) {
      navigate('/mobile-detail', { state: info });
    } else {
      openModalDetails();
    }
  };


  const handleOpenModalImageGallery = () => {
    const PHOTOS: string[] = [
      ...(modalPropertyData?.galleryImgs || []),
      ...(modalPropertyData?.galleryImgs2 || []),
      ...(modalPropertyData?.galleryImgs3 || [])
    ];
    const featuredImage = modalPropertyData?.featuredImage;
    const featuredImage2 = modalPropertyData?.featuredImage2;
    const featuredImage3 = modalPropertyData?.featuredImage3;

    const newPhotos = [
      featuredImage,
      featuredImage2,
      featuredImage3,
      ...PHOTOS
    ];
    const filteredPhotos = newPhotos.filter((item) => typeof item === 'string');

    setPropertyImage(
      filteredPhotos.map((item, index): ListingGalleryImage => {
        return {
          id: index,
          url: typeof item === 'string' ? item : '',
        };
      })
    );
    setIsOpenGallery(true);
  };

  const renderGallery = () => {
    return (
      <div className="flex flex-col ">
        <div className="relative flex-col w-full h-[400px] rounded-sm overflow-hidden cursor-pointer bg-[white] mb-[2px]">
          <img
            className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
            src={modalPropertyData?.featuredImage}
            alt=""
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            onClick={handleOpenModalImageGallery}
          />
        </div>
        <div className="grid grid-cols-2 gap-[2px] scroll-top-0 mb-[2px]">

          {modalPropertyData?.galleryImgs?.map((item, index) => (
            <div
              key={index}
              onClick={handleOpenModalImageGallery}
              className={`relative rounded-sm overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                }`}
            >
              <div className="aspect-w-5 aspect-h-3 sm:aspect-w-6 sm:aspect-h-3">
                <img
                  className="absolute inset-0 object-cover rounded-sm  w-full h-full"
                  src={item || ""}
                  alt=""

                />
              </div>
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"

              />
            </div>
          ))}

        </div>
        <div className="relative flex-col w-full h-[400px] rounded-sm overflow-hidden cursor-pointer bg-[white] mb-[2px]">
          <img
            className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
            src={modalPropertyData?.featuredImage2}
            alt=""
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            onClick={handleOpenModalImageGallery}
          />
        </div>
        <div className="grid grid-cols-2 gap-[2px] scroll-top-0 mb-[2px]">

          {modalPropertyData?.galleryImgs2?.map((item, index) => (
            <div
              key={index}
              onClick={handleOpenModalImageGallery}
              className={`relative rounded-sm overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                }`}
            >
              <div className="aspect-w-5 aspect-h-3 sm:aspect-w-6 sm:aspect-h-3">
                <img
                  className="absolute inset-0 object-cover rounded-sm  w-full h-full"
                  src={item || ""}
                  alt=""

                />
              </div>
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"

              />
            </div>
          ))}

        </div>
        <div className="relative flex-col w-full h-[400px] rounded-sm overflow-hidden cursor-pointer bg-[white] mb-[2px]">
          <img
            className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
            src={modalPropertyData?.featuredImage3}
            alt=""
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            onClick={handleOpenModalImageGallery}
          />
        </div>
        <div className="grid grid-cols-2 gap-[2px] scroll-top-0 mb-[2px]">

          {modalPropertyData?.galleryImgs3?.map((item, index) => (
            <div
              key={index}
              onClick={handleOpenModalImageGallery}
              className={`relative rounded-sm overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                }`}
            >
              <div className="aspect-w-5 aspect-h-3 sm:aspect-w-6 sm:aspect-h-3">
                <img
                  className="absolute inset-0 object-cover rounded-sm  w-full h-full"
                  src={item || ""}
                  alt=""

                />
              </div>
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"

              />
            </div>
          ))}

        </div>
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2 py-[10px]">
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bed text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {modalPropertyData?.bedrooms}  {t('renderTienIch-beds')}
          </span>
        </div>

        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bath text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {modalPropertyData?.bathrooms}  {t('renderTienIch-baths')}
          </span>
        </div>

        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            {modalPropertyData?.sqrft}  {t('renderTienIch-sq')}
          </span>
        </div>
      </div>
    );
  };

  const renderMainFeatures = (features: string[] | undefined) => {
    if (!features) {
      return null;
    }
    return (
      <div className="flex flex-col">
        {features.map((data, index) => (
          <div key={index} className="flex flex-row">
            {index % 2 === 0 && (
              <>
                <div className="flex-col prose sm:prose w-1/2">
                  <ul className="text-xs text-left">
                    <li>{data}</li>
                  </ul>
                </div>
                {features[index + 1] && (
                  <div className="flex-col prose sm:prose w-1/2">
                    <ul className="text-xs text-left">
                      <li>{features[index + 1]}</li>
                    </ul>
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="py-[20px] ">

        <div className="flex justify-between pb-[10px] ">
          <span className="text-3xl ">

            {modalPropertyData?.price}
          </span>
        </div>
        <div className="flex flex-col ">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            {renderTienIch()}
          </div>
          <div className="flex flex-col  pt-[5px] pb-[20px]">
            <h2 className="flex justify-left text-sm font-medium capitalize">
              <span className="line-clamp-2">{modalPropertyData?.title}</span>
            </h2>
            <div className="flex justify-left text-sm font-light pt-[5px]">
              {modalPropertyData?.address}
            </div>
          </div>
          <div className="flex items-center space-x-4 pt-[20px] pl-[35px]">
            <Avatar
              hasChecked
              hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
              sizeClass="h-20 w-20"
              radius="rounded-full"
              imgUrl={modalPropertyData?.author?.avatar}
            />
            <div >
              <button className="text-md font-medium flex justify-left bg-transparent border-none cursor-pointer focus:outline-none" onClick={() => navigate('/agent', { state: author })}>
                {modalPropertyData?.author?.firstName} {modalPropertyData?.author?.lastName}
              </button>

              <div className=" flex items-center text-sm text-neutral-500 dark:text-neutral-400">
                <FiveStartIconForRate iconClass="w-4 h-4 " className="space-x-0.5" defaultPoint={modalPropertyData?.author?.reviewStart} />
                <span className="mx-2">·</span>
              </div>
            </div>

          </div>
          <div className="flex flex-row justify-left  pt-[30px]">
            <div className="flex-col pr-[20px]">
              <ButtonSquareFull onClick={openModalTour} className="bg-[#16385D] text-sm font-medium text-[white] px-[22px]"
                size="h-11" >
                {t('renderSidebar-tour')}
              </ButtonSquareFull>
            </div>
            <div className="flex-col">
              <ButtonSquareFull onClick={openModalContact} className="bg-[white] border border-[#16385D] text-sm font-medium text-[#16385D] px-[22px]"
                size="h-11" >
                {t('renderSidebar-contact')}
              </ButtonSquareFull>
            </div>
            {renderModalTour()}
            {renderModalContact()}
          </div>
          <div className="pt-[30px] ">
            {renderMainFeatures(modalPropertyData?.mainFeatures)}
          </div>
          <div className="grid grid-cols-1 gap-3 mt-[35px] pb-[20px] ">
            <div className="   rounded-lg bg-[#E3F3FF] ">
              <Overview className="flex flex-col " overview={modalPropertyData?.overview} author={modalPropertyData?.author} />
            </div>
            <div className="  rounded-lg bg-[#E3F3FF]">
              <FactsFeatures className="flex flex-col" facts={modalPropertyData?.factsFeatures} />
            </div>
            <div className=" rounded-lg bg-[#E3F3FF]">
              <RentalValue className="flex flex-col " value={modalPropertyData?.rentalValue} />
            </div>
            <div className=" rounded-lg bg-[#E3F3FF]">
              <Neighborhood className="flex flex-col " neighborhoods={modalPropertyData?.amenities} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const sendTourEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      const formData = new FormData(form.current);
      let tyepSelected = ""
      if (isTypePerson) {
        tyepSelected = "in-person"
      } else {
        tyepSelected = "video"
      }

      const tourData = {
        name: formData.get('name') as string,
        email: formData.get('email') as string,
        date: formData.get('date') as string,
        type: tyepSelected,

      };

      //console.log(tourData);
      setIsOpenModalTour(false);
    }
  };

  const sendContactEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      const formData = new FormData(form.current);

      const contactData = {
        name: formData.get('name') as string,
        email: formData.get('email') as string,
        message: formData.get('message') as string,

      };

      // console.log(contactData);
      setIsOpenModalContact(false);
    }
  };

  const renderModalTour = () => {
    return (
      <Transition appear show={isOpenModalTour} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalTour}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-[600px] w-full max-w-sm">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-md font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      {t('renderModalTour')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalTour} />
                    </span>
                  </div>
                  <form className=" px-8  text-neutral-700 " ref={form} onSubmit={sendTourEmail}>
                    <label className="block py-[15px]">
                      <Label> {t('sendTourEmail-type')}</Label>
                      <div className="flex justify-center w-full">
                        <div className="inline-flex  rounded-lg py-[3px]" role="group">
                          <button onClick={changeTypePerson} type="button" className={`w-[160px] py-3 text-xs font-medium  rounded-l-xl  ${isTypePerson ? "text-white bg-[#1A4676] " : "text-[#1A4676] bg-white border border-[#1A4676]"}`}>
                            {t('sendTourEmail-person')}
                          </button>
                          <button onClick={changeTypeVideo} type="button" className={`w-[160px] py-3 text-xs font-medium  rounded-r-xl ${isTypePerson ? "text-[#1A4676] bg-white border border-[#1A4676] " : "text-white bg-[#1A4676]"}`}>
                            Video chat
                          </button>
                        </div>
                      </div>
                    </label>
                    <div className="w-full">
                      <label className="block">
                        <Label>{t('sendTourEmail-date')}</Label>
                        <DatePicker className="rounded-md w-[320px]"
                          selected={startDate}
                          name="date"
                          onChange={(date) => setStartDate(date)}
                          minDate={new Date()}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={30}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                          placeholderText="Select date and time"
                        />
                      </label>
                    </div>
                    <div>
                      <div className="grid grid-cols-1 gap-6" >
                        <label className="block pt-[20px]">
                          <Label>{t('sendTourEmail-name')}</Label>

                          <Input
                            placeholder="Type your full name"
                            name="name"
                            type="text"
                            className="mt-1 rounded-md"
                          />
                        </label>
                        <label className="block">
                          <Label>{t('sendTourEmail-email')}</Label>

                          <Input
                            type="email"
                            name="email"
                            placeholder="example@example.com"
                            className="mt-1 rounded-md"
                          />
                        </label>

                        <div>
                          <button className="p-3 text-white font-medium  rounded-lg w-full bg-[#00D3DB]" type="submit">{t('sendEmail-request')}</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderModalContact = () => {
    return (
      <Transition appear show={isOpenModalContact} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalContact}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-[600px] w-full max-w-sm">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-md font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      {t('sendEmail-contact-buyer')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalContact} />
                    </span>
                  </div>
                  <form className=" px-8  text-neutral-700  " ref={form} onSubmit={sendContactEmail}>
                    <div>
                      <div className="grid grid-cols-1 gap-6" >
                        <label className="block pt-[20px]">
                          <Label>{t('sendTourEmail-name')}</Label>

                          <Input
                            placeholder="Type your full name"
                            type="text"
                            name="name"
                            className="mt-1 rounded-md"
                          />
                        </label>
                        <label className="block">
                          <Label>{t('sendTourEmail-email')}</Label>

                          <Input
                            type="email"
                            name="email"
                            placeholder="example@example.com"
                            className="mt-1 rounded-md"
                          />
                        </label>
                        <label className="block">
                          <Label>{t('sendTourEmail-message')}</Label>

                          <Textarea className="mt-1" rows={6} name="message" />
                        </label>
                        <div>
                          <button className="p-3 text-white font-medium  rounded-lg w-full bg-[#00D3DB]" type="submit">Send</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderModalDetails = () => {
    return (
      <Transition appear show={isOpenModalDetails} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalDetails}
        >
          <div className=" text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-xl backdrop-contrast-125 " />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block  w-full max-w-6xl h-screen">
                <div className="  flex flex-col lg:flex-row  h-full w-full overflow-hidden">
                  <div className="relative flex-col lg:w-3/5 w-full  space-y-8 lg:space-y-10 overflow-y-auto scroll-top-0 bg-[white]">
                    {renderGallery()}

                  </div>

                  <div className="flex-col bg-[white] lg:w-2/5 w-full ">
                    <div className=" h-[30px] bg-[#D9EEFF57]">

                    </div>
                    <div className=" mx-[20px] h-full overflow-y-auto ">
                      {renderSidebar()}

                    </div>
                  </div>

                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      <div className=" ">
        <SectionHero2 className="" />
      </div>
      <div className={`w-full ${showMore ? 'h-[780px] sm:h-[680px] lg:h-[520px]' : 'h-[570px] lg:h-[440px]'}`}>
        <div className="nc-SectionBanner3 h-full flex justify-center relative">
          <BackgroundSection className="absolute opacity-50 w-full h-full z-0" />
          <div className="container relative flex flex-col">
            <div className="absolute top-0 left-0 z-10 px-[30px] lg:px-[60px] ">
              <h2 className="text-3xl md:text-4xl pt-[40px] font-semibold relative">
                {t('home-title')}
              </h2>
              <div className="pt-[20px] pb-[30px] font-normal text-base  text-neutral-500">
                {t('home-text1')}
              </div>
              <div className="font-normal text-base  text-neutral-500  border-l-4 border-[#00D3DB] pl-4">
                {showMore ? (
                  <>
                    <p>
                      {t('home-text2')}
                    </p>
                    <br />
                    <p>
                      {t('home-text3')}
                    </p>
                    <br />
                    <p>
                      {t('home-text4')}
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      {t('home-text2')}
                    </p>
                  </>
                )}
              </div>

              <div className="pt-[15px] px-[15px] text-sm relative z-10">
                <button
                  className="text-[#00D3DB] hover:underline"
                  onClick={handleReadMore}
                >
                  {showMore ? t('home-read-less') : t('home-read-more')}
                </button>
              </div>
              {showMore ? null : (
                <div className="pt-[30px]  ">
                  <img
                    className="mx-auto h-25 w-20"
                    src={hAzul}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex relative">
        <BackgroundSection className=" opacity-50 w-full h-full " />
        <div className="container relative pb-8 md:pb-20">

          <div>
            {isLoadingProperty ? (
              <SectionGridMainSkeleton />
            ) : (
              <SectionGridFeatureProperty featuredListings={properties} propertyDetail={HandleButtonClick} />
            )}
          </div>
          {renderModalDetails()}
          {isOpenGallery &&
            <Modal
              images={propertyImage || []}
              onClose={() => {
                setIsOpenGallery(false);
              }}
            />
          }
        </div>
      </div>
      <div className="w-full ">
        <SectionAd />
      </div>
      <div className="container relative  ">
        <SectionVideos />
      </div>
      <div className="flex relative">
        <BackgroundSection className="opacity-50  w-full h-full " />
        <div className="container relative  pt-10 ">
          <SectionTestimonials currentPage="Stays" currentTab="Stays" />
        </div>
      </div>
      <div className="relative ">
        <BackgroundMap />
        <Footer />
      </div>
    </div>

  );
}

export default PageHome2;
