import React, { FC, useEffect, useState, useRef, ChangeEvent } from "react";
import { AuthorType, ListingDataType } from "data/types";
import { useLocation } from 'react-router-dom';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useNavigate } from 'react-router-dom';
import ReactS3Client from 'react-aws-s3-typescript';
import { s3Config } from '../../utils/s3Config';
import { FadeLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';

export interface PageAddListing4Props { }


const PageAddListing4: FC<PageAddListing4Props> = () => {

  const location = useLocation();
  const propertyInfo = location.state;
  const agent = propertyInfo.agent as AuthorType;
  const property = propertyInfo.property as ListingDataType;
  const [coverImage, setCoverImage] = useState('');
  const [secondCoverImage, setSecondCoverImage] = useState('');
  const [thirdCoverImage, setThirdCoverImage] = useState('');
  const [galleryImgs, setGalleryImgs] = useState<string[]>(["-", "-", "-", "-"]);
  const [secondGalleryImgs, setSecondGalleryImgs] = useState<string[]>(["-", "-", "-", "-"]);
  const [thirdGalleryImgs, setThirdGalleryImgs] = useState<string[]>(["-", "-", "-", "-"]);
  const navigate = useNavigate();
  const [loadingCover, setLoadingCover] = useState(false);
  const [loadingSecondCover, setLoadingSecondCover] = useState(false);
  const [loadingThirdCover, setLoadingThirdCover] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [loadingSecondImages, setLoadingSecondImages] = useState(false);
  const [loadingThirdImages, setLoadingThirdImages] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const CoverInputRef = useRef<HTMLInputElement | null>(null);
  const secondFileInputRef = useRef<HTMLInputElement | null>(null);
  const secondCoverInputRef = useRef<HTMLInputElement | null>(null);
  const thirdFileInputRef = useRef<HTMLInputElement | null>(null);
  const thirdCoverInputRef = useRef<HTMLInputElement | null>(null);
  const [draggingMainCover, setDraggingMainCover] = useState(false);
  const [draggingSecondCover, setDraggingSecondCover] = useState(false);
  const [draggingThirdCover, setDraggingThirdCover] = useState(false);

  const [draggingMainProperties0, setDraggingMainProperties0] = useState(false);
  const [draggingMainProperties1, setDraggingMainProperties1] = useState(false);
  const [draggingMainProperties2, setDraggingMainProperties2] = useState(false);
  const [draggingMainProperties3, setDraggingMainProperties3] = useState(false);

  const [draggingSecondProperties, setDraggingSecondProperties] = useState(false);
  const [draggingThirdProperties, setDraggingThirdProperties] = useState(false);

  const [isMainCoverValid, setIsMainCoverValid] = useState(true);
  const [isMainPropertyValid0, setIsMainPropertyValid0] = useState(true);
  const [isMainPropertyValid1, setIsMainPropertyValid1] = useState(true);
  const [isMainPropertyValid2, setIsMainPropertyValid2] = useState(true);
  const [isMainPropertyValid3, setIsMainPropertyValid3] = useState(true);


  const [isSecondCoverValid, setISecondCoverValid] = useState(true);

  const [isSecondPropertyValid0, setIsSecondPropertyValid0] = useState(true);
  const [isSecondPropertyValid1, setIsSecondPropertyValid1] = useState(true);
  const [isSecondPropertyValid2, setIsSecondPropertyValid2] = useState(true);
  const [isSecondPropertyValid3, setIsSecondPropertyValid3] = useState(true);

  const [isThirdCoverValid, setIsThirdCoverValid] = useState(true);

  const [isThirdPropertyValid0, setIsThirdPropertyValid0] = useState(true);
  const [isThirdPropertyValid1, setIsThirdPropertyValid1] = useState(true);
  const [isThirdPropertyValid2, setIsThirdPropertyValid2] = useState(true);
  const [isThirdPropertyValid3, setIsThirdPropertyValid3] = useState(true);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    checkValues();
  }, []);

  useEffect(() => {
  }, [galleryImgs, secondGalleryImgs, thirdGalleryImgs]);

  const checkValues = () => {

    setCoverImage(property.featuredImage || '');
    setSecondCoverImage(property.featuredImage2 || '');
    setThirdCoverImage(property.featuredImage3 || '');
    setGalleryImgs(property.galleryImgs || ["-", "-", "-", "-"]);
    setSecondGalleryImgs(property.galleryImgs2 || ["-", "-", "-", "-"]);
    setThirdGalleryImgs(property.galleryImgs3 || ["-", "-", "-", "-"]);

  }

  const toNextStep = () => {
    const isValid = validateFields();
    if (!isValid) {
      return;
    }

    property.featuredImage = coverImage;
    property.featuredImage2 = secondCoverImage;
    property.featuredImage3 = thirdCoverImage;
    property.galleryImgs = galleryImgs;
    property.galleryImgs2 = secondGalleryImgs;
    property.galleryImgs3 = thirdGalleryImgs;

    const propertyInfo = {
      property,
      agent,
    };

    navigate('/add-listing-7', { state: propertyInfo });
  };

  const validateFields = () => {
    setIsMainCoverValid(true);
    setIsMainPropertyValid0(true);
    setIsMainPropertyValid1(true);
    setIsMainPropertyValid2(true);
    setIsMainPropertyValid3(true);
    setISecondCoverValid(true);
    setIsSecondPropertyValid0(true);
    setIsSecondPropertyValid1(true);
    setIsSecondPropertyValid2(true);
    setIsSecondPropertyValid3(true);
    setIsThirdPropertyValid0(true);
    setIsThirdPropertyValid1(true);
    setIsThirdPropertyValid2(true);
    setIsThirdPropertyValid3(true);
    setIsThirdCoverValid(true);
    let isValid = true;
    if (!coverImage) {
      setIsMainCoverValid(false);
      isValid = false;
    }
    if (galleryImgs[0].trim() == '-') {
      setIsMainPropertyValid0(false);
      isValid = false;
    }

    if (galleryImgs[1].trim() == '-') {
      setIsMainPropertyValid1(false);
      isValid = false;
    }

    if (galleryImgs[2].trim() == '-') {
      setIsMainPropertyValid2(false);
      isValid = false;
    }

    if (galleryImgs[3].trim() == '-') {
      setIsMainPropertyValid3(false);
      isValid = false;
    }

    if (!secondCoverImage) {
      setISecondCoverValid(false);
      isValid = false;
    }

    if (secondGalleryImgs[0].trim() == '-') {
      setIsSecondPropertyValid0(false);
      isValid = false;
    }

    if (secondGalleryImgs[1].trim() == '-') {
      setIsSecondPropertyValid1(false);
      isValid = false;
    }

    if (secondGalleryImgs[2].trim() == '-') {
      setIsSecondPropertyValid2(false);
      isValid = false;
    }

    if (secondGalleryImgs[3].trim() == '-') {
      setIsSecondPropertyValid3(false);
      isValid = false;
    }

    if (!thirdCoverImage) {
      setIsThirdCoverValid(false);
      isValid = false;
    }

    if (thirdGalleryImgs[0].trim() == '-') {
      setIsThirdPropertyValid0(false);
      isValid = false;
    }

    if (thirdGalleryImgs[1].trim() == '-') {
      setIsThirdPropertyValid1(false);
      isValid = false;
    }

    if (thirdGalleryImgs[2].trim() == '-') {
      setIsThirdPropertyValid2(false);
      isValid = false;
    }

    if (thirdGalleryImgs[3].trim() == '-') {
      setIsThirdPropertyValid3(false);
      isValid = false;
    }

    return isValid;
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingMainCover(true);
  };

  const handleDragEnterSecondCover = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingSecondCover(true);
  };

  const handleDragEnterThirdCover = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingThirdCover(true);
  };

  const handleDragEnterMainPropertie0 = (e: React.DragEvent<HTMLDivElement>) => {
    setDraggingMainProperties0(true);
  };

  const handleDragEnterMainPropertie1 = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingMainProperties1(true);
  };

  const handleDragEnterMainPropertie2 = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingMainProperties2(true);
  };

  const handleDragEnterMainPropertie3 = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingMainProperties3(true);
  };

  const handleDragEnterSecondProperties = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingSecondProperties(true);
  };

  const handleDragEnterThirdProperties = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingThirdProperties(true);
  };

  const handleDragLeave = () => {
    setDraggingMainCover(false);
    setDraggingMainProperties0(false);
    setDraggingMainProperties1(false);
    setDraggingMainProperties2(false);
    setDraggingMainProperties3(false);
    setDraggingSecondCover(false);
    setDraggingSecondProperties(false);
    setDraggingThirdCover(false);
    setDraggingThirdProperties(false);

  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDropMainCover = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingMainCover(false);
    const files: File[] = Array.from(e.dataTransfer.files);

    const file = files[0];
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: 'profiles'
    });


    if (file) {
      try {
        setLoadingCover(true);
        const res = await s3.uploadFile(file);

        setCoverImage(res.location);
        setLoadingCover(false);

      } catch (exception) {
        console.log(exception);
        setLoadingCover(false);
      }
    }
  };

  const handleDropSecondCover = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingSecondCover(false);
    const files: File[] = Array.from(e.dataTransfer.files);

    const file = files[0];
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: 'profiles'
    });


    if (file) {
      try {
        setLoadingSecondCover(true);
        const res = await s3.uploadFile(file);

        setSecondCoverImage(res.location);
        setLoadingSecondCover(false);

      } catch (exception) {
        console.log(exception);
        setLoadingSecondCover(false);
      }
    }
  };

  const handleDropThirdCover = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingThirdCover(false);
    const files: File[] = Array.from(e.dataTransfer.files);

    const file = files[0];
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: 'profiles'
    });


    if (file) {
      try {
        setLoadingThirdCover(true);
        const res = await s3.uploadFile(file);

        setThirdCoverImage(res.location);
        setLoadingThirdCover(false);

      } catch (exception) {
        console.log(exception);
        setLoadingThirdCover(false);
      }
    }
  };

  const handleDropMainProperties = async (index: number, e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingMainCover(false);
    const files: File[] = Array.from(e.dataTransfer.files);

    const file = files[0];
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: 'profiles'
    });

    if (file) {
      try {
        setLoadingImages(true);
        const res = await s3.uploadFile(file);

        addStringAtIndexFirst(index, res.location);

        setLoadingImages(false);

      } catch (exception) {
        console.log(exception);
        setLoadingImages(false);
      }
    }
  };

  const addStringAtIndexFirst = (indexToAdd: number, newString: string) => {
    if (galleryImgs) {
      const newArray = [...galleryImgs];
      newArray[indexToAdd] = newString;
      setGalleryImgs(newArray);
    }
  };

  const addStringAtIndexSecond = (indexToAdd: number, newString: string) => {
    if (secondGalleryImgs) {
      const newArray = [...secondGalleryImgs];
      newArray[indexToAdd] = newString;
      setSecondGalleryImgs(newArray);
    }
  };

  const addStringAtIndexThird = (indexToAdd: number, newString: string) => {
    if (thirdGalleryImgs) {
      const newArray = [...thirdGalleryImgs];
      newArray[indexToAdd] = newString;
      setThirdGalleryImgs(newArray);
    }
  };

  const handleDropSecondProperties = async (index: number, e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingSecondCover(false);

    const files: File[] = Array.from(e.dataTransfer.files);

    const file = files[0];
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: 'profiles'
    });

    if (file) {
      try {
        setLoadingSecondImages(true);
        const res = await s3.uploadFile(file);

        addStringAtIndexSecond(index, res.location);

        setLoadingSecondImages(false);

      } catch (exception) {
        console.log(exception);
        setLoadingSecondImages(false);
      }
    }
  };

  const handleDropThirdProperties = async (index: number, e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingThirdCover(false);

    const files: File[] = Array.from(e.dataTransfer.files);

    const file = files[0];
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: 'profiles'
    });

    if (file) {
      try {
        setLoadingThirdImages(true);
        const res = await s3.uploadFile(file);

        addStringAtIndexThird(index, res.location);

        setLoadingThirdImages(false);

      } catch (exception) {
        console.log(exception);
        setLoadingThirdImages(false);
      }
    }
  };


  const handleCoverImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

    const file = event.target.files && event.target.files[0];
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: 'profiles'
    });


    if (file) {
      try {
        setLoadingCover(true);
        const res = await s3.uploadFile(file);
        setCoverImage(res.location);
        setLoadingCover(false);


      } catch (exception) {
        console.log(exception);
        setLoadingCover(false);
      }
    }
  };

  const handleAddCoverImage = () => {
    if (CoverInputRef.current) {
      CoverInputRef.current.click();
    }
  };

  const handlePropertyImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const file = selectedFiles[0];
      try {

        setLoadingImages(true);
        const s3 = new ReactS3Client({
          ...s3Config,
          dirName: 'profiles'
        });

        const res = await s3.uploadFile(file);

        setGalleryImgs((prevGalleryImgs) => [...(prevGalleryImgs || []), res.location]);
        setLoadingImages(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }


      } catch (exception) {
        console.error('Error uploading image:', exception);
        setLoadingImages(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    }
  };

  const handleAddImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };



  const handleSecondCoverImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: 'profiles'
    });
    if (file) {
      try {
        setLoadingSecondCover(true);
        const res = await s3.uploadFile(file);
        setSecondCoverImage(res.location);
        setLoadingSecondCover(false);
      } catch (exception) {
        console.log(exception);
        setLoadingSecondCover(false);
      }
    }
  };

  const handleAddSecondCoverImage = () => {
    if (secondCoverInputRef.current) {
      secondCoverInputRef.current.click();
    }
  };

  const handleSecondPropertyImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const file = selectedFiles[0];
      try {

        setLoadingSecondImages(true);
        const s3 = new ReactS3Client({
          ...s3Config,
          dirName: 'profiles'
        });

        const res = await s3.uploadFile(file);

        setSecondGalleryImgs((prevGalleryImgs) => [...(prevGalleryImgs || []), res.location]);
        setLoadingSecondImages(false);
        if (secondFileInputRef.current) {
          secondFileInputRef.current.value = '';
        }


      } catch (exception) {
        console.error('Error uploading image:', exception);
        setLoadingSecondImages(false);
        if (secondFileInputRef.current) {
          secondFileInputRef.current.value = '';
        }
      }
    }
  };

  const handleSecondAddImage = () => {
    if (secondFileInputRef.current) {
      secondFileInputRef.current.click();
    }
  };



  const handleThirdCoverImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    const s3 = new ReactS3Client({
      ...s3Config,
      dirName: 'profiles'
    });
    if (file) {
      try {
        setLoadingThirdCover(true);
        const res = await s3.uploadFile(file);
        setThirdCoverImage(res.location);
        setLoadingThirdCover(false);
      } catch (exception) {
        console.log(exception);
        setLoadingThirdCover(false);
      }
    }
  };

  const handleAddThirdCoverImage = () => {
    if (thirdCoverInputRef.current) {
      thirdCoverInputRef.current.click();
    }
  };

  const handleThirdPropertyImage = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const file = selectedFiles[0];
      try {

        setLoadingThirdImages(true);
        const s3 = new ReactS3Client({
          ...s3Config,
          dirName: 'profiles'
        });

        const res = await s3.uploadFile(file);

        setThirdGalleryImgs((prevGalleryImgs) => [...(prevGalleryImgs || []), res.location]);
        setLoadingThirdImages(false);
        if (thirdFileInputRef.current) {
          thirdFileInputRef.current.value = '';
        }


      } catch (exception) {
        console.error('Error uploading image:', exception);
        setLoadingThirdImages(false);
        if (thirdFileInputRef.current) {
          thirdFileInputRef.current.value = '';
        }
      }
    }
  };

  const handleThirdAddImage = () => {
    if (thirdFileInputRef.current) {
      thirdFileInputRef.current.click();
    }
  };



  const toPrevtStep = () => {
    const propertyInfo = {
      property,
      agent,
    };

    navigate('/add-listing-2', { state: propertyInfo });
  };

  const handleDeleteGalleryImage = (index: number) => {
    if (galleryImgs) {
      const newArray = [...galleryImgs];
      newArray[index] = "-";
      setGalleryImgs(newArray);
    }
  };

  const handleDeleteSecondGalleryImage = (index: number) => {
    if (secondGalleryImgs) {
      const newArray = [...secondGalleryImgs];
      newArray[index] = "-";
      setSecondGalleryImgs(newArray);
    }
  };

  const handleDeleteThirdGalleryImage = (index: number) => {
    if (thirdGalleryImgs) {
      const newArray = [...thirdGalleryImgs];
      newArray[index] = "-";
      setThirdGalleryImgs(newArray);
    }
  };

  const handleDeleteCoverImage = () => {
    setCoverImage('');
    property.featuredImage = '';
  };

  const handleDeleteSecondCoverImage = () => {
    setSecondCoverImage('');
  };

  const handleDeleteThirdCoverImage = () => {
    setThirdCoverImage('');
  };

  const renderMainCover = () => {
    return (

      <div className={' relative flex-col w-full h-[400px] rounded-md overflow-hidden  bg-[white] '}>

        {coverImage ? (
          <div className=" group">
            <img
              className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
              src={coverImage}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <button
              className="absolute w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={handleDeleteCoverImage}
            >
              -
            </button>
          </div>
        ) : (
          loadingCover ? (
            <div className="flex items-center justify-center h-[400px]">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : (
            <div
              className={`relative z-10 flex-col w-full h-[400px] rounded-sm overflow-hidden bg-transparent `}
            >
              <div className={`flex items-center justify-center h-[400px] px-6 pt-5 pb-6 border-dashed  border-2  rounded-md' ${!isMainCoverValid ? 'border-red-500' : 'border-neutral-300'} ${draggingMainCover ? 'border-blue-500' : 'border-neutral-300'} `}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDropMainCover}>
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                    <label
                      htmlFor="file-upload"
                      className="relative font-medium text-primary-6000 "
                    >
                      <span>{t('pageAddListing4-upload-cover')}</span>
                    </label>
                    <p className="pl-1"></p>
                  </div>
                  <p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG up to 10MB</p>
                </div>
              </div>
            </div>

          ))}
      </div>

    );
  };

  const renderFirstPropertyImages = () => {
    return (
      <div>
        <div className="grid grid-cols-2 gap-[2px] scroll-top-0  ">
          {!(galleryImgs?.[0] && galleryImgs[0].trim() !== '-') ? (
            <div className={`border-dashed border-2 relative rounded-sm overflow-hidden ${!isMainPropertyValid0 ? 'border-red-500' : 'border-neutral-300'}  ${!draggingMainProperties0 ? 'border-blue-500' : 'border-blue-500'}`} >
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">

                    <div className={`flex items-center justify-center h-full px-6 pt-5 pb-6 rounded-md    `}
                      onDragEnter={handleDragEnterMainPropertie0}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropMainProperties(0, e)}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative  font-medium text-primary-6000 "

                          >
                            <span>{t('pageAddListing4-drag-property')}</span>

                          </label>
                          <p className="pl-1"></p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          {t('pageAddListing4-type')}
                        </p>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>

          ) : (
            <div>
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">

                    <img
                      className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                      src={galleryImgs?.[0] || ""}
                      alt=""
                    />
                    <button
                      className="absolute  w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full cursor-pointer"
                      onClick={() => handleDeleteGalleryImage(0)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!(galleryImgs?.[1] && galleryImgs[1].trim() !== '-') ? (
            <div
              className={`border-dashed border-2 relative rounded-sm overflow-hidden  ${!isMainPropertyValid1 ? 'border-red-500' : 'border-neutral-300'} ${draggingMainProperties1 ? 'border-blue-500' : 'border-neutral-300'}`} >
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <div className={`flex items-center justify-center h-full px-6 pt-5 pb-6 rounded-md   `}
                      onDragEnter={handleDragEnterMainPropertie1}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropMainProperties(1, e)}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative  font-medium text-primary-6000 "

                          >
                            <span>{t('pageAddListing4-drag-property')}</span>

                          </label>
                          <p className="pl-1"></p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          {t('pageAddListing4-type')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <img
                      className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                      src={galleryImgs?.[1] || ""}
                      alt=""
                    />
                    <button
                      className="absolute  w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full cursor-pointer"
                      onClick={() => handleDeleteGalleryImage(1)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!(galleryImgs?.[2] && galleryImgs[2].trim() !== '-') ? (
            <div
              className={`border-dashed border-2 relative rounded-sm overflow-hidden ${!isMainPropertyValid2 ? 'border-red-500' : 'border-neutral-300'} ${draggingMainProperties2 ? 'border-blue-500' : 'border-neutral-300'} `} >
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <div className={`flex items-center justify-center h-full px-6 pt-5 pb-6 rounded-md   `}
                      onDragEnter={handleDragEnterMainPropertie2}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropMainProperties(2, e)}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative  font-medium text-primary-6000 "

                          >
                            <span>{t('pageAddListing4-drag-property')}</span>

                          </label>
                          <p className="pl-1"></p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          {t('pageAddListing4-type')}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <img
                      className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                      src={galleryImgs?.[2] || ""}
                      alt=""
                    />
                    <button
                      className="absolute  w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full cursor-pointer"
                      onClick={() => handleDeleteGalleryImage(2)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}



          {!(galleryImgs?.[3] && galleryImgs[3].trim() !== '-') ? (
            <div
              className={`border-dashed border-2 relative rounded-sm overflow-hidden  ${!isMainPropertyValid3 ? 'border-red-500' : 'border-neutral-300'} ${draggingMainProperties3 ? 'border-blue-500' : 'border-neutral-300'} `} >
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <div className={`flex items-center justify-center h-full px-6 pt-5 pb-6 rounded-md  `}
                      onDragEnter={handleDragEnterMainPropertie3}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropMainProperties(3, e)}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative  font-medium text-primary-6000 "

                          >
                            <span>{t('pageAddListing4-drag-property')}</span>

                          </label>
                          <p className="pl-1"></p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          {t('pageAddListing4-type')}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <img
                      className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                      src={galleryImgs?.[3] || ""}
                      alt=""
                    />
                    <button
                      className="absolute  w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full cursor-pointer"
                      onClick={() => handleDeleteGalleryImage(3)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderSecondPropertyImages = () => {
    return (
      <div>
        <div className="grid grid-cols-2 gap-[2px] scroll-top-0  ">
          {!(secondGalleryImgs?.[0] && secondGalleryImgs[0].trim() !== '-') ? (
            <div className={`border-dashed border-2 relative rounded-sm overflow-hidden ${!isSecondPropertyValid0 ? 'border-red-500' : ''} ${draggingSecondProperties ? 'border-blue-500' : 'border-dashed '}`} >
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <div className={`flex items-center justify-center h-[165px] px-6 pt-5 pb-6 border-neutral-300 dark:border-neutral-6000  rounded-md'  `}
                      onDragEnter={handleDragEnterSecondProperties}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropSecondProperties(0, e)}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative  font-medium text-primary-6000 "

                          >
                            <span>{t('pageAddListing4-drag-property')}</span>

                          </label>
                          <p className="pl-1"></p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          {t('pageAddListing4-type')}
                        </p>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>

          ) : (
            <div>
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">

                    <img
                      className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                      src={secondGalleryImgs?.[0] || ""}
                      alt=""
                    />
                    <button
                      className="absolute  w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full cursor-pointer"
                      onClick={() => handleDeleteSecondGalleryImage(0)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!(secondGalleryImgs?.[1] && secondGalleryImgs[1].trim() !== '-') ? (
            <div
              className={`border-dashed border-2 relative rounded-sm overflow-hidden  ${!isSecondPropertyValid1 ? 'border-red-500' : 'border-neutral-300'} ${draggingMainProperties0 ? 'border-blue-500' : 'border-neutral-300'} `} >
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <div className={`flex items-center justify-center h-full px-6 pt-5 pb-6 rounded-md  `}
                      onDragEnter={handleDragEnterSecondProperties}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropSecondProperties(1, e)}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative  font-medium text-primary-6000 "

                          >
                            <span>{t('pageAddListing4-drag-property')}</span>

                          </label>
                          <p className="pl-1"></p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          {t('pageAddListing4-type')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <img
                      className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                      src={secondGalleryImgs?.[1] || ""}
                      alt=""
                    />
                    <button
                      className="absolute  w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full cursor-pointer"
                      onClick={() => handleDeleteSecondGalleryImage(1)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!(secondGalleryImgs?.[2] && secondGalleryImgs[2].trim() !== '-') ? (
            <div
              className={`border-dashed border-2 relative rounded-sm overflow-hidden  ${!isSecondPropertyValid2 ? 'border-red-500' : 'border-neutral-300'} ${draggingMainProperties0 ? 'border-blue-500' : 'border-neutral-300'} `} >
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <div className={`flex items-center justify-center h-full px-6 pt-5 pb-6 rounded-md  `}
                      onDragEnter={handleDragEnterSecondProperties}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropSecondProperties(2, e)}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative  font-medium text-primary-6000 "

                          >
                            <span>{t('pageAddListing4-drag-property')}</span>

                          </label>
                          <p className="pl-1"></p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          {t('pageAddListing4-type')}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <img
                      className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                      src={secondGalleryImgs?.[2] || ""}
                      alt=""
                    />
                    <button
                      className="absolute  w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full cursor-pointer"
                      onClick={() => handleDeleteSecondGalleryImage(2)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!(secondGalleryImgs?.[3] && secondGalleryImgs[3].trim() !== '-') ? (
            <div
              className={`border-dashed border-2 relative rounded-sm overflow-hidden  ${!isSecondPropertyValid3 ? 'border-red-500' : 'border-neutral-300'} ${draggingMainProperties0 ? 'border-blue-500' : 'border-neutral-300'}`} >
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <div className={`flex items-center justify-center h-full px-6 pt-5 pb-6 rounded-md   `}
                      onDragEnter={handleDragEnterSecondProperties}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropSecondProperties(3, e)}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative  font-medium text-primary-6000 "

                          >
                            <span>{t('pageAddListing4-drag-property')}</span>

                          </label>
                          <p className="pl-1"></p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          {t('pageAddListing4-type')}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <img
                      className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                      src={secondGalleryImgs?.[3] || ""}
                      alt=""
                    />
                    <button
                      className="absolute  w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full cursor-pointer"
                      onClick={() => handleDeleteSecondGalleryImage(3)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderThirdPropertyImages = () => {
    return (
      <div>
        <div className="grid grid-cols-2 gap-[2px] scroll-top-0  ">
          {!(thirdGalleryImgs?.[0] && thirdGalleryImgs[0].trim() !== '-') ? (
            <div className={`border-dashed border-2 relative rounded-sm overflow-hidden ${!isThirdPropertyValid0 ? 'border-red-500' : ''} ${draggingSecondProperties ? 'border-blue-500' : 'border-dashed '}`} >
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <div className={`flex items-center justify-center h-[165px] px-6 pt-5 pb-6 border-neutral-300 dark:border-neutral-6000  rounded-md'  `}
                      onDragEnter={handleDragEnterThirdCover}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropThirdProperties(0, e)}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative  font-medium text-primary-6000 "

                          >
                            <span>{t('pageAddListing4-drag-property')}</span>

                          </label>
                          <p className="pl-1"></p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          {t('pageAddListing4-type')}
                        </p>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>

          ) : (
            <div>
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">

                    <img
                      className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                      src={thirdGalleryImgs?.[0] || ""}
                      alt=""
                    />
                    <button
                      className="absolute  w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full cursor-pointer"
                      onClick={() => handleDeleteThirdGalleryImage(0)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!(thirdGalleryImgs?.[1] && thirdGalleryImgs[1].trim() !== '-') ? (
            <div
              className={`border-dashed border-2 relative rounded-sm overflow-hidden ${!isThirdPropertyValid1 ? 'border-red-500' : 'border-neutral-300'} ${draggingMainProperties0 ? 'border-blue-500' : 'border-neutral-300'} `} >
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <div className={`flex items-center justify-center h-full px-6 pt-5 pb-6 rounded-md   `}
                      onDragEnter={handleDragEnterThirdCover}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropThirdProperties(1, e)}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative  font-medium text-primary-6000 "

                          >
                            <span>{t('pageAddListing4-drag-property')}</span>

                          </label>
                          <p className="pl-1"></p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          {t('pageAddListing4-type')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <img
                      className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                      src={thirdGalleryImgs?.[1] || ""}
                      alt=""
                    />
                    <button
                      className="absolute  w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full cursor-pointer"
                      onClick={() => handleDeleteThirdGalleryImage(1)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!(thirdGalleryImgs?.[2] && thirdGalleryImgs[2].trim() !== '-') ? (
            <div
              className={`border-dashed border-2 relative rounded-sm overflow-hidden  ${!isThirdPropertyValid2 ? 'border-red-500' : 'border-neutral-300'} ${draggingMainProperties0 ? 'border-blue-500' : 'border-neutral-300'} `} >
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <div className={`flex items-center justify-center h-full px-6 pt-5 pb-6 rounded-md  `}
                      onDragEnter={handleDragEnterThirdCover}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropThirdProperties(2, e)}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative  font-medium text-primary-6000 "

                          >
                            <span>{t('pageAddListing4-drag-property')}</span>

                          </label>
                          <p className="pl-1"></p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          {t('pageAddListing4-type')}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <img
                      className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                      src={thirdGalleryImgs?.[2] || ""}
                      alt=""
                    />
                    <button
                      className="absolute  w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full cursor-pointer"
                      onClick={() => handleDeleteThirdGalleryImage(2)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!(thirdGalleryImgs?.[3] && thirdGalleryImgs[3].trim() !== '-') ? (
            <div
              className={`border-dashed border-2 relative rounded-sm overflow-hidden  ${!isThirdPropertyValid3 ? 'border-red-500' : 'border-neutral-300'} ${draggingMainProperties0 ? 'border-blue-500' : 'border-neutral-300'}`} >
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <div className={`flex items-center justify-center h-full px-6 pt-5 pb-6 rounded-md   `}
                      onDragEnter={handleDragEnterThirdCover}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDropThirdProperties(3, e)}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-neutral-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                          <label
                            htmlFor="file-upload"
                            className="relative  font-medium text-primary-6000 "

                          >
                            <span>{t('pageAddListing4-drag-property')}</span>

                          </label>
                          <p className="pl-1"></p>
                        </div>
                        <p className="text-xs text-neutral-500 dark:text-neutral-400">
                          {t('pageAddListing4-type')}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="relative flex-col rounded-md overflow-hidden  bg-[white] aspect-w-6 aspect-h-3 ">
                <div className="group">
                  <div className="relative z-10 flex-col w-[330px] h-[165px] rounded-sm overflow-hidden  bg-transparent ">
                    <img
                      className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
                      src={thirdGalleryImgs?.[3] || ""}
                      alt=""
                    />
                    <button
                      className="absolute  w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full cursor-pointer"
                      onClick={() => handleDeleteThirdGalleryImage(3)}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };



  const renderSecondCover = () => {
    return (

      <div className="border-dashed relative flex-col w-full h-[400px] rounded-md overflow-hidden  bg-[white] ">
        {secondCoverImage ? (
          <div className=" group">
            <img
              className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
              src={secondCoverImage}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <button
              className="absolute w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={handleDeleteSecondCoverImage}
            >
              -
            </button>
          </div>
        ) : (
          loadingSecondCover ? (
            <div className="flex items-center justify-center h-[400px]">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : (
            <div
              className={`relative z-10 flex-col w-full h-[400px] rounded-sm overflow-hidden bg-transparent `}
            >
              <div className={`flex items-center justify-center h-[400px] px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000  rounded-md' ${!isSecondCoverValid ? 'border-red-500' : ''} ${draggingSecondCover ? 'border-blue-500' : 'border-dashed '} `}
                onDragEnter={handleDragEnterSecondCover}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDropSecondCover}>
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                    <label
                      htmlFor="file-upload"
                      className="relative font-medium text-primary-6000 "
                    >
                      <span>{t('pageAddListing4-drag-property')}</span>
                    </label>
                    <p className="pl-1"></p>
                  </div>
                  <p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG up to 10MB</p>
                </div>
              </div>
            </div>

          ))}
      </div>

    );
  };

  const renderThirdCover = () => {
    return (

      <div className="border-dashed relative flex-col w-full h-[400px] rounded-md overflow-hidden  bg-[white] ">
        {thirdCoverImage ? (
          <div className=" group">
            <img
              className="absolute inset-0 object-cover rounded-sm sm:rounded-sm w-full h-full"
              src={thirdCoverImage}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <button
              className="absolute w-[30px] h-[30px] top-2 right-2 bg-red-500 text-white p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={handleDeleteThirdCoverImage}
            >
              -
            </button>
          </div>
        ) : (
          loadingThirdCover ? (
            <div className="flex items-center justify-center h-[400px]">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : (
            <div
              className={`relative z-10 flex-col w-full h-[400px] rounded-sm overflow-hidden bg-transparent `}
            >
              <div className={`flex items-center justify-center h-[400px] px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000  rounded-md' ${!isThirdCoverValid ? 'border-red-500' : ''} ${draggingThirdCover ? 'border-blue-500' : 'border-dashed '} `}
                onDragEnter={handleDragEnterThirdCover}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDropThirdCover}>
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                    <label
                      htmlFor="file-upload"
                      className="relative font-medium text-primary-6000 "
                    >
                      <span>{t('pageAddListing4-drag-property')}</span>
                    </label>
                    <p className="pl-1"></p>
                  </div>
                  <p className="text-xs text-neutral-500 dark:text-neutral-400">{t('pageAddListing4-type')}</p>
                </div>
              </div>
            </div>

          ))}
      </div>

    );
  };

  return (

    <div
      className={`nc-PageAddListing2 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing2"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">3</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 5
          </span>
        </div>
        <div>
          <h2 className="text-2xl font-semibold">{t('pageAddListing4-images')}</h2>

        </div>

        <div className="listingSection__wrap ">
          <div className="space-y-8">
            <div>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={CoverInputRef}
                onChange={handleCoverImageChange}
              />
              <ButtonSecondary onClick={handleAddCoverImage}> {t('pageAddListing4-add-main')} </ButtonSecondary>

            </div>
            {renderMainCover()}

            {renderFirstPropertyImages()}
          </div>

        </div>

        <div className="listingSection__wrap ">
          <div className="space-y-8">
            <div>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={secondCoverInputRef}
                onChange={handleSecondCoverImageChange}
              />
              <ButtonSecondary onClick={handleAddSecondCoverImage}> {t('pageAddListing4-add-second')} </ButtonSecondary>

            </div>
            {renderSecondCover()}
            <div className="flex flex-row items-center">
              <div>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={secondFileInputRef}
                  onChange={handleSecondPropertyImage}
                />
                {/* <ButtonSecondary onClick={handleSecondAddImage}> Second Block of Property Images + </ButtonSecondary> */}


              </div>
              {loadingSecondImages && (
                <div className="pl-[40px]">
                  <FadeLoader color="#36d7b7" />
                </div>
              )}
            </div>
            {renderSecondPropertyImages()}

          </div >

        </div >

        < div className="listingSection__wrap " >
          <div className="space-y-8">
            <div>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={thirdCoverInputRef}
                onChange={handleThirdCoverImageChange}
              />
              <ButtonSecondary onClick={handleAddThirdCoverImage}> Third Cover Image + </ButtonSecondary>

            </div>
            {renderThirdCover()}
            <div className="flex flex-row items-center">
              <div>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={thirdFileInputRef}
                  onChange={handleThirdPropertyImage}
                />


              </div>
              {loadingThirdImages && (
                <div className="pl-[40px]">
                  <FadeLoader color="#36d7b7" />
                </div>
              )}
            </div>
            {renderThirdPropertyImages()}

          </div>

        </div >
        <div className="flex justify-end space-x-5">
          <ButtonSecondary onClick={toPrevtStep}>{t('pageAddListing10-back')}</ButtonSecondary>
          <ButtonPrimary onClick={toNextStep}> {t('pageAddListing4-continue')} </ButtonPrimary>
        </div>
      </div >
    </div >
  );
};

export default PageAddListing4;
