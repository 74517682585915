import React, { useState, useRef, useEffect, FC } from "react";
import ButtonSquareFull from "shared/Button/ButtonSqareFull";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ClockLoader } from 'react-spinners';
import { useAuth0 } from "@auth0/auth0-react";
import { useSearch } from '../../SearchContext';
import { useTranslation } from 'react-i18next';


export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = "Location",
  desc = "Property Address",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { listings, setListings } = useSearch();
  const [value, setValue] = useState("");
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [selectedCategory, setSelectedCategory] = useState('Homes');
  const [selectedLocation, setSelectedLocation] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { t, i18n } = useTranslation();
  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
  };

  const getButtonStyle = (category: string) => {
    if (selectedCategory === category) {
      return 'bg-[#16385D] text-white';
    } else {
      return 'bg-white border border-[#CCCCCC;] text-[#16385D]';
    }
  };


  const handleSearch = () => {
    setLoading(true);

    let apiUrl = `${process.env.REACT_APP_API}property/search?type=${selectedCategory}&address=${selectedLocation}`;
    if (isAuthenticated) {
      const userId = user && user.sub;
      if (userId) {
        const cleanUserId = userId.replace('auth0|', '');
        apiUrl += `&userId=${cleanUserId}`;
      }
    }

    axios.get(apiUrl)
      .then((response) => {
        setLoading(false);
        setListings(response.data);
        navigate('/listing-stay-map');
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching properties:', error);
      });
  };


  return (
    <div className={` flex `} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className="flex flex-col w-full   [ nc-hero-field-padding ] flex-shrink-0   cursor-pointer focus:outline-none text-left"
      >

        <div className="flex flex-row pt-[10px]" >
          <div className=" pr-[10px] pb-[10px]" >
            <ButtonSquareFull className={`text-xs font-medium px-[18px] h-7 ${getButtonStyle('Homes')}`}
              onClick={() => handleCategoryClick('Homes')}>
              {t('locationInput-h')}
            </ButtonSquareFull>

          </div>
          <div className="pr-[10px]" >
            <ButtonSquareFull className={`text-xs font-medium px-[18px] h-7 ${getButtonStyle('Apartments')}`}
              onClick={() => handleCategoryClick('Apartments')}>
              {t('locationInput-a')}
            </ButtonSquareFull>
          </div>
          <div className="pr-[10px]" >
            <ButtonSquareFull className={`text-xs font-medium px-[18px] h-7 ${getButtonStyle('Commercial')}`}
              onClick={() => handleCategoryClick('Commercial')} >
              {t('locationInput-c')}
            </ButtonSquareFull>
          </div>
          <ButtonSquareFull className={`text-xs font-medium px-[18px] h-7 ${getButtonStyle('Rentals')}`}
            onClick={() => handleCategoryClick('Rentals')}>
            {t('locationInput-r')}
          </ButtonSquareFull>
        </div>
        <div className="flex flex-row " >
          <input
            className={`block w-full bg-[#F2F2F2] border-none rounded-md pl-[15px] py-[10px] text-xs focus:outline-none focus:placeholder-neutral-300  font-semibold placeholder-neutral-800  truncate`}
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover}
            onChange={(e) => {
              setValue(e.currentTarget.value);
              setSelectedLocation(e.currentTarget.value)
            }}
            ref={inputRef}
          />

          {value && showPopover && (
            <div className="bg-black">

            </div>
          )}
          <div className="pl-[10px]">
            {loading ? (
              <div className="px-[18px] pt-[5px]">
                <ClockLoader color="#36d7b7" size={20} />
              </div>
            ) : (
              <ButtonSquareFull
                className="bg-[#00D3DB] text-xs font-medium text-[white] px-[18px]"
                size="h-9"
                onClick={handleSearch}
              >
                {t('locationInput-button')}
              </ButtonSquareFull>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
