import React, { FC, useState, useEffect } from "react";

import { Link, useNavigate } from 'react-router-dom';
import FormItem from "./FormItem";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { useLocation } from 'react-router-dom';
import { AuthorType, ListingDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useTranslation } from 'react-i18next';
export interface PageAddListing1Props { }



const PageAddListing1: FC<PageAddListing1Props> = () => {
  const location = useLocation();
  const propertyInfo = location.state;
  const agent = propertyInfo.agent as AuthorType;
  const property = propertyInfo.property as ListingDataType;
  const backtHref = "/listing-profile";
  type CheckedItems = Record<string, boolean>;
  const [checkedHouseItems, setCheckedHouseItems] = useState<CheckedItems>({});
  const [checkedApartmentItems, setCheckedApartmentItems] = useState<CheckedItems>({});
  const [checkedOfficeItems, setCheckedOfficeItems] = useState<CheckedItems>({});
  const { t, i18n } = useTranslation();

  const [yearBuilt, setYearBuilt] = useState('');
  const [propertyPrice, setPropertyPrice] = useState(0);
  const [pricesqft, setPricesqf] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [province, setProvince] = useState('');
  const [town, setTown] = useState('');
  const [street, setStreet] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();

  const [isYearBuiltValid, setIsYearBuiltValid] = useState(true);
  const [isPropertyPriceValid, setIsPropertyPriceValid] = useState(true);
  const [isPricesqftValid, setIsPricesqftValid] = useState(true);
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isStreetValid, setIsStreetValid] = useState(true);
  const [isTownValid, setIsTownValid] = useState(true);
  const [isProvinceValid, setIsProvinceValid] = useState(true);
  const [isMunicipalityValid, setIsMunicipalityValid] = useState(true);
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [isTypeValid, setIsTypeValid] = useState(true);

  useEffect(() => {
    checkValues();
  }, []);

  const checkValues = () => {
    const mainFeatures = property.mainFeatures || [];
    setDescription(property.overview?.description || '');
    setPropertyPrice(property.price || 0);


    if (mainFeatures.length > 0) {
      const firstFeature = mainFeatures[0];
      const secondFeature = mainFeatures[1];
      const parts = firstFeature.split("Built in ");
      setYearBuilt(parts[1]);
      const parts2 = secondFeature.split("$");
      const parts3 = parts2[1];
      const parts4 = parts3.split(" price/sqft");
      setPricesqf(parts4[0]);
      setTitle(property.title || '');
      setStreet(property.street || '');
      setTown(property.town || '');
      setProvince(property.province || '');
      setMunicipality(property.municipality || '');
      setDescription(property.overview?.description || '');

    }
  }

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYearBuilt(event.target.value);
  };


  const handleCheckboxHouseItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const updatedHouseItems = { ...checkedHouseItems };
    if (checked) {
      updatedHouseItems[name] = true;
    } else {
      delete updatedHouseItems[name];
    }
    setCheckedHouseItems(updatedHouseItems);
  };

  const handleCheckboxOfficeItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const updatedOfficeItems = { ...checkedOfficeItems };
    if (checked) {
      updatedOfficeItems[name] = true;
    } else {
      delete updatedOfficeItems[name];
    }
    setCheckedOfficeItems(updatedOfficeItems);
  };

  const handleCheckboxApartmentItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const updatedApartmentItems = { ...checkedApartmentItems };
    if (checked) {
      updatedApartmentItems[name] = true;
    } else {
      delete updatedApartmentItems[name];
    }
    setCheckedApartmentItems(updatedApartmentItems);
  };

  const handlePropertyPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setPropertyPrice(value);
  };

  const handlePriceSqftChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPricesqf(event.target.value);
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStreet(event.target.value);
  };

  const handleTownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTown(event.target.value);
  };

  const handleProvinceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProvince(event.target.value);
  };

  const handleMunicipalityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMunicipality(event.target.value);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const validateFields = () => {
    setIsYearBuiltValid(true);
    setIsPropertyPriceValid(true);
    setIsPricesqftValid(true);
    setIsTitleValid(true);
    setIsStreetValid(true);
    setIsTownValid(true);
    setIsProvinceValid(true);
    setIsMunicipalityValid(true);
    setIsDescriptionValid(true);
    setIsTitleValid(true);

    let isValid = true;
    if (!yearBuilt) {
      setIsYearBuiltValid(false);
      isValid = false;
    }

    if (!propertyPrice) {
      setIsPropertyPriceValid(false);
      isValid = false;
    }

    if (!pricesqft) {
      setIsPricesqftValid(false);
      isValid = false;
    }

    if (!title) {
      setIsTitleValid(false);
      isValid = false;
    }

    if (!street) {
      setIsStreetValid(false);
      isValid = false;
    }

    if (!town) {
      setIsTownValid(false);
      isValid = false;
    }

    if (!province) {
      setIsProvinceValid(false);
      isValid = false;
    }

    if (!municipality) {
      setIsMunicipalityValid(false);
      isValid = false;
    }

    if (!description) {
      setIsDescriptionValid(false);
      isValid = false;
    }

    if (!Object.values(checkedHouseItems).some((checked) => checked) && !Object.values(checkedApartmentItems).some((checked) => checked)) {
      setIsTypeValid(false);
      isValid = false;
    }

    return isValid;
  };


  const toNextStep = () => {

    const isValid = validateFields();
    if (!isValid) {
      return;
    }

    var type = "";
    var values: string[] = [];
    const built = "Built in " + yearBuilt;
    const pricesqf = "$" + pricesqft + " price/sqft";

    if (Object.values(checkedHouseItems).some((checked) => checked)) {
      type = 'Homes';
      const checkedHouseItemsArray: string[] = Object.keys(checkedHouseItems);
      values = values.concat(built, pricesqf, checkedHouseItemsArray);
    } else if (Object.values(checkedApartmentItems).some((checked) => checked)) {
      type = 'Apartments';
      const checkedApartmentItemsArray: string[] = Object.keys(checkedApartmentItems);
      values = values.concat(built, pricesqf, checkedApartmentItemsArray);
    } else if (Object.values(checkedOfficeItems).some((checked) => checked)) {
      type = 'Commercial';
      const checkedOfficeItemsArray: string[] = Object.keys(checkedOfficeItems);
      values = values.concat(built, pricesqf, checkedOfficeItemsArray);
    }

    property.yearBuilt = parseInt(yearBuilt, 10);

    property.address = street + ", " + town + ", " + province + ", " + municipality;
    property.street = street;
    property.price = propertyPrice;
    property.type = type;
    property.mainFeatures = values;
    property.title = title;
    property.town = town;
    property.province = province;
    property.municipality = municipality;
    property.factsFeatures = {
      "appliances": [],
      "interior": [],
      "otherInterior": [],
      "parking": []
    }
    if (property.overview) {
      property.overview.description = description;
    }


    const propertyInfo = {
      property,
      agent,
    };

    navigate('/add-listing-2', { state: propertyInfo });
  };


  return (

    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">1</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 5
          </span>
        </div>

        <h2 className="text-2xl font-semibold">{t('pageAddListing1-basic')}</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div className="listingSection__wrap ">

          <div className="space-y-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
              <FormItem label="Real state agent" >
                <Input placeholder="Name"
                  value={agent.firstName + " " + agent.lastName} disabled />
              </FormItem>
              <FormItem label="Year build" >

                <Input placeholder="Year"
                  value={yearBuilt || ''}
                  onChange={handleYearChange}
                  className={!isYearBuiltValid ? 'border-red-500' : ''}
                />
              </FormItem>
            </div>

            <FormItem label="Agent Company" >
              <Input placeholder="Agent Name"
                value={agent.company} disabled />
            </FormItem>
            <div>
              <span className="text-xl ">
                {t('pageAddListing1-overview')}
              </span>
            </div>

            <Textarea placeholder="Type here."
              rows={8}
              value={description || ''}
              onChange={handleDescriptionChange}
              className={!isDescriptionValid ? 'border-red-500' : ''}
            />

            <div className={!isTypeValid ? 'listingSection__wrap border-red-500' : 'listingSection__wrap'}>
              <div className="space-y-8">

                <div className="pt-[10px]">
                  <span className="text-xl pt-[5px]">
                    {t('pageAddListing1-type')}
                  </span>
                </div>
                <div className="my-8">
                  <label className="text-lg font-semibold " htmlFor="">
                    {t('pageAddListing1-home')}
                  </label>
                  <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                </div>
                <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">

                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Single family"
                      name="Single family"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxHouseItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-single')}
                      </span>
                    </label>
                  </div>
                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Duplex"
                      name="Duplex"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxHouseItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-duplex')}
                      </span>
                    </label>
                  </div>
                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Village"
                      name="Village"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxHouseItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-village')}
                      </span>
                    </label>
                  </div>
                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Townhouse"
                      name="Townhouse"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxHouseItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-townhouse')}
                      </span>
                    </label>
                  </div>
                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Gated community"
                      name="Gated community"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxHouseItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-gated-community')}
                      </span>
                    </label>
                  </div>
                </div>
                <div className="my-8">
                  <label className="text-lg font-semibold " htmlFor="">
                    {t('pageAddListing1-apartments')}
                  </label>
                  <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                </div>
                <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Duplex"
                      name="Duplex"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxApartmentItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-duplex')}
                      </span>
                    </label>
                  </div>
                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Penthouse"
                      name="Penthouse"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxApartmentItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-penthouse')}
                      </span>
                    </label>
                  </div>
                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Gated community"
                      name="Gated community"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxApartmentItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-gated-community')}
                      </span>
                    </label>
                  </div>

                </div>
                <div className="my-8">
                  <label className="text-lg font-semibold " htmlFor="">
                    {t('pageAddListing1-commercial')}
                  </label>
                  <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                </div>
                <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Individual building"
                      name="Individual building"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxOfficeItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-individual-building')}
                      </span>
                    </label>
                  </div>
                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Office building"
                      name="Office building"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxOfficeItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-office-building')}
                      </span>
                    </label>
                  </div>
                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Full floor"
                      name="Full floor"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxOfficeItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-full-floor')}
                      </span>
                    </label>
                  </div>
                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Multi-level"
                      name="Multi-level"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxOfficeItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-multi-level')}
                      </span>
                    </label>
                  </div>
                  <div className={`flex text-sm sm:text-base`}>
                    <input
                      id="Street level"
                      name="Street level"
                      type="checkbox"
                      className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                      onChange={handleCheckboxOfficeItems}
                    />
                    <label
                      className="ml-3.5 flex flex-col flex-1 justify-center"
                    >
                      <span className=" text-neutral-900 dark:text-neutral-100">
                        {t('pageAddListing1-street-level')}
                      </span>
                    </label>
                  </div>
                </div>

              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
              <FormItem label="Property price">
                <Input
                  placeholder="Price"
                  value={propertyPrice || ''}
                  onChange={handlePropertyPriceChange}
                  className={!isPropertyPriceValid ? 'border-red-500' : ''}
                />
              </FormItem>
              <FormItem label="Price per square foot">
                <Input placeholder="Price"
                  value={pricesqft || ''}
                  onChange={handlePriceSqftChange}
                  className={!isPricesqftValid ? 'border-red-500' : ''}
                />
              </FormItem>
            </div>
            <FormItem label="Property Title" >
              <Input placeholder="Title"
                value={title || ''}
                onChange={handleTitleChange}
                className={!isTitleValid ? 'border-red-500' : ''}
              />
            </FormItem>
            <FormItem label="Property street" >
              <Input placeholder="street"
                value={street || ''}
                onChange={handleAddressChange}
                className={!isStreetValid ? 'border-red-500' : ''}
              />
            </FormItem>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
              <FormItem label="Town" >
                <Input placeholder="Name"
                  value={town || ''}
                  onChange={handleTownChange}
                  className={!isTownValid ? 'border-red-500' : ''}
                />
              </FormItem>
              <FormItem label="Province">
                <Input placeholder="Name"
                  value={province || ''}
                  onChange={handleProvinceChange}
                  className={!isProvinceValid ? 'border-red-500' : ''}
                />
              </FormItem>
            </div>
            <FormItem label="Municipality" >
              <Input placeholder="Name"
                value={municipality || ''}
                onChange={handleMunicipalityChange}
                className={!isMunicipalityValid ? 'border-red-500' : ''}
              />
            </FormItem>
          </div>
        </div>

        <div className="flex justify-end space-x-5">
          <ButtonSecondary href={backtHref}>Go back</ButtonSecondary>
          <ButtonPrimary onClick={toNextStep}> {"Continue"} </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default PageAddListing1;
